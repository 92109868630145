

import React from 'react';


const Message = () => {
  return (
    <div className="message-container">
      <div className="message">
        Interview evaluation added successfully
      </div>
    </div>
  );
}

export default Message;
