import { Row, Col } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";

const BillingLayout = () => {
    return (
        <>
            <Row className="row-pad-0">
                <Col md={2} className="IntegrationsSetting-tab pad-0">
                    <div className="template-wrap-nav d-flex flex-column pad-20 bg-white">
                        <NavLink
                            to="plan-overview"
                            end
                            className={({ isActive }) => `pad-10 mb-20 ${isActive ? 'active' : ''}`}
                            style={{ padding: "10px" }}
                        >
                            Plan Overview
                        </NavLink>

                        <NavLink to="billing-packages"
                            className={({ isActive }) => `pad-10 mb-20 ${isActive ? 'active' : ''}`}
                            style={{ padding: "10px" }}
                        >Packages</NavLink>
                        <NavLink
                            to="billing-history"
                            className={({ isActive }) => `pad-10 mb-20 ${isActive ? 'active' : ''}`}
                            style={{ padding: "10px" }}
                        >
                            Billing History
                        </NavLink>



                    </div>
                </Col>
                <Col sm={10} className='bg-light-gray'>
                    <Outlet />
                </Col>
            </Row >
        </>
    );
};

export default BillingLayout;
