import { createSlice } from '@reduxjs/toolkit'
import { create, update, getAll, getById, deleteETemplate } from '../actions/emailTemplateActions'


const initialState = {
    loading: false,
    emailTemplates: [],
    emailTemplateDetail: null,
    error: null,
}

const emailTemplateSlice = createSlice({
    name: 'emailTemplate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state, action) => {
                state.loading = true
            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading = false
                // state.emailTemplates.push(action.payload)
            })
            .addCase(create.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(update.pending, (state, action) => {
                state.loading = true
            })
            .addCase(update.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(update.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getAll.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.loading = false
                state.emailTemplates = action.payload
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getById.pending, (state, action) => {
                state.loading = true
                
            })
            .addCase(getById.fulfilled, (state, action) => {
                state.loading = false
                state.emailTemplateDetail = action.payload
               // state.emailTemplates = action.payload
            })
            .addCase(getById.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(deleteETemplate.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteETemplate.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteETemplate.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
    },
})

export default emailTemplateSlice.reducer