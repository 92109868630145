import { createSlice } from '@reduxjs/toolkit'
import { create, update, getAll, getById, deleteTemplate } from '../actions/interviewTemplateActions'


const initialState = {
    loading: false,
    interviewTemplates: [],
    interviewTemplateDetail: null,
    error: null,
}

const interviewTemplateSlice = createSlice({
    name: 'interviewTemplate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state, action) => {
                state.loading = true
            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(create.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(update.pending, (state, action) => {
                state.loading = true
            })
            .addCase(update.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(update.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getAll.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.loading = false
                state.interviewTemplates = action.payload
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getById.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getById.fulfilled, (state, action) => {
                state.loading = false
                state.interviewTemplateDetail = action.payload
            })
            .addCase(getById.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(deleteTemplate.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteTemplate.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteTemplate.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
    },
})

export default interviewTemplateSlice.reducer