import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const config = require('../../config/config.json');
const API_BASE_URL = config.API_BASE_URL;

const BASE_URL = `${API_BASE_URL}/meeting-schedular`;

const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

export const update = createAsyncThunk(
    'meetingSchedularAction/update',
    async ({meetingSchedularId,payload},thunkAPI) => {
       
            
    
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

   
        try {
            const { data } = await axios.put(`${BASE_URL}/${meetingSchedularId}`, payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)

