import { React, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import LeftSidebar from "../components/header/LeftSidebar";
import TopHeader from "../components/header/TopHeader";
import Postjob from "../components/job/Postjob";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UsersProfileLogin } from "../store/actions/userActions";
const Layout = () => {
    let navigate = useNavigate();
    const [showPostjob, setPostjob] = useState(false);
    const { UsersProfileLoginData } = useSelector((state) => state.user);
    const userToken = useSelector((state) => state.auth.userToken);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!userToken) {
            navigate("/");
        }
        dispatch(UsersProfileLogin(""))
    }, [userToken, navigate]);
    // useEffect(() => {
    //     if (UsersProfileLoginData) {
    //         localStorage.setItem('storedImage', UsersProfileLoginData?.data?.user?.user_image);
    //     }
    // }, [UsersProfileLoginData]);
    const handlePostjob = () => {
        setPostjob(true);
    }

    const handleClose = () => {
        setPostjob(false);
    }

    //remove token when browser close
    // useEffect(() => {
    //     const handleBeforeUnload = () => {
    //         localStorage.removeItem("userToken");
    //     };

    //     window.addEventListener("beforeunload", handleBeforeUnload);

    //     return () => {
    //         window.removeEventListener("beforeunload", handleBeforeUnload);
    //     };
    // }, []);

    return (
        !userToken ? null :
            <>

                <Container fluid className="pad-0   parant-container" >
                    <Navbar className="custom-navbar bg-white  Topheader-wrap d-flex justify-content-between align-items-center flex-wrap">
                        <TopHeader postjob={handlePostjob} />
                    </Navbar>
                    <section className="dasborad-wrap">
                        <Row className="row row-pad-0">
                            <Col md={1} sm={1} className="LeftMenu-warp border-radius-5 bg-white">
                                <LeftSidebar />
                            </Col>
                            <Col md={11} sm={11} className="right-side-wrap">
                                <Outlet />
                            </Col>
                        </Row>
                    </section>



                </Container>
                {showPostjob && <Postjob handleClose={handleClose} />}

            </>
    );
};

export default Layout;
