import { useState, useEffect } from 'react';
import { Row, Col, Offcanvas, Container } from 'react-bootstrap';
import { downloadarrowblow, crossicon } from '../../helper/Icons/Icon';
import { Label, CustomButton } from '../form';
import { getAll } from '../../store/actions/interviewTemplateActions';
import InterviewTemplates from '../common/InterviewTemplates';
import { useDispatch, useSelector } from 'react-redux';

const ImportInterview = ({ handleClose, submit }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({});
    const { interviewTemplates } = useSelector((state) => state.interviewTemplate);

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    const handleSearchChange = (name, value) => {
        setData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: value.toString().trim() ? '' : ' is required'
        }));
    };

    const handleSave = () => {
        const newErrors = {};
        if (!data || !data.interview_template_id) {
            newErrors.interview_template_id = ' is required';
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            submit(data);
        }
    };

    return (
        <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-light-gray custom-offcanvas" style={{ width: "50%" }}>
            <Offcanvas.Header className="model-header bg-white actionlist-header">
                <Offcanvas.Title>
                    <span className="svg">{downloadarrowblow}</span> Import Interview Journey
                </Offcanvas.Title>
                <div className="text-end">
                    <CustomButton onClick={handleClose} className="text-end close-btn" text={crossicon} />
                </div>
            </Offcanvas.Header>
            <Container fluid className="pad-10">
                <Row className="mt-20 row-pad-0">
                    <Col md={12} className="bg-white border-radius-5 pad-10">
                        <Label text="Choose Template" className="label-input pad-0" />
                        {interviewTemplates && (
                            <InterviewTemplates
                                label="Hide"
                                onChange={handleSearchChange}
                                interviewTemplates={interviewTemplates}
                                interviewTemplateId={data?.interview_template_id}
                            />
                        )}
                        {errors.interview_template_id && (
                            <span className="text-danger">{errors.interview_template_id}</span>
                        )}
                    </Col>
                    <Col md={12} className='text-end mt-10 pad-0'>
                        <CustomButton className="custom-btn-outline mr-10" text="Cancel" onClick={handleClose} />
                        <CustomButton className="custom-btn-primary" text="Save" onClick={handleSave} />
                    </Col>
                </Row>
            </Container>
        </Offcanvas>
    );
};

export default ImportInterview;
