import React, { useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function CriclrDiv({ clrName, icon, onclick, tooltiptext }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
if(tooltiptext){
  return(
    <OverlayTrigger
    placement="top"
    overlay={<Tooltip id={`tooltip-${clrName}`}>{tooltiptext}</Tooltip>}
    show={showTooltip}
  >
    <div
      className='number-wrap-cricle'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onclick}
    >
      <div className={`${clrName}`}>
      <span className="icon">{icon}</span>
      </div>
    </div>
  </OverlayTrigger>
  )

}
else{
 return(
  <div
  className='number-wrap-cricle'

>
  <div className={`${clrName}`}>
  <span className="icon">{icon}</span>
  </div>
</div>

 )
}

 
}

export default CriclrDiv;
