import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPhotos, } from "../../store/actions/companyActions";
import { companyPreviewtoken } from "../../store/actions/InterviewproceedAction";
import Paginate from "../common/Paginate";

;

const Prehotos = ({ company, token, PageChange, PerPage, perPageSate, currentPageSate }) => {
  const dispatch = useDispatch();
  const { AllPhotos, loading, error } = useSelector((state) => state.company);

  const hasPhotos = company.culture_images?.length > 0;
  const pagination = company.culture_images_pagination;

  const [selectedImage, setSelectedImage] = useState(null);  // State to track selected image

  const pages = pagination?.pages;
  const totalPages = pagination?.total_pages;
  const totalRecords = pagination?.total_images;


  const imagesToDisplay = company.culture_images;
  const id = company?.id;



  const handlePageChange = (page) => {
    PageChange(page);
  };

  const handlePerPage = (perPage) => {
    PerPage(perPage);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);  // Set the selected image when clicked
  };

  const handleClosePreview = () => {
    setSelectedImage(null);  // Close the modal
  };

  return (
    <>

      < Row className="row-pad-0  mb-10">
        <Col md={12} className="pad-0">
          <h2 className="sub-heading">{company.company_name} Photos</h2>
        </Col>
        <span className="single-border d-block"></span>
      </Row >


      {hasPhotos && (
        <Row className="row-pad-0 mt-10 mb-10 d-flex justify-content-center">
          <div className="photos-carousel d-flex justify-content-center">
            {imagesToDisplay.map((photo, index) => (
              <Col
                key={index}
                md={4}
                className="photos-carousel-col"
                style={{ marginRight: "5px" }}
              >
                <Image
                  src={`${photo.image_file}`}
                  fluid
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleImageClick(photo.image_file)}
                />
              </Col>
            ))}
          </div>

          <div className='d-flex justify-content-center mt-5'>
            <Paginate
              pages={pages}
              currentPage={currentPageSate}
              totalPages={totalPages}
              perPage={perPageSate}
              totalRecords={totalRecords}
              handlePageChange={handlePageChange}
              handlePerPage={handlePerPage}
              pagenation="only"
            />
          </div>
        </Row>

      )
      }

      {/* Modal for Image Preview */}
      {
        selectedImage && (
          <Modal show={true} onHide={handleClosePreview} size="md">
            <Modal.Header closeButton>
              <Modal.Title>Image Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Image
                src={selectedImage}
                fluid
                style={{ width: '80%', height: '100%' }}
              />
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary" onClick={handleClosePreview}>
                Close
              </button>
            </Modal.Footer>
          </Modal>
        )
      }
    </>
  );
}

export default Prehotos;
