import React, { useState, useEffect } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { Document, Page } from 'react-pdf';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { CustomButton } from '../form';
import { crossicon } from '../../helper/Icons/Icon';

const ViewerFile = ({ document, handleClose }) => {
  const getFileExtension = (url) => {
    const splitUrl = url.split('?')[0].split('.');
    return splitUrl.length > 1 ? splitUrl.pop().toLowerCase() : '';
  };

  const getFileType = (fileExtension) => {
    switch (fileExtension) {
      case 'pdf': return 'pdf';
      case 'docx': return 'docx';
      case 'doc': return 'doc';
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif': return 'img';
      case 'txt': return 'txt';
      default: return 'unknown';
    }
  };

  const fileExtension = getFileExtension(document);
  const fileType = getFileType(fileExtension);
  const [numPages, setNumPages] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (fileExtension === "pdf") {
      const fetchFile = async () => {
        try {
          const response = await fetch(document);
          if (!response.ok) {
            throw new Error(`Failed to fetch document: ${response.statusText}`);
          }

          const blob = await response.blob();
          const url = URL.createObjectURL(blob);

          if (fileType === 'pdf') {
            setFileData(url);
          }
        } catch (error) {
          console.error('Error loading file:', error);
          setError('Error loading document. Please try again later.');
          handleClose()

          window.open(document, '_blank');
        }
      };

      fetchFile();
    }
  }, [document, fileType]);


  const renderFileViewer = () => {
    if (error) return <div>{error}</div>;

    switch (fileType) {
      case 'pdf':
        return fileData ? (
          <Document file={fileData} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        ) : (
          <p>Loading PDF...</p>
        );
      case 'docx':
      case 'doc':
        return (
          <DocViewer
            documents={[{ uri: document, fileType: fileExtension }]}
            pluginRenderers={DocViewerRenderers}
          />
        );

      case 'img':
        return <img src={document} alt="Preview" style={{ width: '100%' }} />;
      case 'txt':
        return (
          <iframe
            src={document}
            title="Text file preview"
            style={{ width: '100%', height: '100%' }}
          />
        );
      default:
        return <div>Unsupported file type: {fileExtension}</div>;
    }
  };

  return (
    <Offcanvas
      show={true}
      onHide={handleClose}
      placement="end"
      className="bg-light-gray custom-offcanvas viewer-file-wrap"
      style={{ width: '60%' }}
    >
      <Offcanvas.Header className="model-header bg-white">
        <Offcanvas.Title>Document Preview</Offcanvas.Title>
        <div className="text-end">
          <CustomButton
            onClick={handleClose}
            className="text-end close-btn"
            text={crossicon}
          />
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="mt-10">
        {renderFileViewer()}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ViewerFile;
