import { Offcanvas, Container, Col, Row } from "react-bootstrap";
import InviteLiveInterview from "../../components/common/InviteLiveInterview";
import Interview from "../../components/RecordedInterviewDetails/Interview";
import InviteAnotherJob from "./InviteAnotherJob";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CustomButton } from "../../components/form/index";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { crossicon, actionlist, adduser, attachment, time, timer, CandidatesICon, Jobs } from "../../helper/Icons/Icon";
import * as moment from 'moment'
import ViewerFile from "../common/ViewerFile";
import { actionlistfields } from './fieldCandidates';
import ActionList from "../common/ActionList";

import { getByName } from "../../store/actions/userPrefrenceActions";
import ErrorMessage from "../common/ErrorMessage";
import FeedTimeLine from "../../components/common/FeedTimeLine";
import { getById } from '../../store/actions/candidateActions'
import JobApplied from './JobApplied'
import { useState } from "react";
import Spinner from "../common/Spinner";
const CandidatesDetail = ({ handleClose, candidateId }) => {
  const dispatch = useDispatch();
  const prefrenceName = "users";
  const [showInviteAnotherJob, setInviteAnotherJob] = useState(false);
  const [showactionlist, setactionlist] = useState(false);
  const [showInterview, setInterview] = useState(false);
  const [showDocViewerFile, setDocViewerFile] = useState(false);
  const [showDocViewer, setDocViewer] = useState(false);
  const [showInviteLiveInterview, setInviteLiveInterview] = useState(false);
  const [showShareCandidate, setShareCandidate] = useState(false);
  const [showSelectJob, setSelectJob] = useState(false);
  const { candidateDetail, loading, error } = useSelector((state) => state.candidate);
  const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);
  const [showTooltip, setShowTooltip] = useState({});
  const [selectedID, setSelectID] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null)
  useEffect(() => {
    if (candidateId) {



      dispatch(getByName(prefrenceName));

      dispatch(getById(candidateId)).then((res) => {

        if (res.type === "candidate/getById/rejected") {

          if (res.payload) {
            setErrorMessage(res.payload);
          } else {

            setErrorMessage({
              title: "An error occurred",
              detail: "Unable to retrieve andidate details at this time.Please try again later."
            });
          }
        } else {

          setErrorMessage(null);
        }

      });
    }

  }, [dispatch, candidateId]);

  const handleModel = (props, id) => {
    setSelectID(id)
    if (props === "actionList") {
      setactionlist(true);
    }
    if (props == "GenerateLink") {

    }
    if (props == "InviteLiveInterview") {
      setInviteLiveInterview(true)
    }
    if (props == "InviteAnotherJob") {
      setInviteAnotherJob(true)
    }
    if (props === "SelectJob") {
      setSelectJob(true);
    }
    if (props == "Interview") {
      setInterview(true)

    }
    if (props == "refreshPage") {
      if (candidateId) {
        dispatch(getById(candidateId));
      }

    }

  };

  const handleCloseModel = (props) => {
    if (props === "actionList") {
      setactionlist(false);
    }
    if (props == "InviteAnotherJob") {
      setInviteAnotherJob(false)

    }
    if (props == "ResumeDocViewer") {
      setDocViewer(false);

    }
    if (props == "InviteLiveInterview") {
      setInviteLiveInterview(false)
    }
    if (props === "SelectJob") {
      setSelectJob(false);
    }


    if (props == "Interview") {
      setInterview(false)
    }
  }
  const renderTooltip = ({ text }) => (
    <Tooltip
      id={`tooltip-top`}
      trigger={['hover', 'focus']}
      key="bottom"
      placement="bottom"
    >
      {text}
    </Tooltip>
  );
  const handelResumeDocViewer = (url) => {
    setDocViewerFile(url)
    setDocViewer(true);


  }
  const handleMouseEnter = (key) => {
    setShowTooltip(prev => ({ ...prev, [key]: true }));
  };

  const handleMouseLeave = (key) => {
    setShowTooltip(prev => ({ ...prev, [key]: false }));
  };

  return (
    <>

      <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "82%" }}>
        <Container fluid className="detailpage-wrap pad-0">
          {loading && <Spinner />}
          {errorMessage && <ErrorMessage error={errorMessage} closebtn={<CustomButton className="closeIconouter" onClick={handleClose} icon={crossicon} />} />}

          {candidateDetail && <>
            <Row className="row-pad-0">
              <div className="header">
                <div className="header-left">
                  <h4>Candidate Detail</h4>
                  <div className="date">
                    <p className="created">
                      <span>CREATED BY: {candidateDetail?.created_by}</span>
                      <small> {candidateDetail?.created_date && moment(candidateDetail?.created_date).format('MM-DD-YYYY h:mm A')}</small>
                    </p>
                    <p className="updated">
                      <span>UPDATED BY: {candidateDetail?.updated_by}</span>
                      <small>{candidateDetail?.updated_date && moment(candidateDetail?.updated_date).format('MM-DD-YYYY h:mm A')}</small>
                    </p>
                  </div>
                  <CustomButton className="actionlist-btn" onClick={() => handleModel("actionList", candidateDetail.id)} icon={<i class="fa fa-ellipsis-v" ></i>} />
                </div>


                <div className="header-right">


                  <ul className="right-ul">
                    <li>
                      <OverlayTrigger
                        show={showTooltip['InviteAnotherJob']}
                        placement="bottom"
                        overlay={renderTooltip({ text: "Invite for Another Job" })}
                      >
                        <span
                          onMouseEnter={() => handleMouseEnter('InviteAnotherJob')}
                          onMouseLeave={() => handleMouseLeave('InviteAnotherJob')}
                        >
                          <CustomButton className="circle-btn ml-2" onClick={() => handleModel("InviteAnotherJob")} icon={adduser} /></span>
                      </OverlayTrigger>
                    </li>

                    <li>
                      <OverlayTrigger
                        show={showTooltip['LiveInterview']}
                        placement="bottom"
                        overlay={renderTooltip({ text: "Invite for Live Interview" })}

                      >
                        <span
                          onMouseEnter={() => handleMouseEnter('LiveInterview')}
                          onMouseLeave={() => handleMouseLeave('LiveInterview')}
                        ><CustomButton className="circle-btn ml-10 " onClick={() => handleModel("InviteLiveInterview")} icon={time} /></span>

                      </OverlayTrigger>
                    </li>

                  </ul>
                  <ul className="right-ul">
                    <li>

                      <OverlayTrigger

                        placement="bottom"
                        overlay={renderTooltip({ text: "Refresh" })}

                      >
                        <span ><CustomButton className="btn-default" onClick={() => handleModel("refreshPage")} icon={<i class="fa fa-refresh"></i>} /></span>

                      </OverlayTrigger>
                    </li>
                  </ul>



                </div>



              </div>
            </Row>
            <Col md={12} className="body-wrap pad-0">
              <CustomButton className="closeIconouter" onClick={handleClose} icon={crossicon} />
              <div className="body-left-side">
                <Row className="row-pad-0 custom-row-pad-0">
                  <Col md={1} className="custom-md-1  mt-15">
                    <div className="profile">
                      <span>{CandidatesICon}</span>
                    </div>
                  </Col>
                  <Col md={11} className="content pad-0 custom-md-11 mt-10">


                    <h5 className="min-sub-heading mt-15">
                      <label>{candidateDetail.fname + "  "}{candidateDetail.lname && candidateDetail.lname}  </label>
                    </h5>


                    <div className="flex-Xcenter">
                      {candidateDetail?.email && (
                        <div className="list-title">
                          <label className="label-view">Email:</label>
                          <span className="view-text">{candidateDetail.email}</span>
                        </div>
                      )}
                      {candidateDetail?.phone && (
                        <div className="list-title">
                          <label className="label-view">Phone Number:</label>
                          <span className="view-text">{candidateDetail.phone}</span>
                        </div>
                      )}
                      <div className="list-title">
                        <label className="label-view">jobs Applied:</label>
                        <span className="view-text">{candidateDetail.applied}</span>
                      </div>




                      {candidateDetail?.country_name && (
                        <div className="list-title">
                          <label className="label-view">Country:</label>
                          <span className="view-text">{candidateDetail.country_name}</span>
                        </div>
                      )}

                      {candidateDetail?.city && (
                        <div className="list-title">
                          <label className="label-view">City:</label>
                          <span className="view-text">{candidateDetail.city}</span>
                        </div>
                      )}

                      {candidateDetail?.address && (
                        <div className="list-title">
                          <label className="label-view">Address:</label>
                          <span className="view-text">{candidateDetail.address}</span>
                        </div>
                      )}
                      {candidateDetail?.state_name
                        && (
                          <div className="list-title">
                            <label className="label-view">State name:</label>
                            <span className="view-text">{candidateDetail.state_name}</span>
                          </div>
                        )}
                      {candidateDetail?.zip_code
                        && (
                          <div className="list-title">
                            <label className="label-view">Zip code                            :</label>
                            <span className="view-text">{candidateDetail.zip_code}</span>
                          </div>
                        )}

                      {candidateDetail?.resume && (
                        <div className="list-title">
                          <label className="label-view">Resume:</label>
                          <span className="view-text">
                            <a href="#" onClick={() => handelResumeDocViewer("ResumeDocViewer", candidateDetail?.resume)}> Preview |</a>
                          </span>
                          <span className="view-text">
                            <a href={candidateDetail.resume} target="_blank" download>
                              Download
                            </a>
                          </span>
                        </div>
                      )}
                    </div>
                    {candidateDetail?.skills && candidateDetail.skills.length > 0 ? (
                      <div className="list-location mt-10 mb-10">
                        <label className="label-view" style={{ marginRight: "5px" }}>Skills:</label>
                        {candidateDetail?.skills.map((skill) => (
                          <CustomButton
                            key={skill.skill_id}
                            className="btn-status mr-5 skill-tags"
                            text={skill.skill_name}
                          />
                        ))}
                      </div>) : null}


                    {candidateDetail?.profile_summary && (
                      <div className="list-description">
                        <label className="label-view">Profile Summary:</label>

                        <p class="view-text bg-white pad-10 radius-5">{candidateDetail.profile_summary}</p>

                      </div>
                    )}

                  </Col>

                </Row>
                <Row className="row-pad-0  y-center mt-10 border-x">
                  <Col md={1} className="custom-md-1 mt-1 mt-10">
                    <div className="profile">
                      <span>{Jobs}</span>
                    </div>
                  </Col>
                  <Col md={11} className="pad-0 mt-1 custom-md-11 mt-10">
                    <h5 className="min-sub-heading">
                      <label>Jobs Applied</label></h5>
                  </Col>
                </Row>
                <JobApplied candidateId={candidateId} mainPageClose={handleClose} preferenceValue={userPrefrenceDetail && userPrefrenceDetail?.preference_value?.record_per_page} />
              </div>
              <div className="body-right-side">
                <FeedTimeLine module={"candidates"} module_pk_id={candidateId} mainPageClose={handleClose} />
              </div>
            </Col>
          </>}
        </Container>
      </Offcanvas>

      {showactionlist && <ActionList ID={selectedID} list={actionlistfields} onclick={handleModel} headertext="Action List" handleClose={() => handleCloseModel("actionList")} />}
      {showSelectJob && <InviteLiveInterview UniqueJobCandidate={selectedID} handleClose={() => handleCloseModel("SelectJob")} text="Select Job" modelopnetext="ShareCandidate" />}
      {showInviteAnotherJob && <InviteAnotherJob candidateId={candidateId} handleClose={() => handleCloseModel("InviteAnotherJob")} />}
      {showInviteLiveInterview && <InviteLiveInterview handleClose={() => handleCloseModel("InviteLiveInterview")} text="Invite for Live Interview  " modelopnetext="scheduleInterview" />}
      {/* {showShareCandidate && <RecordedInterview  handleClose={()=>handleCloseModel("ShareCandidate")}/>} */}
      {showInterview && <Interview handleClose={() => handleCloseModel("Interview")} />}
      {showDocViewer && <ViewerFile handleClose={() => handleCloseModel("ResumeDocViewer")} document={candidateDetail?.resume} />}


    </>


  )
}

export default CandidatesDetail;