import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Offcanvas, Container } from 'react-bootstrap';
import { downloadarrowblow, crossicon, fileupploed, uploadfile, deletclrred, email } from '../../helper/Icons/Icon';
import { CustomButton, Label } from '../form';
import { getById } from '../../store/actions/emailTemplateActions';
import * as moment from 'moment';
import Spinner from "../common/Spinner";

const ViewEmailTemplate = ({ handleClose, emailTemplateId }) => {
    const dispatch = useDispatch()

    const { emailTemplateDetail, loading, error } = useSelector((state) => state.emailTemplate);

    useEffect(() => {
        if (emailTemplateId) {
            dispatch(getById(emailTemplateId));
        }

    }, [dispatch]);

    return (
        <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "50%" }}>
            <Offcanvas.Header className="model-header bg-white actionlist-header ">
                <Offcanvas.Title>
                    <span className="model-header-svg">{email}View Email Template</span>
                </Offcanvas.Title>
                <div className="text-end">
                    <CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} />
                </div>
            </Offcanvas.Header>

            {loading &&
                <Spinner />
            }

            {emailTemplateDetail &&
                <Container fluid className="pad-10" style={{ overflowY: "auto" }}>
                    <Row className="mt-20 row-pad-0 bg-white border-radius-5 mt-10 pad-10 view-templae-wrap">
                        <Col md={6} className=" mb-10">
                            <Label className="label-view" text="Template Name" />
                            <div className='view-text'>{emailTemplateDetail.email_template_name}</div>
                        </Col>
                        <Col md={6} className=" mb-10">
                            <Label className="label-view" text="Template Type" />
                            <div className='view-text'>{emailTemplateDetail.email_template_type_name}</div>
                        </Col>
                        <Col md={6} className=" mb-10">
                            <Label className="label-view" text="Created By" />
                            <div className='view-text'>{emailTemplateDetail?.created_by}</div>
                        </Col>
                        <Col md={6} className=" mb-10">
                            <Label className="label-view" text="Created Date" />
                            <div className='view-text'>{moment(emailTemplateDetail.created_date).format('DD-MM-YYYY h:mm A')}</div>
                        </Col>
                        <Col md={6} className=" mb-10">
                            <Label className="label-view" text="Subject Line" />
                            <div className='view-text'>{emailTemplateDetail.subject}</div>
                        </Col>
                        <Col md={12} className=" mb-10">
                            <Label className="label-view" text="Message" />

                            <div className='view-text' dangerouslySetInnerHTML={{ __html: emailTemplateDetail.message }} />
                        </Col>

                    </Row>
                </Container>
            }
        </Offcanvas>
    );
};

export default ViewEmailTemplate;
