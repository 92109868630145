
import { Row, Col } from "react-bootstrap";
import HeaderListing from "../components/common/HeaderListing";
import CandidatesList from "../components/candidate/CandidatesList";
const Candidates = () => {
    return (
        <Row className="row-pad-0">
            <HeaderListing headertext="Candidates" />
            <Col md={12} className="pad-0 mt-10 bg-white border-radius-5 ">
                <CandidatesList />
            </Col>
        </Row>
    )
}

export default Candidates;