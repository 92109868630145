import { createSlice } from '@reduxjs/toolkit'
import { create, update, getAll, getById, deleteSTemplate } from '../actions/smsTemplateActions'


const initialState = {
    loading: false,
    smsTemplates: [],
    smsTemplateDetail: null,
    error: null,
}

const smsTemplateSlice = createSlice({
    name: 'smsTemplate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state, action) => {
                state.loading = true
            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading = false
                //state.smsTemplates.push(action.payload)
            })
            .addCase(create.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(update.pending, (state, action) => {
                state.loading = true
            })
            .addCase(update.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(update.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getAll.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.loading = false
                state.smsTemplates = action.payload
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getById.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getById.fulfilled, (state, action) => {
                state.loading = false
                state.smsTemplateDetail = action.payload
            })
            .addCase(getById.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(deleteSTemplate.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteSTemplate.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteSTemplate.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
    },
})

export default smsTemplateSlice.reducer