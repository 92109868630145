import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const config = require('../../config/config.json');
const API_BASE_URL = config.API_BASE_URL;
const API_BASE_URL_PUBLIC = config.API_BASE_URL_PUBLIC;

const BASE_URL = `${API_BASE_URL}/interviews`;

const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

export const create = createAsyncThunk(
    'interview/create',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.post(`${BASE_URL}`, payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)

export const meetingschedular = createAsyncThunk(
    'interview/interviewsschedular',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.post(`${API_BASE_URL}/interviews-schedular`, payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)
export const update = createAsyncThunk(
    'interview/update',
    async ({ id, payload }, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.put(`${BASE_URL}/${id}`, payload, config)
            return data;
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)


export const getAll = createAsyncThunk(
    'interview/getAll',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            params: payload
        }

        try {
            const { data } = await axios.get(`${BASE_URL}`, config)
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const getById = createAsyncThunk(
    'interview/getById',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${BASE_URL}/${id}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)


export const deleteInterview = createAsyncThunk(
    'interview/deleteInterview',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.delete(`${BASE_URL}/${id}`, config);
            return data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const deleteInterviews = createAsyncThunk(
    'job/deleteInterviews',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.post(`${API_BASE_URL}/interviews-delete`, payload, config)
            return data;
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)
export const downloadinterview = createAsyncThunk(
    'interview/downloadinterview',
    async (interviewId, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },

        }

        try {
            const { data } = await axios.get(`${API_BASE_URL}/download-interview/${interviewId}`, config)
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const downloadLiveinterview = createAsyncThunk(
    'interview/downloadLiveinterview',
    async (interviewId, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },

        }

        try {
            const { data } = await axios.get(`${API_BASE_URL}/download-live-interview/${interviewId}`, config)
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const exportFile = createAsyncThunk(
    'interview/exportFile',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            params: payload
        }

        try {
            const { data } = await axios.get(`${BASE_URL}`, config)
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const getRecordedInterview = createAsyncThunk(
    'interview/getRecordedInterview',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },

        }

        try {
            const { data } = await axios.get(`${BASE_URL}/${id}`, config)
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const getLiveRecordedInterview = createAsyncThunk(
    'interview/getLiveRecordedInterview ',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',

            },

        }

        try {
            const { data } = await axios.get(`${API_BASE_URL_PUBLIC}/retrive-recording/${id}`, config)
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const getInterviewRating = createAsyncThunk(
    'interview/getRecordedInterviewRating',
    async ({ id, payload }, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            params: payload,

        }

        try {
            // const { data } = await axios.get(`${API_BASE_URL}/get-rating/${id}`, config)
            const { data } = await axios.get(`${API_BASE_URL}/interview-evaluators-rating/${id}`, config)

            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const liveinterviewEvaluatorsRating = createAsyncThunk(
    'interview/liveinterviewEvaluatorsRating',
    async ({ id, payload }, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            params: payload


        }

        try {
            // const { data } = await axios.get(`${API_BASE_URL}/get-rating/${id}`, config)
            const { data } = await axios.get(`${API_BASE_URL}/live-interview-evaluators-rating/${id}`, config)

            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const getFinalRemarks = createAsyncThunk(
    'interview/getFinalRemarks',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },

        }

        try {
            const { data } = await axios.get(`${API_BASE_URL}/get-specific-evaluator-rating/${id}`, config)
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const getTimeLineById = createAsyncThunk(
    'interview/getTimeLineById',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL}/interview-timeline/${id}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const saveInterviewTimeline = createAsyncThunk(
    'interviews/saveInterviewTimeline',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.post(`${API_BASE_URL}/interviews-timeline`, payload, config)
            return data;
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)
