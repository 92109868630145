import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Spinner } from 'react-bootstrap';
import { getAll } from '../../store/actions/candidateActions';
import { Label } from '../form';
import { CodeSlash } from 'react-bootstrap-icons';

const AllCadidates = ({ Changehandle }) => {
    const dispatch = useDispatch();
    const { candidates, loading, error } = useSelector((state) => state.candidate);

    let candidateList = candidates.data;
    useEffect(() => {
        dispatch(getAll())

    }, [dispatch]);


    if (!candidateList || !Array.isArray(candidateList)) {
        return (<>

            <Label text="Select Job " className="label-input" HtmlFor="select_job" required="required" />
            <Form.Select name='select_job'  >
                <option value="">Some thing wrong check internect connection</option>

            </Form.Select>
        </>)

    }


    return (
        <>


            <Label text="Select Candidate" HtmlFor="select_job" required="required" />
            <Form.Select name='select_job' id='select_job' onChange={Changehandle} >
                <option value="">Select Candidate from dropdown</option>
                {candidateList.map(option => (
                    <option
                        key={option.id}
                        value={option.id}

                    >
                        {option.fname} {option.lname && option.lname}
                    </option>
                ))}
            </Form.Select>
        </>
    );
};

export default AllCadidates;
