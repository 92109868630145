import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Table, Col } from "react-bootstrap";
import { CustomRating } from "../form/index";
import IndividualEvaluation from '../RecordedInterviewDetails/IndividualEvaluation';
import { liveinterviewEvaluatorsRating } from "../../store/actions/interviewActions";
import * as moment from 'moment';
import { eye } from "../../helper/Icons/Icon";

const RatingsTable = ({ id }) => {
  const [showIndividualEvaluation, setShowIndividualEvaluation] = useState(false);
  const { liveinterviewEvaluatorsRatingData, loading, error } = useSelector((state) => state.interview);
  const [evaluatorId, setEvaluatorId] = useState(null);
  const dispatch = useDispatch();
  const [sortConfig, setSortConfig] = useState({});
  const [searchField, setSearchField] = useState({});

  useEffect(() => {
    if (id) {
      const payload = {
        ...searchField,
        order_by: sortConfig?.key,
        sort_by: sortConfig?.order,
      };
      dispatch(liveinterviewEvaluatorsRating({ id, payload }));
    }
  }, [dispatch, id, sortConfig, searchField]);



  const handleFieldSearch = (e) => {
    const { name, value } = e.target;
    setSearchField((prevSearchField) => ({ ...prevSearchField, [name]: value }));
  };

  const toggleSortOrder = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      order: prevConfig.key === key && prevConfig.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  return (
    <>
      <Col md={12} className="pad-20  bg-white radius-7">
        <h5 className="min-sub-heading pad-10"><label>Ratings</label></h5>
        <Table className="table rating-tabel-wrap" style={{ width: "100%" }} >

          <thead>
            <tr>
              <th style={{ width: "6px" }}><span></span></th>
              <th style={{ minWidth: "100px", width: "150px" }} className="sorting">
                <span onClick={() => toggleSortOrder('name')}>
                  {sortConfig.key === 'name'
                    ? sortConfig.order === 'asc'
                      ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                      : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                    : <i className="fa fa-exchange" aria-hidden="true"></i>}
                </span>

                <Form.Control

                  type="text"
                  name="name"
                  placeholder="Reviewer’s Name"
                  value={searchField.name}
                  onChange={handleFieldSearch}
                />

              </th>
              <th style={{ minWidth: "100px", width: "150px" }} className="sorting">
                <span onClick={() => toggleSortOrder('email_address')}>
                  {sortConfig.key === 'email_address'
                    ? sortConfig.order === 'asc'
                      ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                      : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                    : <i className="fa fa-exchange" aria-hidden="true"></i>}
                </span>

                <Form.Control

                  type="text"
                  name="email_address"
                  placeholder="Email Address"
                  value={searchField.email_address}
                  onChange={handleFieldSearch}
                />

              </th>

              <th style={{ minWidth: "100px", width: "150px" }} className="sorting">
                <span onClick={() => toggleSortOrder('final_rating')}>
                  {sortConfig.key === 'final_rating'
                    ? sortConfig.order === 'asc'
                      ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                      : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                    : <i className="fa fa-exchange" aria-hidden="true"></i>}
                </span>


                <Form.Control

                  type="text"
                  name="final_rating"
                  placeholder="Rating"
                  value={searchField.rating}
                  onChange={handleFieldSearch}
                />

              </th>

              <th style={{ minWidth: "100px", width: "150px" }} className="sorting">
                <span onClick={() => toggleSortOrder('final_remarks')}>
                  {sortConfig.key === 'final_remarks'
                    ? sortConfig.order === 'asc'
                      ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                      : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                    : <i className="fa fa-exchange" aria-hidden="true"></i>}
                </span>

                <Form.Control

                  type="text"
                  name="final_remarks"
                  placeholder="Final Remarks"
                  value={searchField.final_remarks}
                  onChange={handleFieldSearch}
                />

              </th>


              <th style={{ minWidth: "100px", width: "150px" }} className="sorting">
                <span onClick={() => toggleSortOrder('created_date')}>
                  {sortConfig.key === 'created_date'
                    ? sortConfig.order === 'asc'
                      ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                      : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                    : <i className="fa fa-exchange" aria-hidden="true"></i>}
                </span>

                <Form.Control

                  type="text"
                  name="created_date"
                  placeholder="Requested On"
                  value={searchField.created_date}
                  onChange={handleFieldSearch}
                />

              </th>

              <th style={{ minWidth: "100px", width: "150px" }} className="sorting">
                <span onClick={() => toggleSortOrder('created_by_name')}>
                  {sortConfig.key === 'created_by_name'
                    ? sortConfig.order === 'asc'
                      ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                      : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                    : <i className="fa fa-exchange" aria-hidden="true"></i>}
                </span>
                <Form.Control

                  type="text"
                  name="created_by_name"
                  placeholder="Requested By"
                  value={searchField.created_by_name}
                  onChange={handleFieldSearch}
                />

              </th>
              <th style={{ minWidth: "100px", width: "150px" }} className="sorting">
                <span onClick={() => toggleSortOrder('evaluation_created_date')}>
                  {sortConfig.key === 'evaluation_created_date'
                    ? sortConfig.order === 'asc'
                      ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                      : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                    : <i className="fa fa-exchange" aria-hidden="true"></i>}
                </span>
                <Form.Control
                  type="text"

                  name="evaluation_created_date"
                  placeholder="Evaluation Date"
                  value={searchField.evaluation_created_date}
                  onChange={handleFieldSearch}
                />

              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="8" className="text-center">Loading...</td>
              </tr>
            ) : liveinterviewEvaluatorsRatingData?.data?.length === 0 ? (
              <tr>
                <td colSpan="8" className="text-center"><h6>Data not found</h6></td>
              </tr>
            ) : (
              liveinterviewEvaluatorsRatingData?.data?.map((rating, index) => (
                <tr key={index}>
                  <td></td>
                  <td className="td-text text-ellipsis"> <a href="#">{rating.name}</a></td>
                  <td className="td-text text-ellipsis">{rating.email_address}</td>
                  <td className="td-text text-ellipsis"><CustomRating value={rating.final_rating} edit={false} /></td>
                  <td className="td-text text-ellipsis"><span className="ellipsis">{rating.final_remarks}</span></td>
                  <td className="td-text text-ellipsis">{moment(rating.requested_on).format('DD-MM-YYYY h:mm A')}</td>
                  <td className="td-text text-ellipsis">{rating.created_by_name}</td>
                  <td className="td-text text-ellipsis">{moment(rating.evaluation_created_date).format('DD-MM-YYYY h:mm A')}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Col >
      {showIndividualEvaluation && <IndividualEvaluation evaluatorId={evaluatorId} handleClose={() => setShowIndividualEvaluation(false)} />
      }
    </>
  );
};

export default RatingsTable;
