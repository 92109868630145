import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MyPreferences = ({ preference, PreferenceHandle }) => {
  const [isAccordionOpen, setAccordionOpen] = useState(true);
  const [showPreference, setPreference] = useState([10, 25, 50, 100]);
  const toggleAccordion = () => {
    setAccordionOpen(!isAccordionOpen);
  };


  return (
    <Accordion className='pad-0 bg-white border-radius-5'>
      <Row className='pad-5 mt-1 mb-1 bg-white row-pad-0 border-radius-5'>
        <Col md={6} className='d-flex justify-content-start align-items-center '><h5 className='sub-heading mt-2'>My Preferences</h5></Col>
        <Col className="d-flex justify-content-end align-items-center" md={6}>
          <a href="#" className="ml-10 mt-1 mr-10" onClick={toggleAccordion}>
            <i className={`fa ${isAccordionOpen ? 'fa-angle-up' : ' fa-angle-down'} clr-light-black`} style={{ fontSize: "25px" }}></i>
          </a>
        </Col>
      </Row>
      <span className='single-border d-block'></span>
      <Accordion.Collapse in={isAccordionOpen}>
        <Row className='row-pad-0 mt-10 pb-3'>

          <Col className='pad-0' md={12}>
            <Row className='row-pad-0 '>
              {preference && <Col md={12} className="d-flex" style={{ alignItems: "center" }}>
                <label className='ml-10 label-view mr-10'>Records Per Page:</label>
                {!showPreference || showPreference.length === 0 ? (
                  <small className='ml-10 span-data'>No preferences available.</small>
                ) : (
                  <Form.Select style={{ width: '200px' }} name="record_per_page" onChange={PreferenceHandle}>
                    <option value="">Select template type</option>
                    {showPreference.map((value, index) => (
                      <>
                        <option key={index}

                          value={value}


                          selected={preference[0]?.preference_value?.record_per_page && preference[0]?.preference_value?.record_per_page == value}

                        >





                          {value}

                        </option>
                      </>

                    ))}
                  </Form.Select>
                )}
              </Col>
              }

            </Row>
          </Col>

        </Row>
      </Accordion.Collapse>
    </Accordion>
  );
}

export default MyPreferences;
