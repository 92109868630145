import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import HeaderCompanName from "../components/company/HeaderCompanName";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useParams, useLocation } from "react-router-dom";
import { companytoken, jobtoken } from "../store/actions/InterviewproceedAction";

const config = require('../config/config.json');

const LayoutLiveInterviews = () => {
    const { token } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();

    const { companyDetail, jobDetail } = useSelector((state) => state.Interviewproceed);

    useEffect(() => {
        if (token) {
            dispatch(companytoken(token));
            dispatch(jobtoken(token));
        }
    }, [dispatch, token]);

    const { company_logo, header_footer_colour_code, company_name } = companyDetail;
    const backgroundColor = header_footer_colour_code || "#fff";
    const companyName = company_name;


    const isLiveInterviewRoute = location.pathname.includes("live_interview");

    return (
        <>
            <Col className="pad-0 sm-for-btn-layout-recorded-wrap" md={12} sm={12}>
                <HeaderCompanName
                    logo={<img src={`${company_logo}`} style={{ width: "100%", height: "100%" }} alt="company logo" />}
                    bgclr={backgroundColor}
                    name={companyName}
                />

                <Col
                    md={12}
                    className={` ${isLiveInterviewRoute ? 'custom-pad-1' : 'y-scroll pad-10'} bg-ligte-gray`}
                    style={{ overflowY: isLiveInterviewRoute ? "hidden" : "auto" }}
                >

                    {!isLiveInterviewRoute && (
                        <Col md={12} className="row-pad-0 pl-10 row">
                            <label>
                                <span className="main-heading">{jobDetail?.job_title}-</span>
                                <span>{jobDetail?.company_name}</span>
                            </label>
                        </Col>
                    )}

                    <Row className="row-pad-0 sm-for-btn-layout-recorded-wrap">
                        <Outlet />
                    </Row>
                </Col>
            </Col>
        </>
    );
};

export default LayoutLiveInterviews;
