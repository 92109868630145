import { createSlice } from '@reduxjs/toolkit'
import { create, update, getAll, companyhighlights, getById, UplodedFiles, deleteCompany, getCompanyJobs, companyPreview, getAllPhotos } from '../actions/companyActions'

const initialState = {
    loading: false,
    companies: [],
    CompanyJobs: [],
    fileUploded: [],
    companyDetail: null,
    companyPreviewData: null,
    highlightData: null,
    AllPhotos: null,
    error: null,
}

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state, action) => {
                state.loading = true
            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(create.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(update.pending, (state, action) => {
                state.loading = true
            })
            .addCase(update.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(update.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getAll.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.loading = false
                state.companies = action.payload
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getAllPhotos.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAllPhotos.fulfilled, (state, action) => {
                state.loading = false
                state.AllPhotos = action.payload
            })
            .addCase(getAllPhotos.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getById.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getById.fulfilled, (state, action) => {
                state.loading = false
                state.companyDetail = action.payload
            })
            .addCase(getById.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(deleteCompany.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteCompany.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteCompany.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(getCompanyJobs.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getCompanyJobs.fulfilled, (state, action) => {
                state.loading = false
                state.CompanyJobs = action.payload
            })
            .addCase(getCompanyJobs.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })


            .addCase(UplodedFiles.pending, (state, action) => {
                state.loading = true
            })
            .addCase(UplodedFiles.fulfilled, (state, action) => {
                state.loading = false
                state.fileUploded = action.payload
            })
            .addCase(UplodedFiles.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(companyPreview, (state, action) => {

                state.companyPreviewData = action.payload.data
            }).addCase(companyhighlights.pending, (state, action) => {
                state.loading = true
            })
            .addCase(companyhighlights.fulfilled, (state, action) => {
                state.loading = false
                state.highlightData = action.payload
            })
            .addCase(companyhighlights.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
    },
})



export default companySlice.reducer
