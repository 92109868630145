import React, { useState, useEffect, useMemo } from 'react';
import FileUploadView from './FileUploadView';
import { Row, Tab, Nav } from 'react-bootstrap';
import { ringcopy, videobox, mcqQuestion, textquestion, uploadfile, shareleftarrow, thingtime, retake, responsestime, word } from "../../helper/Icons/Icon";
import FileUploadViewInternal from './FileUploadViewInternal';
import { Checkbox } from "../form";
import InternalComment from './InternalComment';
const config = require('../../config/config.json');

const icons = { 'Video Question': videobox, 'Audio': ringcopy, 'Multiple Choice': mcqQuestion, 'Written': textquestion, 'File Upload': uploadfile };
const text = { 1: "Video Question", 2: "Audio Question", 3: "MCQ Question", 4: "Text Question", 5: "File Upload Question" }

const CandidateRespones = ({ responses, setUpdateList, save }) => {
    const [answerId, setAnswerId] = useState(0);
    const checkbox = <span>✔️</span>;
    const crossbox = <span>❌</span>;
    const [selectedAnswer, setSelectedAnswer] = useState(null);

    const questions = useMemo(() => {
        return responses ?? [];
    }, [responses]);

    useEffect(() => {
        if (answerId !== null && questions.length > 0) {
            const currentQuestion = questions[answerId];
            if (currentQuestion?.answers?.length > 0) {
                setSelectedAnswer(currentQuestion.answers[0]);
            } else {
                setSelectedAnswer(null);
            }
            setSelectedAnswer(currentQuestion);
        }
    }, [answerId, questions, selectedAnswer]);


    const handleTabSelect = (index) => {
        setAnswerId(index);
    };
    const handleOptionChange = (value) => {

        setSelectedAnswer(prev => ({ ...prev, answer: value }));
    };
    return (
        <Row className="row-pad-0 bg-white border-radius-5">

            <h6 className='pad-0 mt-10 sub-heading '><label>Candidate’s Responses</label> </h6>
            <Row className='row-pad-0 pad-0'>
                <Tab.Container id="left-tabs-example" className="" >
                    <div className='responses-wrap pad-0 mt-10'>
                        <Nav variant="pills" className="responses-wrap-nav">
                            {questions && questions.map((answer, index) => (
                                <Nav.Item key={index + 1}>
                                    <Nav.Link className={`box cursor ${index === answerId ? 'active' : ''}`} onClick={() => handleTabSelect(index)}>
                                        <span>{icons[answer.answer_media]}</span>
                                        <span className='question-nav-link '>Question {index + 1}-{text[answer.question_type_id]}</span>
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <div className='Response-left-side'>
                            <Row className='row-pad-0'>
                                {selectedAnswer && (
                                    <>
                                        <div className='Response-div question sm-Response-div row-pad-0 '>
                                            <p>{selectedAnswer.question_text}</p>
                                        </div>


                                        <div className='Response-div sm-Response-div thingtime-wrap d-flex mt-20 row-pad-0 pad-10'>
                                            <div className='thingtime'>
                                                <small>{thingtime}Think Time:</small>
                                                <span>
                                                    <small className={selectedAnswer?.answer_think_time > selectedAnswer?.question_think_time ? "resgreater" : ""}>
                                                        {selectedAnswer?.answer_think_time && selectedAnswer?.answer_think_time}
                                                    </small>
                                                    /<small>{selectedAnswer.question_think_time && selectedAnswer.question_think_time} Mins</small>

                                                </span>



                                            </div>
                                            {(selectedAnswer.question_type_id === 1 || selectedAnswer.question_type_id === 2) &&
                                                <>
                                                    <div className='responestime'>
                                                        <small>{responsestime}Response Time:</small><span>
                                                            <small className={selectedAnswer?.answer_response_time > selectedAnswer?.question_response_time ? "resgreater" : ""}>
                                                                {selectedAnswer?.answer_response_time}
                                                            </small>
                                                            /
                                                            <small>{selectedAnswer?.question_response_time}</small> Mins
                                                        </span>
                                                    </div>
                                                    <div className='retakes'>
                                                        <small>{retake} Retakes:</small>
                                                        <span>
                                                            {selectedAnswer?.answer_retake ?? 0}/{selectedAnswer.question_retakes ?? selectedAnswer.retakes}
                                                        </span>
                                                    </div>
                                                </>
                                            }
                                        </div>


                                        {selectedAnswer.question_type_id === 1 && (

                                            <div className='mt-20 Response-wrap response-wrap-answer-div pad-10'>
                                                <video
                                                    width="100%" height="100%"
                                                    controls
                                                    src={`${selectedAnswer?.answer}`}
                                                />

                                            </div>

                                        )}

                                        {selectedAnswer.question_type_id === 2 && (

                                            <div className='mt-20 Response-wrap response-wrap-answer-div  audio-center pad-10'>
                                                <audio
                                                    width="100%"
                                                    height="100%"
                                                    controls
                                                    src={`${selectedAnswer?.answer}`}
                                                />

                                            </div>

                                        )}

                                        {selectedAnswer.question_type_id === 3 && (

                                            <div className='Response-wrap response-wrap-answer-div option-overflow-wrap-answer  pad-10 mt-20'>
                                                {selectedAnswer?.question_options.map((option, index) => (
                                                    <>
                                                        <div className='Response-MCQResponse' key={index} style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between'
                                                        }}>
                                                            <Checkbox
                                                                label={option.value}
                                                                type="radio"
                                                                id={`option${index + 1}`}
                                                                name="options"
                                                                onClick={() => handleOptionChange(option.value)}
                                                                checked={option.value === selectedAnswer.answer}
                                                            />

                                                            {option.is_correct === 1 ? (
                                                                option.value === selectedAnswer.answer ? checkbox : checkbox
                                                            ) : (
                                                                option.value === selectedAnswer.answer ? crossbox : null
                                                            )}
                                                        </div>
                                                    </>
                                                ))}
                                            </div>

                                        )}

                                        {selectedAnswer.question_type_id === 4 && (

                                            <div className='Response-wrap response-wrap-answer-div mt-20 pad-10'>
                                                <p className='text-p' dangerouslySetInnerHTML={{ __html: selectedAnswer?.answer }} />
                                            </div>

                                        )}

                                        {selectedAnswer.question_type_id === 5 && (


                                            <div className='Response-wrap response-wrap-answer-div pad-10 mt-20'>
                                                <FileUploadViewInternal document={`${selectedAnswer?.answer}`} />
                                            </div>

                                        )}

                                        <InternalComment selectedAnswer={selectedAnswer} setUpdateList={setUpdateList} save={save} />
                                    </>
                                )}
                            </Row>
                        </div>
                    </div>
                </Tab.Container>
            </Row>
        </Row >
    )
}

export default CandidateRespones;
