import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const config = require('../../config/config.json');
const API_BASE_URL = config.API_BASE_URL;

const BASE_URL = `${API_BASE_URL}/field`;

const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null



export const getModuleFields = createAsyncThunk(
    'field/getAll',
    async (module, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
          //  params: payload
        }

        try {
            const {data} = await axios.get(`${BASE_URL}/${module}`, config)
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
