import React, { useState } from 'react';
import { Tab, Nav, Row, Col } from 'react-bootstrap';
import { Ats, ComingSoon, OtherIntegrations } from '../helper/Icons/Icon';
function IntegrationsSetting() {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Row className="row-pad-0 settings-wrap">

      <Col md={12} className='pad-0 IntegrationsSetting-tab '>
        <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
          <Row>
            <Col sm={3} className='template-wrap-nav   '>
              <Nav variant="pills" className="flex-column pad-20 bg-white">
                <Nav.Item>
                  <Nav.Link eventKey="tab1">{Ats} ATS Integrations</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab2">{OtherIntegrations} Other Integrations</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab3">{ComingSoon} Coming Soon</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9} className=' bg-ligte-gray'>
              <Tab.Content className=''>
                <Tab.Pane eventKey="tab1">
                  <Row className="row-pad-0">
                    <h2> Integrations are not available</h2>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="tab2">
                  <h2> Integrations are not available</h2>

                </Tab.Pane>
                <Tab.Pane eventKey="tab3">
                  <h2> Integrations are not available</h2>

                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Col>
    </Row>


  );
}



export default IntegrationsSetting;