import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getModuleFields } from '../actions/tableFieldsActions'

const initialState = {
    loading: false,
    fields: [{"id":1}],
    error: null,
}
// ss
const tableFieldsSlice = createSlice({
    name: 'field',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getModuleFields.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getModuleFields.fulfilled, (state, action) => {
                state.loading = false
                state.fields = action.payload
            })
            .addCase(getModuleFields.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
    },
})

export default tableFieldsSlice.reducer