
import ButtonGroup from "react-bootstrap/ButtonGroup";
import React, { useState, useEffect, useRef } from 'react';
import { Table, Dropdown, Form, Row, Col } from "react-bootstrap";
import { Checkbox, CustomButton, InputField } from "../form";
import { useDispatch, useSelector } from "react-redux";
import Profile from '../../pages/Profile';
import { getModuleActivities } from "../../store/actions/activityLogsActions";
import { getAll } from "../../store/slices/activityTypeSlice";
import * as moment from 'moment'
import Spinner from "../common/Spinner";
import JobDetail from "../job/JobDetail";
import CandidatesDetail from '../candidate/CandidatesDetail';
const FeedTimeLine = ({ module, module_pk_id, mainPageClose }) => {
    const dispatch = useDispatch();
    const dropdownRef = useRef(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [showDetailPage, setShowDetailPage] = useState(false);
    const [showCandidatesDetailPage, setCandidatesDetailPage] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const { activityLogs, loading, error } = useSelector((state) => state.activityLogs);
    const typeList = useSelector((state) => state.activityType.activityTypes);

    useEffect(() => {
        dispatch(getModuleActivities({ module, module_pk_id }));
        dispatch(getAll());

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [dispatch]);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            setSelectedItems(typeList.map(stage => stage.activity_type_id));
        } else {
            setSelectedItems([]);
        }
    };


    const handleCheckboxChange = (id) => {
        setSelectedItems(prevSelectedItems => {
            if (prevSelectedItems.includes(id)) {
                return prevSelectedItems.filter(itemId => itemId !== id);
            } else {
                return [...prevSelectedItems, id];
            }
        });
    };

    const handleSave = () => {
        let pf = { ...module, activity_type_id: selectedItems, activity: searchQuery }
        dispatch(getModuleActivities(pf));
        setDropdownOpen(false);
    };

    const handleReset = () => {
        dispatch(getModuleActivities(module));
        setSelectedItems([]);
        setDropdownOpen(false);
    };

    const handleChange = (e) => {
        const { value } = e.target;
        if (value.length > 3 || value.length === 0) {
            setSearchQuery(value);
            let ps = { ...module, activity_type_id: selectedItems, activity: value }
            dispatch(getModuleActivities(ps));

        };
    };

    const handleLogClick = (log) => {

        if (log.module_code == "jobs") {
            setSelectedJob(log.module_pk_id);
            setShowDetailPage(true);

        }
        if (log.module_code == "companies") {
            sessionStorage.setItem('companyID', log.module_pk_id);
            window.open('/company', '_blank');
        }
        if (log.module_code == "candidates") {
            setSelectedJob(log.module_pk_id);
            setCandidatesDetailPage(true);

        }
    };
    const handleLogClose = (logtype) => {
        if (logtype == "jobs") {
            mainPageClose()
            setShowDetailPage(false);
        }
        if (logtype == "candidates") {
            mainPageClose()
            setCandidatesDetailPage(false);
        }

    }
    return (
        <>
            {loading &&
                <Spinner />
            }

            <Col md={12} className="activity-feedHeader">
                <Col md={12} className="mb-10 Search-filter-wrap ">
                    <h6 className="min-sub-heading">Feeds</h6>
                    <div className="d-flex">
                        <InputField
                            type="text"
                            className="Search-filter-input"
                            placeholder="Search"
                            onChange={handleChange}
                        />
                        <Dropdown show={dropdownOpen} ref={dropdownRef}>
                            <Dropdown.Toggle className="Search-filter" id="dropdown-custom-2" onClick={() => setDropdownOpen(!dropdownOpen)}>
                                <i className="fa fa-filter fa-fw"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='stages-menu'>
                                <Form.Check
                                    type="checkbox"
                                    label="Select All"
                                    onChange={handleSelectAllChange}
                                    checked={typeList?.length > 0 && selectedItems.length === typeList.length}
                                />

                                {typeList &&
                                    typeList.map((type, index) => (
                                        <Form.Check
                                            key={index}
                                            type="checkbox"
                                            label={type.activity_type_name}
                                            checked={selectedItems.includes(type.activity_type_id)}
                                            onChange={() => handleCheckboxChange(type.activity_type_id)}
                                        />
                                    ))}
                                <Dropdown.Divider />


                                <div className="d-flex text-end">
                                    <CustomButton style={{ whiteSpace: "nowrap" }} className="custom-btn-primary" text="Apply" onClick={handleSave} />
                                    <CustomButton text="Reset" className="mr-10 ml-10 custom-btn-outline" onClick={handleReset} />

                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Col>
                <span class="single-border d-block  mt-10"></span>

                {activityLogs && Array.isArray(activityLogs) && activityLogs.length > 0 ? (
                    activityLogs.map((activity, index) => (

                        <div className="activity-feed mt-10" key={index}>
                            <div className="feed-item">
                                <div className="date">
                                    <i className="glyphicon glyphicon-edit"></i>{" "}

                                    {activity.created_by_name}

                                    <span className="pull-right">
                                        {activity.created_date && moment(activity.created_date).format("MM-DD-YYYY h:mm A")}
                                    </span>
                                </div>
                                {/* <div className="text mt-1">
                                    {activity.module_code} <a href='#' onClick={() => handleLogClick(activity)}>{activity.field_name}</a> {activity.activity}
                                </div> */}
                                <div className="text mt-1">
                                    {activity.module_code && activity.module_code.includes('_')
                                        ? activity.module_code.replace(/_/g, "   ")
                                        : activity.module_code}

                                    {
                                        (activity.module_code === "candidates" || activity.module_code === "jobs" || activity.module_code === "companies")
                                            ? (

                                                <a href='#' onClick={() => handleLogClick(activity)} style={{ marginLeft: "5px", marginRight: "5px" }}>
                                                    {activity.field_name && activity.field_name.includes('_')
                                                        ? activity.field_name.replace(/_/g, "   ")
                                                        : activity.field_name}
                                                </a>
                                            ) : (

                                                <span style={{ marginLeft: "5px", marginRight: "5px" }}>
                                                    {activity.field_name && activity.field_name.includes('_')
                                                        ? activity.field_name.replace(/_/g, "   ")
                                                        : activity.field_name}
                                                </span>

                                            )
                                    }


                                    {activity.activity && activity.activity.includes('_')
                                        ? activity.activity.replace(/_/g, "   ")
                                        : activity.activity}

                                </div>

                            </div>
                        </div>

                    ))
                ) : (
                    <p>No activity logs found.</p>
                )}


            </Col>
            {/* {showProfile && <Profile profileId={UserProfileId} handleClose={handleClose} />} */}
            {showDetailPage && <JobDetail handleClose={() => handleLogClose("jobs")} jobId={selectedJob} />}
            {showCandidatesDetailPage && <CandidatesDetail handleClose={() => handleLogClose("candidates")} candidateId={selectedJob} />}
        </>
    );
};

export default FeedTimeLine;
