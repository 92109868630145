import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import HeaderCompanName from "../components/company/HeaderCompanName";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useParams, useLocation } from "react-router-dom";
import { companytoken, jobtoken } from "../store/actions/InterviewproceedAction";

const config = require('../config/config.json');


const LayoutRecorded = () => {
    const { token } = useParams();
    const dispatch = useDispatch();
    const [linkUsed, setLinkUsed] = useState(null)
    const location = useLocation();

    const { companyDetail, jobDetail } = useSelector((state) => state.Interviewproceed);

    useEffect(() => {
        if (token) {
            dispatch(companytoken(token));
            dispatch(jobtoken(token)).then((res) => {
                if (res.payload.error) {
                    setLinkUsed(res.payload.error)
                }

            });

        }
    }, [dispatch, location]);

    const { company_logo, header_footer_colour_code, company_name } = companyDetail;
    const backgroundColor = header_footer_colour_code || "#fff";
    const companyName = company_name;

    return (
        <>
            <Col className="pad-0" md={12} sm={12}>
                <HeaderCompanName
                    logo={<img src={`${company_logo}`} style={{ width: "100%", height: "100%" }} alt="company logo" />}
                    bgclr={backgroundColor}
                    name={companyName}
                />

                <Col md={12} className="pad-20 sm-pad-0  sm-pt-10 bg-ligte-gray y-scroll layout-recorded-wrap sm-for-btn-layout-recorded-wrap">
                    <Col md={12} className="row-pad-0 pl-10 sm-pl-0 sm-pt-10  row "> <label ><h2 className="sub-heading">{jobDetail?.job_title}-<span>{jobDetail?.company_name}</span></h2> </label>   </Col>
                    <Row className="row-pad-0">
                        {(linkUsed && (location.pathname !== `/meeting-request/${token}` && location.pathname !== `/exit-message/${token}`)) ?
                            <div className="link-error-wrap">
                                <div className="link-error"><h1>{linkUsed}</h1></div>
                            </div>
                            :
                            <Outlet />

                        }
                    </Row>
                </Col>
            </Col>
        </>
    );
};

export default LayoutRecorded;
