import { Row, Col } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";

const TemplateLayout = () => {

    return (
        <>
            <Row className="row-pad-0 layout-template-wrap" md={11} sm={11}>

                <Col md={12} className="scroll-auto" >
                    <Row className=" row-pad-0 bg-white radius-5 mb-20 pad-20  ">
                        <Col md={12} className="template-wrap-nav">
                            <NavLink to="interview-template">Interviews Templates</NavLink>
                            <NavLink to="sms-template">SMS Templates</NavLink>
                            <NavLink to="email-template">Email Templates</NavLink>

                        </Col>

                    </Row>

                    <Row className="listing-section row-pad-0  radius-5 bg-white ">
                        <Outlet />
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default TemplateLayout;