import { ChangePasswordActionList, jobedit, deletegray, } from "../../helper/Icons/Icon";
import users from '../../assets/images/users.png';

export const actionlistfields = [
  {
    icon: ChangePasswordActionList,
    text: "Change Password",
    props: "ChangePassword"
  },
  // {
  //     icon: viewdetails,
  //     text: "View Job Details",
  //     props:"ViewJobDetails"
  // },
  {
    icon: jobedit,
    text: "View Details",
    props: "profile"
  },

  // {
  //   icon: deletegray,
  //   text: "Delete",
  //   props: "Delete"
  // }
];

