import Accordion from 'react-bootstrap/Accordion';
import { Row, Col, Spinner } from "react-bootstrap";
import { edit } from '../../helper/Icons/Icon';
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import JobDetail from '../job/JobDetail';
import Profile from '../../pages/Profile';
import CandidatesDetail from '../candidate/CandidatesDetail';
import { profileActivityLog } from '../../store/actions/activityLogsActions';
import * as moment from 'moment';
import { Navigate, useNavigate } from 'react-router-dom';
const TimeLine = ({ id }) => {
  const [isAccordionOpen, setAccordionOpen] = useState(true);
  const [showDetailPage, setShowDetailPage] = useState(false);
  const [showProfile, setProfileShow] = useState(false);
  const [showCandidatesDetailPage, setCandidatesDetailPage] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleAccordion = () => {
    setAccordionOpen(!isAccordionOpen);
  };

  const { profileLogs, loading, error } = useSelector((state) => state.activityLogs);

  useEffect(() => {
    if (id) {
      dispatch(profileActivityLog(id));
    }

  }, [dispatch, id]);

  const handleLogClick = (log) => {

    if (log.module_code == "jobs") {

      setSelectedJob(log.module_pk_id);
      setShowDetailPage(true);


    }
    if (log.module_code == "companies") {
      sessionStorage.setItem('companyID', log.module_pk_id);
      window.open('/company', '_blank');
    }
    if (log.module_code == "candidates") {
      setSelectedJob(log.module_pk_id);
      setCandidatesDetailPage(true);

    }

  };
  const handleLogClose = (logtype) => {
    if (logtype == "jobs") {
      setShowDetailPage(false);
      setCandidatesDetailPage(false);
    }
    if (logtype == "candidates") {
      setCandidatesDetailPage(false);
      setShowDetailPage(false);
    }







  };
  // const UserProfileModel = (log) => {
  //   setSelectedJob(log.created_by);
  //   setProfileShow(true)

  // }
  return (
    <>
      <Accordion className='pad-0 bg-white border-radius-5  '>
        <Row md={12} className='pad-5 mt-1 m-1 bg-white border-radius-5 row-pad-0'>
          <Col md={6} className='d-flex justify-content-start align-items-center ' ><h5 className='sub-heading mt-1'>Timeline</h5></Col>
          <Col className="d-flex justify-content-end align-items-center" md={6}>
            <a href="#" className='ml-10 ' onClick={toggleAccordion}>
              <i className={`fa ${isAccordionOpen ? 'fa-angle-up' : ' fa-angle-down'} clr-light-black`} style={{ fontSize: "24px" }}></i>
            </a>
          </Col>
        </Row>
        <span className='single-border d-block'></span>

        <Accordion.Collapse in={isAccordionOpen} style={{ height: 'calc(840px - 200px)', overflowY: 'auto' }}>
          <Row className='row-pad-0'>
            {loading && <Spinner />}
            <Col md={12} className="activity-feedHeader" style={{ height: '500px', overflowY: 'auto' }}>
              {profileLogs && Array.isArray(profileLogs) && profileLogs.length > 0 ? (
                profileLogs.map((label, index) => (
                  <div className="activity-feed" key={index}>
                    <div className="feed-item mb-10">
                      <div className="date">
                        <i>{edit}</i> | {label.created_by_name}<span className="pull-right">{moment(label.created_date).format('DD-MM-YYYY h:mm A')}</span>
                      </div>
                      <div className="text mt-1">
                        {
                          label.module_code
                            ? label.module_code.includes('_')
                              ? label.module_code.replace(/_/g, " ")
                              : label.module_code === "candidates"
                                ? "candidate"
                                : label.module_code === "jobs"
                                  ? "job"
                                  : label.module_code === "companies"
                                    ? "company"
                                    : label.module_code === "interviews"
                                      ? "interview"
                                      : label.module_code
                            : null
                        }



                        {
                          (label.module_code === "candidates" || label.module_code === "jobs" || label.module_code === "companies")
                            ? (

                              label.activity.includes('Deleted') ? <span>  {label.field_name && label.field_name.includes('_')
                                ? label.field_name.replace(/_/g, "   ")
                                : label.field_name} </span> : <a href='#' onClick={() => handleLogClick(label)} style={{ marginLeft: "5px", marginRight: "5px" }}>
                                {label.field_name && label.field_name.includes('_')
                                  ? label.field_name.replace(/_/g, "   ")
                                  : label.field_name}
                              </a>
                            ) : (

                              <span style={{ marginLeft: "5px", marginRight: "5px" }}>
                                {label.field_name && label.field_name.includes('_')
                                  ? label.field_name.replace(/_/g, "   ")
                                  : label.field_name}
                              </span>

                            )
                        }


                        {label.activity.includes('_')
                          ? label.activity.replace(/_/g, "   ")
                          : label.activity}
                      </div>

                      <span className='single-border d-block mt-1'></span>
                    </div>
                  </div>
                ))
              ) : (
                <p>No activity logs found.</p>

              )}
            </Col>
          </Row>
        </Accordion.Collapse>
      </Accordion>
      {showDetailPage && (
        <>

          <JobDetail handleClose={() => handleLogClose("jobs")} jobId={selectedJob} />
        </>
      )}
      {showCandidatesDetailPage && <CandidatesDetail handleClose={() => handleLogClose("candidates")} candidateId={selectedJob} />}
      {showProfile && <Profile handleClose={() => handleLogClose("UserProfile")} profileId={selectedJob} />}
    </>
  );
}

export default TimeLine;
