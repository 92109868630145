import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const config = require('../../config/config.json');
const API_BASE_URL = config.API_BASE_URL;

const BASE_URL = `${API_BASE_URL}/emails-template-type`;

const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

const initialState = {
    loading: false,
    emailTemplateTypes: [],
    error: null,
}

const emailTemplateTypeSlice = createSlice({
    name: 'emailTemplateType',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAll.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.loading = false
                state.emailTemplateTypes = action.payload
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })

    },
})

export const getAll = createAsyncThunk(
    'emailTemplateType/getAll',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${BASE_URL}`, config)
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export default emailTemplateTypeSlice.reducer