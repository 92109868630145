import { Offcanvas, Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CriclrDiv from "../common/CriclrDiv";
import { CustomRating } from "../form/index";
import { crossicon, share, downloadarrowblow, Video, HiredICon } from "../../helper/Icons/Icon";
import { CustomButton } from "../form/index";
import aryaimg from "../../assets/images/users.png"
import { downloadLiveinterview } from '../../store/actions/interviewActions';
import { getRecordedInterview } from '../../store/actions/interviewActions';
import { commentCreateHired } from '../../store/actions/recorderInterviewAction';

import TimeLineLive from "./TimeLineLive";
import RatingsTabelLiveInterview from './RatingsTabelLiveInterview';
import RatingsTabel from './RatingsTabel';
import CommentModel from '../common/CommentModel';
import CustomAlert from '../common/CustomAlert';
import ShareCandidateLiveInterview from "../common/ShareCandidateLiveInterview";
import Swal from 'sweetalert2';
import Spinner from "../common/Spinner";
import LiveInternalComment from "../common/LiveInternalComment";
const sweetAlert = (type, title, text) => {
  Swal.fire({
    icon: type,
    title: title,
    text: text,
    showConfirmButton: false,
    timer: 2500
  });
}
const Liveterview = ({ handleClose, id }) => {
  const [updateList, setUpdateList] = useState(true)
  const [showShareCandidate, setShowShareCandidate] = useState(false);


  const [showHiredCandidate, setHiredCandidate] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [commentData, setCommentData] = useState();
  const dispatch = useDispatch();

  const { recordedInterview, loading, error } = useSelector((state) => state.interview);
  const candidate = recordedInterview?.interview_candidate;
  const interviewId = recordedInterview?.interview_id;
  const candidateId = candidate?.candidate_id;
  const recording = recordedInterview?.media_location ?? null;
  const InterviewUnderway = recordedInterview?.interview_stage?.stage_name.includes("Interview Underway") && recordedInterview?.interview_stage?.stage_name.includes("Interview Underway")
  const Invited = recordedInterview?.interview_stage?.stage_name.includes("Invited") && recordedInterview?.interview_stage?.stage_name.includes("Invited")


  useEffect(() => {
    if (id) {
      dispatch(getRecordedInterview(id));
    }
  }, [dispatch, id, updateList]);



  const handelModel = (props) => {


    if (props === "Evaluation") {
      setShowShareCandidate(true);
    }
    if (props === "download") {
      InterviewsDownload(interviewId)
    }
    if (props === "Hired") {
      setHiredCandidate(true)
    }
  }

  const handelCloseModel = (props) => {


    if (props === "Evaluation") {
      setShowShareCandidate(false);
    }
    if (props === "alert") {
      setShowAlert(false);
    }
    if (props === "Hired") {
      setHiredCandidate(false)
    }
  }
  const InterviewsDownload = (interviewId) => {

    if (interviewId) {
      dispatch(downloadLiveinterview(interviewId))
        .then((res) => {


          if (res.error)
            Swal.fire({
              icon: 'error',
              title: 'Download Failed',
              text: `${res.error.message}`,
              showConfirmButton: false,
              timer: 2500
            });
          else {

            const blob = new Blob([res.payload.data], { type: 'video/mp4' });


            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'video.mp4';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);


            URL.revokeObjectURL(link.href);

            Swal.fire({
              icon: 'success',
              title: 'Interview Downloaded',
              text: `Interview downloaded successfully.`,
              showConfirmButton: false,
              timer: 2000
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: 'Download Failed',
            text: `Failed to download invoice: ${error.message}`,
          });
        });

    }

  }




  const handelComment = (e) => {
    const { name, value } = e.target;
    setCommentData({
      ...commentData,
      [name]: value
    });
  }


  const handelHired = () => {
    const payload = commentData;

    if (!candidateId || !interviewId) {
      console.error('Candidate ID or Interview ID is undefined.');
      return;
    }

    dispatch(commentCreateHired({ candidateId, interviewId, payload }))
      .then((res) => {

        handelCloseModel('Hired');
        dispatch(getRecordedInterview(id));
        return sweetAlert('success', 'Success!', 'Candidate Hired successfully!');

      })
      .catch((error) => {
        console.error('Error:', error);
        return sweetAlert('error', 'Error!', 'Something went wrong!');
      });

  }



  return (
    <>
      <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray recordedinterview-wrap custom-offcanvas" style={{ width: "82%" }}>



        <Container fluid className="detailpage-wrap pad-0">
          <Offcanvas.Header className="model-header actionlist-header bg-white">
            <Offcanvas.Title><span className="svg mr-10">{Video}</span>Live Interview Details</Offcanvas.Title>
            <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div>
          </Offcanvas.Header>
          {loading && <Spinner />}
          <Col md={12} className="pad-0 recordedinterview-body">
            <Row className="row-pad-0">
              <Col md={8} className="">
                <Row className="row-pad-0 detail-interviewer bg-white">
                  <div className="flex-space-between">
                    <div className="d-flex">
                      <h5 className="name">
                        <label className="sub-heading ">Candidate Details</label> <small>/  {recordedInterview?.job_title}</small>
                      </h5>
                      {recordedInterview?.interview_stage && (
                        <CustomButton

                          className={`status ${recordedInterview?.interview_stage?.stage_name && recordedInterview.interview_stage.stage_name.replace(/\s+/g, '-').toLowerCase()}`}
                          text={recordedInterview.interview_stage.stage_name}
                        />
                      )}
                    </div>
                    <div>
                      <CustomRating value={recordedInterview.average_rating} edit={false} />
                    </div>
                  </div>
                  <Row className="row-pad-0 custom-row-pad-0">
                    <Col md={1} className="custom-md-1 pad-0 mt-10">
                      <div className="profile mr-15">
                        <img src={aryaimg} alt="user profile" />
                      </div>
                    </Col>
                    <Col md={11} className="content pad-0 custom-md-11 mt-10">
                      <div className="flex-space-between">
                        <div className="d-flex">
                          <h5 className="name">
                            <span className="min-heading">{candidate?.first_name} {candidate?.last_name}</span>
                          </h5>
                        </div>
                      </div>
                      <div className="flex-Xcenter">
                        {candidate?.email_address && (
                          <div className="list-title">
                            <label className="label-view">Email:</label>
                            <span className="view-text ">{candidate.email_address}</span>
                          </div>
                        )}



                        {candidate?.address && (
                          <div className="list-title">
                            <label>Address:</label>
                            <span className="view-text ">{candidate.address}</span>
                          </div>
                        )}

                        {candidate?.phone && (
                          <div className="list-title">
                            <label className="label-view">Phone Number:</label>
                            <span className="view-text ">{candidate.phone}</span>
                          </div>
                        )}

                      </div>



                    </Col>
                  </Row>
                </Row>
                <Row className="row-pad-0  pad-20  interviewer-responses bg-white mt-20 ">
                  <h6 className='pad-0 mt-10  min-sub-heading'> <label>Meeting Recording</label></h6>
                  <div className={`mt-20 Response-wrap  ${recording ? "live-intervew-video " : "  "}  pad-10 `}>


                    {loading ? (
                      <div >Loading video...</div>
                    ) : recording ? (
                      <video width="100%" height="100%" controls key={recording}>
                        <source src={recording} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : recordedInterview?.interview_stage?.stage_name && recordedInterview?.interview_stage?.stage_name.includes("Invited") ? (
                      <div className="pad-20" >No video available.</div>) : (
                      <div className="mb-30 pad-20">Hang tight! Your video is being uploaded and it will appear here in a while.</div>


                    )}

                  </div>

                  <LiveInternalComment selectedAnswer={recordedInterview} setUpdateList={setUpdateList} />
                </Row>
              </Col>
              <Col md={4}>
                <Row className="row-pad-0 timeline-wrap">
                  {(InterviewUnderway || Invited) ? <div></div> : < div className="d-flex mt-10">
                    <CriclrDiv icon={share} onclick={() => handelModel("Evaluation")} tooltiptext="Share Candidate’s Live Interview for Evaluation" clrName="timeline-criclr border-header cursor" />
                    <CriclrDiv icon={downloadarrowblow} clrName="timeline-criclr border-header cursor" onclick={() => handelModel("download")} tooltiptext="Download" />
                    <CriclrDiv icon={HiredICon} onclick={() => handelModel("Hired")} tooltiptext="Hired" clrName="timeline-criclr border-header cursor" />
                  </div>}


                  <TimeLineLive id={id} />

                </Row>
              </Col>
            </Row>

            <Row className="row-pad-0 rating-tabel-wrap pad-10 ">
              {/* <RatingsTabelLiveInterview id={id} /> */}
              <RatingsTabel id={id} livetabel="livetabel" />
            </Row>


          </Col>
        </Container >

        {showHiredCandidate && <CommentModel icon={HiredICon} headertext="Marks Candidate as Hired" handelComment={handelComment} handelSubmit={handelHired} handleClose={() => handelCloseModel("Hired")} />
        }
        {showAlert && <CustomAlert handleClose={() => handelCloseModel("alert")} />}
        {showShareCandidate && <ShareCandidateLiveInterview interviewID={interviewId} handleClose={() => handelCloseModel("Evaluation")} />}
      </Offcanvas >




    </>



  )
}

export default Liveterview;