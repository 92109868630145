import React from 'react';
import HeaderListing from '../components/common/HeaderListing';
import MeetingList from '../components/meetingRequests/MeetingList';
import { Row, Col } from 'react-bootstrap';

const MeetingRequest = () => {
    return (
        <Row className="row-pad-0">
            <HeaderListing headertext="Meeting Requests" />
            <Col md={12} className="pad-0 mt-10 bg-white border-radius-5 ">
                <MeetingList />
            </Col>
        </Row>

    );
}

export default MeetingRequest;