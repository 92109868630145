
import { Form } from "react-bootstrap";

const InputField = (props) => {


    return (
        <Form.Control   {...props} />
    )
}

export default InputField;