import React, { useState, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { Row, Tab, Nav, Offcanvas, Col, Container } from 'react-bootstrap';
import { crossicon, star, ringcopy, videobox, mcqQuestion, textquestion, uploadfile, thingtime, retake, responsestime, } from "../../helper/Icons/Icon";
import { Checkbox } from "../form";




import { CustomRating } from "../form/index";
import { CustomButton } from "../form/index";
import { getFinalRemarks } from "../../store/actions/interviewActions";

import Spinner from "../common/Spinner";
import FileUploadViewInternal from "../common/FileUploadViewInternal"
import InternalComment from '../common/InternalComment';
const config = require('../../config/config.json');
const icons = { 'Video Question': videobox, 'Audio': ringcopy, 'Multiple Choice': mcqQuestion, 'Written': textquestion, 'File Upload': uploadfile };
const text = { 1: "Video Question", 2: "Audio Question", 3: "MCQ Question", 4: "Text Question", 5: "File Upload Question" }

const IndividualEvaluation = ({ handleClose, evaluatorId }) => {
  const [answerId, setAnswerId] = useState(0);
  const checkbox = <span>✔️</span>;
  const crossbox = <span>❌</span>;
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const { finalRemarks, loading, error } = useSelector((state) => state.interview);
  const dispatch = useDispatch();

  const responses = useMemo(() => {
    return finalRemarks?.question_answer ?? [];
  }, [finalRemarks]);


  useEffect(() => {
    if (evaluatorId) {
      dispatch(getFinalRemarks(evaluatorId)).then((res) => {

      })
    }
  }, [dispatch, evaluatorId]);



  const questions = useMemo(() => {
    return responses ?? [];
  }, [responses]);

  useEffect(() => {
    if (answerId !== null && questions.length > 0) {
      const currentQuestion = questions[answerId];
      if (currentQuestion?.answers?.length > 0) {
        setSelectedAnswer(currentQuestion.answers[0]);
      } else {
        setSelectedAnswer(null);
      }
      setSelectedAnswer(currentQuestion);
    }
  }, [answerId, questions, selectedAnswer]);


  const handleTabSelect = (index) => {
    setAnswerId(index);
  };
  const handleOptionChange = (value) => {

    setSelectedAnswer(prev => ({ ...prev, answer: value }));
  };
  return (
    <>
      <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray recordedinterview-wrap custom-offcanvas" style={{ width: "65%" }}>


        {loading && <Spinner />}
        <Container fluid className="detailpage-wrap pad-0">


          <Offcanvas.Header className="model-header bg-white ">
            <Offcanvas.Title>
              <span className="model-header-svg">{star}Individual Evaluation</span></Offcanvas.Title>
            <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div>
          </Offcanvas.Header>

          <Col md={12} className="recordedinterview-body pad-10">



            <Row className="row-pad-0  interviewer-responses bg-white mt-20 ">
              {responses &&
                <Row className="row-pad-0 bg-white border-radius-5">

                  <h6 className='pad-0 mt-10 sub-heading '><label>Candidate’s Responses</label> </h6>
                  <Row className='row-pad-0 pad-0'>
                    <Tab.Container id="left-tabs-example" className="" >
                      <div className='responses-wrap pad-0 mt-10'>
                        <Nav variant="pills" className="responses-wrap-nav">
                          {questions && questions.map((answer, index) => (
                            <Nav.Item key={index + 1}>
                              <Nav.Link className={`box cursor ${index === answerId ? 'active' : ''}`} onClick={() => handleTabSelect(index)}>
                                <span>{icons[answer.answer_media]}</span>
                                <span className='question-nav-link '>Question {index + 1}-{text[answer.question_type_id]}</span>
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                        </Nav>
                        <div className='Response-left-side'>
                          <Row className='row-pad-0'>
                            {selectedAnswer && (
                              <>
                                <div className='Response-div question sm-Response-div row-pad-0 '>
                                  <p>{selectedAnswer.question_text}</p>
                                </div>


                                <div className='Response-div sm-Response-div thingtime-wrap d-flex mt-20 row-pad-0 pad-10'>
                                  <div className='thingtime'>
                                    <small>{thingtime}Think Time:</small>

                                    <span>
                                      <small className={selectedAnswer?.answer_think_time > selectedAnswer?.answer_think_time ? "resgreater" : ""} >
                                        {selectedAnswer?.answer_think_time && selectedAnswer?.answer_think_time}
                                      </small>/
                                      <small>{selectedAnswer.think_time && selectedAnswer.think_time} Mins</small>

                                    </span>

                                  </div>
                                  {(selectedAnswer.question_type_id === 1 || selectedAnswer.question_type_id === 2) &&
                                    <>
                                      <div className='responestime'>
                                        <small>{responsestime}Response Time:</small><span><small className={selectedAnswer?.answer_response_time > selectedAnswer?.response_time ? "resgreater" : ""}>{selectedAnswer?.answer_response_time}</small>/<small>{selectedAnswer?.response_time}</small> Mins</span>
                                      </div>
                                      <div className='retakes'>
                                        <small>{retake} Retakes:</small>
                                        <span>
                                          {selectedAnswer?.answer_retake ?? 0}/{selectedAnswer.retakes}
                                        </span>
                                      </div>
                                    </>
                                  }
                                </div>


                                {selectedAnswer.question_type_id === 1 && (

                                  <div className='mt-20 Response-wrap response-wrap-answer-div pad-10'>
                                    <video
                                      width="100%" height="100%"
                                      controls
                                      src={`${selectedAnswer?.answer}`}
                                    />

                                  </div>

                                )}

                                {selectedAnswer.question_type_id === 2 && (

                                  <div className='mt-20 Response-wrap response-wrap-answer-div  audio-center pad-10'>
                                    <audio
                                      width="100%"
                                      height="100%"
                                      controls
                                      src={`${selectedAnswer?.answer}`}
                                    />

                                  </div>

                                )}

                                {selectedAnswer.question_type_id === 3 && (

                                  <div className='Response-wrap response-wrap-answer-div option-overflow-wrap-answer  pad-10 mt-20'>
                                    {selectedAnswer?.question_options.map((option, index) => (
                                      <>
                                        <div className='Response-MCQResponse' key={index} style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'space-between'
                                        }}>
                                          <Checkbox
                                            label={option.value}
                                            type="radio"
                                            id={`option${index + 1}`}
                                            name="options"
                                            onClick={() => handleOptionChange(option.value)}
                                            checked={option.value === selectedAnswer.answer}
                                          />

                                          {option.is_correct === 1 ? (
                                            option.value === selectedAnswer.answer ? checkbox : checkbox
                                          ) : (
                                            option.value === selectedAnswer.answer ? crossbox : null
                                          )}
                                        </div>
                                      </>
                                    ))}
                                  </div>

                                )}

                                {selectedAnswer.question_type_id === 4 && (

                                  <div className='Response-wrap response-wrap-answer-div mt-20 pad-10'>
                                    <p className='text-p' dangerouslySetInnerHTML={{ __html: selectedAnswer?.answer }} />
                                  </div>

                                )}

                                {selectedAnswer.question_type_id === 5 && (


                                  <div className='Response-wrap response-wrap-answer-div pad-10 mt-20'>
                                    <FileUploadViewInternal document={`${selectedAnswer?.answer}`} />
                                  </div>

                                )}

                                <InternalComment selectedAnswer={selectedAnswer} />
                              </>
                            )}
                          </Row>
                        </div>
                      </div>
                    </Tab.Container>
                  </Row>
                </Row >
              }
            </Row>

            <Row className="row-pad-0  bg-white FinalRatingView mt-20 mb-20 ">
              <h6 className="mt-10 sub-heading"><lable>Final Rating & Remarks</lable></h6>
              <h5 className="mt-20 bold"><b>Rating</b></h5>
              <p>{finalRemarks?.rating && <CustomRating value={finalRemarks?.rating} edit={false} />}</p>

              <h5 className="bold mt-10"><b>Final Remarks</b></h5>
              <Col md={12} className="">
                <div>
                  <p>{finalRemarks?.rating_remarks}</p>
                </div>
              </Col>
            </Row>


          </Col>
        </Container>
      </Offcanvas>



    </>



  )
}

export default IndividualEvaluation;