import HeaderListing from "../components/common/HeaderListing";
import JobList from "../components/job/JobList";
import { Row, Col } from "react-bootstrap";


const Job = () => {
    return (
        <>
            <Row className="row-pad-0">
                <HeaderListing headertext="View All Job" />
                <Col md={12} className="pad-0 mt-10 bg-white border-radius-5 ">
                    <JobList />
                </Col>
            </Row>


        </>

    )

}
export default Job;