import { Row, Col } from "react-bootstrap";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { getRoles } from "../store/actions/userActions";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

const SettingLayout = () => {
    const dispatch = useDispatch();
    const { rolesusers, loading, error } = useSelector((state) => state.user);
    const location = useLocation(); // Get the current location

    useEffect(() => {
        dispatch(getRoles());
    }, [dispatch]);

    return (
        <>
            <Row className="row-pad-0">
                <Col md={12}>
                    <Row className="row-pad-0 bg-white radius-5 mb-20 pad-10">
                        <Col md={2} className="template-wrap-nav">
                            <NavLink to="users">Users</NavLink>


                            {rolesusers !== "Standard" && (
                                <NavLink
                                    to="billing/plan-overview"
                                    className={location.pathname.includes("billing") ? "active" : ""}
                                >
                                    Billing
                                </NavLink>
                            )}

                            {/* {rolesusers !== "Standard" && (
                                <NavLink to="integration">Integrations</NavLink>
                            )} */}

                        </Col>
                    </Row>
                    <Row className="listing-section wrap-accounting-setting row-pad-0 radius-5 bg-white">
                        <Outlet />
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default SettingLayout;
