import { createSlice } from '@reduxjs/toolkit'
import { update} from '../actions/meetingSchedularAction'


const initialState = {
    loading: false,

    meetingSchedular:{},
   
    error: null,
}

const meetingSchedularSlice = createSlice({
    name: 'meetingSchedular',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(update.pending, (state, action) => {
                state.loading = true
            })
            .addCase(update.fulfilled, (state, action) => {
                state.loading = false
             state.meetingSchedular=action.payload
            })
            .addCase(update.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
          
    },
})

export default meetingSchedularSlice.reducer