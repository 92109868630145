import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const CustomButton = (props) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        if (props.Tooltip) {
            setShowTooltip(true);
        }
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    const renderTooltip = (tooltipText) => (
        <Tooltip id="button-tooltip">{tooltipText}</Tooltip>
    );

    if (props.Tooltip) {
        return (
            <OverlayTrigger
                placement={props.dir ? props.dir : "top"}
                overlay={renderTooltip(props.Tooltip)}
                show={showTooltip}
            >
                <Button
                    {...props}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <span >{props.text ?? props.icon}</span>
                </Button>
            </OverlayTrigger>
        );
    }

    if (props.icon && props.text) {
        return (
            <Button {...props}>
                <span className='custom-btn-svg'>{props.icon}</span>
                <span>{props.text}</span>
            </Button>
        );
    } else {
        return (
            <Button {...props}>
                <span className=''>{props.text ?? props.icon}</span>
            </Button>
        );
    }
};

export default CustomButton;
