

const SystemCheckbox = ({ icon, clrName, text, onclick }) => {

    return (<>

        <div className={`system-card ${clrName}`}>
            <div className="syetem-icon">
                {icon}

            </div>
            <div className="system-msg">
                <label className=" min-heading-clr min-heading">{text}</label>
                {clrName == "system-card-erro" ? (<small className="system-status">Status:<span className="success error">Failed</span><a href="#" onClick={onclick} className="ml-10">Troubleshoot</a></small>) : (<small className="system-status">Status:<span className="success error">Success</span></small>)}
            </div>

        </div>

    </>)
}
export default SystemCheckbox;