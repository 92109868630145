import { createSlice } from '@reduxjs/toolkit'
import { create, getAll, profileActivityLog, getModuleActivities, getById } from '../actions/activityLogsActions'

const initialState = {
    loading: false,
    activityLogs: [],
    activityLogTypes: [],
    activityDetail: null,
    profileLogs: [],
    error: null,
}

const activityLogsSlice = createSlice({
    name: 'activity_logs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state, action) => {
                state.loading = true
            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading = false
                //state.candidates.push(action.payload)
            })
            .addCase(create.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getAll.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.loading = false
                state.activityLogs = action.payload
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getModuleActivities.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getModuleActivities.fulfilled, (state, action) => {
                state.loading = false
                state.activityLogs = action.payload
            })
            .addCase(getModuleActivities.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getById.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getById.fulfilled, (state, action) => {
                state.loading = false
                state.activityDetail = action.payload
            })
            .addCase(getById.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(profileActivityLog.pending, (state, action) => {
                state.loading = true
            })
            .addCase(profileActivityLog.fulfilled, (state, action) => {
                state.loading = false
                state.profileLogs = action.payload
            })
            .addCase(profileActivityLog.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
    },
})

export default activityLogsSlice.reducer