import React from 'react';
import StarRating from 'react-rating-stars-component';

const CustomRating = ({ handleRatingChange, value = 0, edit = true }) => {

  const numericValue = parseFloat(value);
  const ratingValue = isNaN(numericValue) || numericValue === null ? 0 : numericValue;



  return (
    <StarRating
      key={ratingValue}
      value={ratingValue}
      count={5}
      edit={edit}
      size={20}
      emptyIcon={<i className="far fa-star" aria-hidden="true"></i>}
      halfIcon={<i className="fa fa-star-half-alt" aria-hidden="true"></i>}
      fullIcon={<i className="fa fa-star" aria-hidden="true"></i>}
      onChange={handleRatingChange}
      activeColor="#FFD700"
      isHalf={true}
      aria-label={`Rating: ${ratingValue} out of 5 stars`}
    />
  );
};

export default CustomRating;
