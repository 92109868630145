import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { getAll } from '../../store/slices/stateSlice';
import { Label } from '../form';

const States = ({ stateName, state, onChange, Norequired }) => {

    const dispatch = useDispatch();
    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const StatesOptions = useSelector(state => state.state.states);

    useEffect(() => {
        if (stateName) {
            dispatch(getAll(stateName));
        }

    }, [stateName]);

    const handleChange = (selectedOption) => {
        if (selectedOption) {

            onChange('state_name', selectedOption.value);
        } else {
            onChange('state_name', '');
        }
    };
    if (!StatesOptions || !Array.isArray(StatesOptions)) {
        return (
            <>
                <Label text="State" htmlFor="state_name" />
                <Form.Select name='state_name'>
                    <option value="">Something went wrong. Check your internet connection.</option>
                </Form.Select>
            </>
        );
    }
    const options = StatesOptions.map(option => ({
        value: option.name,
        label: option.name,
    }));

    const selectedOption = state ? options.find(option => option.value === state) : null;
    return (

        <>


            <Label text="State" className="label-input" HtmlFor="state_name" required={Norequired ? "" : "required"} />
            <Select
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                options={options}
                onChange={handleChange}
                value={selectedOption}
                placeholder={"Select State"}
            />
        </>
    );
};

export default States;