import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Row, Col } from 'react-bootstrap';
import { CustomButton, InputField, Label } from '../form';
import Swal from 'sweetalert2';
import { sendSms, verifycode } from '../../store/actions/userActions';
import { useDispatch } from 'react-redux';

const SigninSecurity = ({ security, setReload }) => {
  const [isAccordionOpen, setAccordionOpen] = useState(true);
  const [showChecked, setChecked] = useState(security.data?.security === 0 ? false : true);

  const [isVerificationSent, setVerificationSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const dispatch = useDispatch(); // Add the dispatch hook

  const toggleAccordion = () => {
    setAccordionOpen(prevState => !prevState);
  };

  const handleSwitch = (e) => {
    const { checked } = e.target;
    setChecked(checked);
    if (checked) {
      setVerificationSent(false); // Reset verification status when switch is turned on
    }
  };

  const handleSendVerification = () => {
    const phonePattern = /^\+?[0-9]+$/;
    if (phoneNumber === '') {
      setPhoneError('Phone number is required');
      return;
    }
    if (!phonePattern.test(phoneNumber)) {
      setPhoneError('Phone number must contain only numbers');
      return;
    }
    setPhoneError(''); // Clear error if validation passes

    dispatch(sendSms(phoneNumber))
      .then((result) => {

        if (result.type === "user/sendSms/fulfilled") {
          setReload(!isVerificationSent)
          setVerificationSent(true);
          Swal.fire({
            icon: 'success',
            title: 'Verification Sent',
            text: 'Verification code has been sent to your phone.',
            showConfirmButton: false,
            timer: 2000
          });
        }
        if (result.type === "user/sendSms/rejected") {
          Swal.fire({
            showConfirmButton: false,
            showCloseButton: true,
            icon: 'error',
            timer: 3000,
            title: 'Failed to Send SMS',
            text: result.payload.detail || 'Failed to send verification SMS. Please try again.',
          });
        }

      })
      .catch((error) => {
        console.error('SMS Sending Error:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while sending the verification code.',
        });
      });
  };

  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;
    const phonePattern = /^\+?[0-9]*$/;

    if (phonePattern.test(value)) {
      setPhoneNumber(value);
      setPhoneError('');
    } else {
      setPhoneError('Phone number must contain only numbers');
    }
  };

  const handleVerificationCodeChange = (e) => {
    setVerificationCode(e.target.value);

  };
  const handleVerifyCode = () => {
    const payload = {
      phone_number: phoneNumber,
      code: verificationCode,
    };

    dispatch(verifycode(payload))
      .then((result) => {

        if (result.type === "user/verifycode/fulfilled") {
          Swal.fire({
            icon: 'success',
            title: 'Verification Successful',
            text: 'Your verification code has been validated.',
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (result.type === "user/verifycode/rejected") {
          Swal.fire({
            showConfirmButton: false,
            showCloseButton: true,
            icon: 'error',
            timer: 3000,
            title: 'Verification Failed',
            text: result.payload.detail || result.payload.error
          });
        }
      })
      .catch((error) => {
        console.error('Verification Error:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while verifying the code.',
        });
      });
  };

  return (
    <>
      <Accordion className='pad-0 bg-white border-radius-5'>
        <Row className='pad-5 mt-1 mb-1 bg-white  border-radius-5 row-pad-0'>
          <Col md={6} className='d-flex justify-content-start align-items-center'>
            <h5 className='sub-heading mt-2'>Sign in & Security</h5>
          </Col>
          <Col md={6} className="d-flex justify-content-end align-items-center">
            <a href="#" className='ml-10 mt-1 mr-10' onClick={(e) => { e.preventDefault(); toggleAccordion(); }}>
              <i className={`fa ${isAccordionOpen ? 'fa-angle-up' : ' fa-angle-down'} clr-light-black`} style={{ fontSize: "25px" }}></i>
            </a>
          </Col>
        </Row>
        <span className='single-border d-block'></span>

        <Accordion.Collapse in={isAccordionOpen}>
          <Row className='row-pad-0 mt-10 pb-3'>
            <Col md={12}>
              <Row className='row-pad-0'>
                <Col md={12}>
                  <p>Add your active mobile number for security purposes, such as sending you SMS to recover your account if you forget your password.</p>
                </Col>
                <Col md={12}>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="security"
                      checked={showChecked}
                      onChange={handleSwitch}
                    />
                    <span className="slider round"></span>
                  </label>
                </Col>

                <Col md={12} className='mt-10'>
                  {showChecked && (
                    <>
                      {!isVerificationSent && (<>
                        <Label text="Phone " className="label-input" />
                        <div className='d-flex'>
                          <InputField
                            type="text"
                            name="number"
                            placeholder="Enter number"
                            style={{ width: "60%" }}
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                          />

                          <CustomButton
                            text="Send Verification Code"
                            type="button"
                            className="custom-btn-primary mr-10 ml-10"
                            onClick={handleSendVerification}
                          />


                        </div>
                        {phoneError && <span style={{ color: 'red', }}>{phoneError}</span>}
                      </>)}
                      {isVerificationSent && (
                        <p className='mt-1'>Please enter the 5-digit code sent via SMS below and press Activate.</p>
                      )}

                    </>
                  )}
                </Col>

                <Col md={12} className='mt-1'>
                  {isVerificationSent && (
                    <>
                      <Label text="Verification Code " />
                      <div className='d-flex'>
                        <InputField
                          type="text"
                          name="code"
                          placeholder="Enter code"
                          style={{ width: "60%" }}
                          value={verificationCode}
                          onChange={handleVerificationCodeChange}
                        />
                        <CustomButton
                          text="Activate"
                          type="button"
                          disabled={verificationCode.length !== 5}
                          className="custom-btn-primary mr-10 ml-10"
                          onClick={handleVerifyCode}
                        />
                      </div>
                      {verificationCode.length > 5 && <span style={{ color: 'red', }}>Please enter the 5-digit code</span>}

                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Accordion.Collapse>
      </Accordion>
    </>
  );
}

export default SigninSecurity;