import { Button } from "react-bootstrap";

const Statusbtn=({ className,text})=>{
return(
<>
<Button className={ className}>{text}</Button>

</>)

}

export default Statusbtn;