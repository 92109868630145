import React, { useState, useEffect } from "react";
import { Table, Row, Form } from "react-bootstrap";
import { checkbox, crossbox, } from "../../helper/Icons/Icon";
import ScheduleMeeting from "../common/ScheduleMeeting";
import ScheduleInterviews from "../common/ScheduleInterview";
import Spinner from "../common/Spinner";
import * as moment from 'moment'
import { getAll, deleteRequest } from '../../store/actions/meetingRequestActions'
import { useDispatch, useSelector } from 'react-redux';
import Paginate from "../common/Paginate";

import Tabelcolumn from "../common/Tabelcolumn";
import { getByName } from "../../store/actions/userPrefrenceActions";
import ReactDatePicker from "react-datepicker";
import Swal from 'sweetalert2';
const defaultCurrentPage = 1;
const defaultPerPage = 25

const headers = [
    { key: 'first_name', title: 'Full Name' },
    // { key: 'last_name', title: 'Last Name' },
    { key: 'job_name', title: 'Job Name' },
    { key: 'email_address', title: 'Email Address' },
    { key: 'start', title: 'Start Time' },
    { key: 'end', title: 'End Time' }
];

const MeetingList = () => {
    const dispatch = useDispatch();
    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);
    const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
    const [perPage, setPerPage] = useState(userPrefrenceDetail?.preference_value?.record_per_page ?? defaultPerPage);
    const [meetingDetail, setMeetingDetail] = useState()
    const [searchField, setSearchField] = useState({});
    const [showScheduleMeeting, setScheduleMeeting] = useState(false)
    const [sortConfig, setSortConfig] = useState({});
    const [deleteFlag, setDeleteFlag] = useState(false);
    const prefrenceName = "users";

    const { meetingRequests, loading, error } = useSelector((state) => state.meetingRequest);
    let meetingRequestList = meetingRequests.data;
    let pages = meetingRequests.pages;
    let totalPages = meetingRequests.total_page;
    let totalRecords = meetingRequests.total_record;


    useEffect(() => {
        dispatch(getByName(prefrenceName));
    }, [dispatch])


    useEffect(() => {
        if (perPage) {
            let data = {
                'limit': perPage, 'page': currentPage, search: searchField, order_by: sortConfig?.key, sort_by: sortConfig?.order
            }
            dispatch(getAll(data)).then((res) => {

            })
        }

    }, [dispatch, currentPage, perPage, searchField, sortConfig, deleteFlag]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
    };
    const handleDateChange = (date, key) => {
        const formattedDate = date ? moment(date).format('MM-DD-YYYY') : "";
        setCurrentPage(1)
        setSearchField((prevState) => {
            const newSearchField = { ...prevState };

            if (formattedDate) {

                newSearchField[key] = formattedDate;
            } else {

                delete newSearchField[key];
            }

            return newSearchField;
        });
    };

    const handleFieldSearch = (e) => {
        const { name, value } = e.target;

        setCurrentPage(1)
        if (value === "") {
            const newSearchField = { ...searchField };
            delete newSearchField[name];
            setSearchField(newSearchField);
        } else {

            setSearchField((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const toggleSortOrder = (key) => {
        setSortConfig(prevConfig => {
            if (prevConfig.key === key) {
                return { key, order: prevConfig.order === 'asc' ? 'desc' : 'asc' };
            } else {
                return { key, order: 'asc' };
            }
        });
    };
    const meetingRequestSend = (props) => {
        setMeetingDetail(props)
        setScheduleMeeting(true)
    }
    const meetingRequestClose = () => {
        setScheduleMeeting(false)
        setDeleteFlag(!deleteFlag)
    }
    const handleReject = (requestId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure you want to reject this meeting request?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, reject it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteRequest(requestId))
                    .then((res) => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Rejected',
                            text: 'The meeting request has been rejected.',
                            showConfirmButton: false,
                            timer: 1000
                        });
                        setDeleteFlag(!deleteFlag);
                    });
            }
        });
    };


    return (
        <>
            {loading &&
                <Spinner />
            }

            <Row className="listing-section row-pad-0" >

                <div className="oveflow-meetingList-auto">

                    <Table className="tabel" >

                        <thead>
                            <tr>
                                {/* <th style={{ width: "20px" }} className="th-icon"><i className="fa fa-ellipsis-v" title="Quick Action"></i></th> */}

                                <th style={{ minWidth: "140px", width: "160px" }} className="th-text">Accept or Reject Request</th>
                                {headers &&
                                    headers.map((header, index) => {
                                        const fieldValue = searchField[header.key] || '';
                                        const isSortedColumn = sortConfig.key === header.key;

                                        const isDateField = header.key.toLowerCase().includes("start") || header.key.toLowerCase().includes("end");
                                        return (
                                            <th key={index} className="sorting" style={{ minWidth: "100px", width: "200px" }}>
                                                <span onClick={() => toggleSortOrder(header.key)}>
                                                    {isSortedColumn ? (sortConfig.order === 'asc' ? <i class="fa fa-sort-amount-asc" aria-hidden="true"></i> : <i class="fa fa-sort-amount-desc" aria-hidden="true"></i>) : <i class="fa fa-exchange" aria-hidden="true"></i>}
                                                </span>
                                                {isDateField ? (
                                                    <ReactDatePicker
                                                        className="form-control rating-dropdown custom-datepicker"
                                                        selected={fieldValue}
                                                        onChange={(date) => handleDateChange(date, header.key)}
                                                        placeholderText={header.title}
                                                        dateFormat="MM-dd-yyyy"
                                                        isClearable={!!fieldValue}
                                                        showPopperArrow={true}
                                                        popperPlacement="bottom"
                                                    />
                                                ) : (
                                                    <Form.Control
                                                        name={header.key}
                                                        type="text"
                                                        value={fieldValue}
                                                        placeholder={header.title}
                                                        onChange={handleFieldSearch}
                                                    />
                                                )}
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody >
                            {meetingRequestList && meetingRequestList.length > 0 ? (
                                meetingRequestList.map((meetingRequest, index) => (
                                    <tr key={index}>
                                        {/* <td className="cursor td-icon"><i className="fa fa-ellipsis-v" title="Quick Action"></i></td> */}
                                        <td className="td-icon">
                                            <div className="quick-action">
                                                <Tabelcolumn span="span" onClick={() => meetingRequestSend(meetingRequest)} icon={checkbox} Tooltip="Accept meeting request" />

                                                <Tabelcolumn span="span" onClick={() => handleReject(meetingRequest?.meeting_request_id)} icon={crossbox} Tooltip=" Reject meeting request" />




                                            </div>
                                        </td>
                                        <td className="td-text">{meetingRequest.first_name + "  " + meetingRequest.last_name}</td>

                                        <td className="td-text">{meetingRequest.job_name}</td>
                                        <td className="td-text">{meetingRequest.email_address}</td>
                                        <td className="td-text">{moment(meetingRequest.start).format('MM-DD-YYYY h:mm A')}</td>
                                        <td className="td-text">{moment(meetingRequest.end).format('MM-DD-YYYY h:mm A')}</td>
                                    </tr>

                                ))) : (
                                <tr>
                                    <td colSpan={10} className="text-center">No records found.</td>
                                </tr>
                            )



                            }
                        </tbody>
                    </Table>

                    <Paginate
                        pages={pages}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        perPage={perPage}
                        totalRecords={totalRecords}
                        handlePageChange={handlePageChange}
                        handlePerPage={handlePerPage}
                    />

                </div>
            </Row>


            {showScheduleMeeting && <ScheduleMeeting handleClose={meetingRequestClose} meetingData={meetingDetail} />}
        </>
    );
};

export default MeetingList;