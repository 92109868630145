import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';

import { getAll } from '../../store/actions/companyActions';
import { Label } from '../form';

const Companies = ({ companyId, onChange, companyGet }) => {
    const dispatch = useDispatch();
    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const allGetCompanies = useSelector(state => state.company.companies.data);

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    useEffect(() => {
        if (companyGet) {


            companyGet(allGetCompanies && allGetCompanies[allGetCompanies?.length - 1]?.id)
        }
    }, [allGetCompanies])
    const handleChange = (selectedOption) => {
        if (selectedOption) {
            onChange('company_id', selectedOption.value);
        }
        else {
            onChange('company_id', ' ');
        }
    };

    if (!allGetCompanies || !Array.isArray(allGetCompanies)) {
        return (
            <>
                <Label text="Select Company" HtmlFor="company_id" />
                <Form.Select name='company_id'>
                    <option value="">Something went wrong. Check your internet connection.</option>
                </Form.Select>
            </>
        );
    }

    const options = allGetCompanies.map(option => ({
        value: option.id,
        label: option.company_name
    }));
    const selectedOption = companyId ? options.find(option => option.value === companyId) : null;



    return (
        <>
            <Label text="Company" className="label-input" HtmlFor="company_id" required="required" />
            <Select
                name="company_id"
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                options={options}
                value={selectedOption}
                onChange={handleChange}
                placeholder="Select company name or enter"
            />
        </>
    );
};

export default Companies;
