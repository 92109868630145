export const CompanyLinks = [
    {
        text: "Website ",
        id: "website_url",
        name: "website_url",
        className: "label-input",
        type: "url",
        placeholder: "https://",
        required: true,
    },
    {
        text: "LinkedIn URL",
        id: "linkedin_url",
        name: "linkedin_url",
        className: "label-input",
        type: "url",
        placeholder: "https://",
        required: true,
    },
    {
        text: "Facebook",
        id: "facebook_url",
        name: "facebook_url",
        className: "label-input",
        type: "url",
        placeholder: "https://",
        required: true,
    },
    {
        text: "X ",
        id: "twitter_url",
        name: "twitter_url",
        className: "label-input",
        type: "url",
        placeholder: "https://",
        required: true,
    }
]



