import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const config = require('../../config/config.json');
const API_BASE_URL = config.API_BASE_URL;

const BASE_URL = `${API_BASE_URL}`;

const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

export const getAll = createAsyncThunk(
    'RecordedInterview/Interviewdetail',
    async ({ jobid, candidateId }, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }



        try {
            const { data } = await axios.get(`${BASE_URL}/get-candidate-interviews/${candidateId}/${jobid}`, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)


export const getAllRecordedInterview = createAsyncThunk(
    'RecordedInterview/getAllDetail',
    async (candidateId, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }



        try {
            const { data } = await axios.get(`${BASE_URL}/recorded-interview-candidates/${candidateId}`, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)

export const commentCreateHired = createAsyncThunk(
    'Interview/commentCreateHired',
    async ({ candidateId, interviewId, payload }, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }



        try {
            const { data } = await axios.post(`${BASE_URL}/candidate-hired/${candidateId}/${interviewId}`, payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)

export const commentCreateAccept = createAsyncThunk(
    'RecordedInterview/commentCreateAccept',
    async ({ candidateId, interviewId, payload }, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }



        try {
            const { data } = await axios.post(`${BASE_URL}/candidate-accept/${candidateId}/${interviewId}`, payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)

export const commentCreatecandRejected = createAsyncThunk(
    'RecordedInterview/commentCreatecandRejected',
    async ({ candidateId, interviewId, payload }, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }



        try {
            const { data } = await axios.post(`${BASE_URL}/candidate-rejected/${candidateId}/${interviewId}`, payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)

export const addAnswerRating = createAsyncThunk(
    'RecordedInterview/addAnswerRating',
    async ({ answerId, payload }, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }



        try {
            const { data } = await axios.post(`${BASE_URL}/add-answer-rating/${answerId}`, payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)

export const addLiveAnswerRating = createAsyncThunk(
    'LiveInterview/internalComments',
    async ({ interviewID, payload }, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }



        try {
            const { data } = await axios.post(`${BASE_URL}/add-live-interview-internal-comments/${interviewID}`, payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)
