import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const config = require('../../config/config.json');
const API_BASE_URL = config.API_BASE_URL;


const BASE_URL = `${API_BASE_URL}/companies`;

const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

export const create = createAsyncThunk(
    'company/create',
    async (data, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        let payload = {
            "company_name": data.company_name,
            "industry_id": data.industry_id,
            "company_logo": data.logo,
            "company_banner": data.banner,
            "company_banner_type": data.banner_type ?? "",
            "header_footer_colour_code": data.header_footer_colour,
            "button_colour_code": data.button_colour,
            "button_text_color_code": data.button_text_colour,
            "company_description": data.company_description,
            "city": data.city,
            "state_name": data.state_name,
            "country_name": data.country_name,
            "zip_code": data.zip_code,
            "company_size": data.company_size,
            "website_url": data.website_url,
            "linkedin_url": data.linkedin_url,
            "facebook_url": data.facebook_url,
            "twitter_url": data.twitter_url,
            "is_active": data.is_active ?? 1,
            "culture_images": data.culture_images,
            "landing_page_settings": data.landing_page_settings,
        }

        try {
            const { data } = await axios.post(`${BASE_URL}`, payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)


export const update = createAsyncThunk(
    'company/update',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.put(`${BASE_URL}/${payload.companyId}`, payload.formDataFields, config)
            return data;
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)

// 
export const getAll = createAsyncThunk(
    'company/getAll',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            params: payload
        }

        try {
            const { data } = await axios.get(`${BASE_URL}`, config)
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const getAllPhotos = createAsyncThunk(
    'company/getAllPhotos',
    async ({ id, payload }, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            params: payload
        }
        try {
            const { data } = await axios.get(`${API_BASE_URL}/company-culture/${id}`, config)
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const getById = createAsyncThunk(
    'company/getById',
    async (companyId, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${BASE_URL}/${companyId}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)


export const deleteCompany = createAsyncThunk(
    'company/deleteCompany',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.delete(`${BASE_URL}/${id}`, config);
            return data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const getcompanyPhotos = createAsyncThunk(
    'documents',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${BASE_URL}/${id}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const getCompanyJobs = createAsyncThunk(
    'companyJobs',
    async ({ companyId, payload }, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            params: payload
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL}/company-jobs/${companyId}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)


export const companyhighlights = createAsyncThunk(
    'companyhighlights ',
    async (companyId, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },

        }

        try {
            const { data } = await axios.get(`${API_BASE_URL}/company-highlights/${companyId}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const UplodedFiles = createAsyncThunk(
    'UplodedFiles',
    async (payload, thunkAPI) => {
        const formData = new FormData();

        formData.append('file', payload.file);
        formData.append('module', payload.moduleName);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + userToken
            },

        }


        try {
            const { data } = await axios.post(`${API_BASE_URL}/documents-upload`, formData, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)


export const companyPreview = createAction('companyPreview', (data) => {

    return {
        payload: {
            data
        }
    };
});