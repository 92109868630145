import { uploadfile, joinedUser, shareScreenUser, participant_join, leftUser, acceptcandidate, rejectecandidate, share, downloadarrowblow, emildevilerd, windresize, exitedinterview, Video, ringcopy, textquestion, website, mcqQuestion } from "../../helper/Icons/Icon";
import CriclrDiv from "../common/CriclrDiv";
import { Row, Col } from "react-bootstrap";
import { getTimeLineById } from "../../store/actions/interviewActions";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import moment from "moment-timezone";

const iconData = {
  'email_delivered': emildevilerd,
  'landing_page': website,
  'screen_share': shareScreenUser,
  'left_*': leftUser,
  'joined_*': participant_join,
  'interview_started': joinedUser,
  'exited_interview': exitedinterview
};

const TimeLineLive = ({ id }) => {
  const dispatch = useDispatch();
  const { InterviewTimeLine, loading, error } = useSelector((state) => state.interview);
  const { UsersProfileLoginData } = useSelector((state) => state.user);
  const timeZone = UsersProfileLoginData?.data?.user?.timezone;
  useEffect(() => {
    if (id) {
      dispatch(getTimeLineById(id));
    }
  }, [dispatch, id]);


  const timelineData = InterviewTimeLine?.data || [];
  const checkNotNull = (label) => label !== null;


  const filteredTimeLine = timelineData.filter(checkNotNull);

  function getIcon(actionType) {

    if (iconData[actionType]) {
      return iconData[actionType];
    }


    const wildcardMatch = Object.keys(iconData).find(key => {

      return key.includes('*') && actionType.startsWith(key.replace('*', ''));
    });


    return wildcardMatch ? iconData[wildcardMatch] : participant_join;
  }
  return (
    <div className="timeline-body timeline-body-live">
      <h5 className='min-sub-heading mt-10'><label>Timeline </label></h5>
      <div className="timeline">
        {loading && <p>Loading...</p>}

        <ul>
          {filteredTimeLine.length > 0 ? (
            filteredTimeLine.map((item, index) => (
              <li key={index}>
                <div className="d-flex">
                  <CriclrDiv icon={getIcon(item.key)} clrName="timeline-criclr v-blue-light border-0" />
                  <div className="timeline-body-text">
                    <label>{item.log}</label>
                    <span>{item.key == "email_delivered" ? <>{
                      moment.utc(item?.date_time?.date && item?.date_time?.date)
                        .tz(timeZone)
                        .format("MM-DD-YYYY h:mm A")
                    } </> : moment.utc(item?.date_time && item?.date_time)
                      .tz(timeZone)
                      .format("MM-DD-YYYY h:mm A")}</span></div>
                </div>
              </li>
            ))
          ) : (
            <p>No timeline data available</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TimeLineLive;

