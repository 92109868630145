import { exchange, filter } from "../../helper/Icons/Icon";
import { Row, Col } from "react-bootstrap";
import DoughnutChart from "../graph/DoughnutChart";
import React, { useState } from 'react';
import moment from 'moment';

function Card({ name, dashboard, labelData, handleOpenModal, formData, cardModel, nameapi, Yesterday }) {
  const [expanded, setExpanded] = useState(false);

  const expandCard = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className={`${4} ${expanded ? 'col-md-6' : 'col-md-4'} `}>
        <div className="card-wrap bg-white">
          <Row className="card-heading row-pad-0">
            <div className="widget-h d-flex justify-content-between align-items-center">
              <span>{name}</span>
              <div className="d-flex align-items-cente">
                <span className="filter d-flex" onClick={handleOpenModal}> {filter}</span>
                <span className="expand-card mr-5 ml-5" onClick={() => expandCard()}>{exchange}</span>
              </div>


            </div>
          </Row>
          <div className={`card-body`} >
            <DoughnutChart dashboard={dashboard} labelData={labelData} cardModel={cardModel} nameCard={nameapi} />
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-center align-items-center clr-filter-date mt-10 mb-10">
              {Yesterday && <b>Yesterday</b>}
              <i className="fa fa-calendar"> </i>
              <small>{moment(formData.startDate).format('MM/DD/YYYY')}-</small>
              <i className="fa fa-calendar"></i>
              <small>{moment(formData.endDate).format('MM/DD/YYYY')}</small>
            </div>
          </div>
        </div >
      </div >
    </>
  );
}

export default Card;
