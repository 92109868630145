import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../common/Spinner';
import { Row, Col, Offcanvas, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { crossicon, copyicon, checkbox, addQuestion, deletclrred, browserQuestion } from '../../helper/Icons/Icon';
import { CustomButton, InputField } from '../form';
import { getAll, deleteQuestion } from '../../store/actions/questionActions';
import Swal from 'sweetalert2';
import Paginate from "../common/Paginate";

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 2500
    });
};

const QuestionsLibrary = ({ handleClose, setSelectionQuestion, spanout }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [questionCopyAdd, setQuestionCopyAdd] = useState(null);
    const [questionCopy, setQuestionCopy] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [reload, setReload] = useState(false);

    const dispatch = useDispatch();
    const { questions, loading } = useSelector(state => state.question);
    const questionList = questions.data || [];
    let pages = questions.pages;
    let totalPages = questions.total_pages;
    let totalRecords = questions.total_questions;

    const renderTooltip = (tooltipText) => (
        <Tooltip id="button-tooltip">{tooltipText}</Tooltip>
    )
    useEffect(() => {
        const payload = {
            'search': searchQuery || ' ',
            'limit': perPage, 'page': currentPage,
        };
        dispatch(getAll(payload));
    }, [dispatch, currentPage, perPage, searchQuery, reload]);

    const reloadList = (bol) => {
        setReload(bol)
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setSearchQuery(value);
        setCurrentPage(1)

    };

    useEffect(() => {
        if (questionCopyAdd) {
            handleClose();
        }
    }, [questionCopyAdd, handleClose]);

    const addCopyHandle = (text) => {
        // setSelectionQuestion({ value: text, label: text });
        setSelectionQuestion(text);
        handleClose();
    };

    const copyHandle = (text, id) => {
        navigator.clipboard.writeText(text);
        setQuestionCopy(id);

    };

    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure to delete the selected item(s).",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteQuestion(id))
                    .then((res) => {
                        if (res.payload.error) {
                            sweetAlert("error", "Error!", `${res.payload.error}!`);
                        } else {
                            reloadList(true);
                            sweetAlert("success", "Success!", res.payload.message);
                        }
                    })
                    .catch(error => {
                        sweetAlert("error", "Error!", "Something went wrong!");
                    });
            }

            reloadList(false);
        });
    };

    return (
        <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-light-gray custom-offcanvas" style={{ width: "50%" }}>
            <Offcanvas.Header className="model-header bg-white actionlist-header">
                <Offcanvas.Title>
                    <span className="model-header-svg ">{browserQuestion}Questions Library</span>
                </Offcanvas.Title>
                <div className="text-end">
                    <CustomButton onClick={handleClose} className="text-end close-btn" text={crossicon} />
                </div>
            </Offcanvas.Header>

            {loading && <Spinner />}

            <Container fluid className="pad-10">
                <Col md={12} className='bg-white border-radius-5 pad-10 new-template-wrap'>
                    <Row className='row-pad-0'>
                        <Col md={6}></Col>
                        <Col md={6} className='text-end'>
                            <InputField type="search" onChange={handleChange} placeholder="Search Questions" />
                        </Col>
                        <Col md={12} className='mt-20'>
                            {questionList.length === 0 && !loading && (
                                <div className="text-center">No questions found.</div>
                            )}
                            {questionList.map((question, index) => (
                                <div key={index} className='mb-10'>
                                    <div className='d-flex justify-content-between question-wrap'>
                                        <div className='text'>{question.question_text}</div>
                                        <div className='d-flex adduestion-svg'>
                                            {question?.is_deleteable && (
                                                <span className='mr-10 ml-1 cursor' onClick={() => handleDelete(question.question_id)}>
                                                    {deletclrred}
                                                </span>
                                            )}
                                            {questionCopyAdd === question.question_text ? (
                                                <span className='checkboxclr cursor mr-10 ml-10'>{checkbox}</span>
                                            ) : (
                                                !spanout && (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={renderTooltip("Add Question")}
                                                    >
                                                        <span className='copyicon-clr cursor mr-10 ml-10' onClick={() => addCopyHandle(question.question_text)}>
                                                            {addQuestion}
                                                        </span>
                                                    </OverlayTrigger>
                                                )
                                            )}
                                            <span className='copyicon-clr cursor mr-10 ml-10' onClick={() => copyHandle(question.question_text, question.question_id)}>
                                                {questionCopy === question.question_id ? checkbox :
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={renderTooltip("Copied to clipboard")}
                                                    ><small>{copyicon}</small>
                                                    </OverlayTrigger>
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <span className='single-border mt-1 d-block'></span>
                                </div>
                            ))}
                        </Col>
                        <Paginate
                            pages={pages}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            perPage={perPage}
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            handlePerPage={handlePerPage}
                        />
                    </Row>
                </Col>
            </Container>
        </Offcanvas>
    );
};

export default QuestionsLibrary;
