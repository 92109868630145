import { Offcanvas, Row, Container, Col, Spinner } from "react-bootstrap";
import { Interviews, crossicon, ringcopy, videobox, mcqQuestion, textquestion, uploadfile } from "../../helper/Icons/Icon";
import { CustomButton } from "../form/index";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "../../store/actions/recorderInterviewAction";
import moment from "moment-timezone";

import Statusbtn from "../common/Statusbtn";
import Swal from 'sweetalert2';
import RecordedInterview from "./RecordedInterview";
import Liveterview from "./Liveterview";

const sweetAlert = (type, title, text) => {
  Swal.fire({
    icon: type,
    title: title,
    text: text,
    showConfirmButton: false,
    timer: 3000
  });
};

const icons = {
  'Video Question': videobox,
  'Audio': ringcopy,
  'Multiple Choice': mcqQuestion,
  'Written': textquestion,
  'File Upload': uploadfile,
};

const Interview = (props) => {
  const { handleClose, jobid, candidateId, headertext, icon, headertimeLine } = props;

  const dispatch = useDispatch();
  const [showRecordedInterview, setRecordedInterview] = useState(false);
  const [resError, setResError] = useState();
  const [showLiveterview, setLiveterview] = useState(false);
  const [interviewId, setInterviewID] = useState();
  const { UsersProfileLoginData } = useSelector((state) => state.user);
  const timeZone = UsersProfileLoginData?.data?.user?.timezone;
  const handleheaderModel = (type, id) => {
    if (type === "RecordedInterview") {
      setInterviewID(id);
      setRecordedInterview(!showRecordedInterview);
    } else if (type === "Liveterview") {
      setInterviewID(id);
      setLiveterview(!showLiveterview);
    }
  };

  const handleCloseModel = (type) => {
    setInterviewID(null);
    if (type === "RecordedInterview") {
      setRecordedInterview(false);
    } else if (type === "Liveterview") {
      setLiveterview(false);
    }
  };

  const { recorderInterview, loading, error } = useSelector((state) => state.recorderInterview);

  useEffect(() => {
    dispatch(getAll({ jobid, candidateId })).then((res) => {
      if (res.payload.error) {
        setResError(res.payload.error);
      }
    }).catch(error => {
      sweetAlert("error", "Error!", "Something went wrong!");
    });
  }, [dispatch, jobid, candidateId]); // Include dependencies

  const interviewData = recorderInterview?.data;

  return (
    <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "650px" }}>
      <Container fluid className="row-pad-0 pad-0 interview-model-wrap pad-20">

        {resError ? (
          <div className="link-error-wrap pad-20">
            <div className="link-error">
              <h1>{resError}</h1>
            </div>
          </div>
        ) : (
          <>
            {interviewData && (
              <>

                <Offcanvas.Header className="model-header bg-white actionlist-header">
                  <Offcanvas.Title>
                    {icon && <span className="svg">{icon}</span>} {recorderInterview?.job_name} Interviews of {recorderInterview?.candidate_name}
                  </Offcanvas.Title>
                  <div className="text-end">
                    <CustomButton onClick={handleClose} className="text-end close-btn" text={crossicon} />
                  </div>
                </Offcanvas.Header>
                <Col md={12} className="InviteCandidate-wrap">
                  {loading && <div className="spinner-all-interview"><Spinner /></div>}
                  <Row className="row-pad-0 bg-white m-2 pad-20 border-radius-5">
                    {interviewData.map((label, index) => {

                      const hasStartTime = label?.stage_id == 2 || label?.stage_id == 7;
                      if (hasStartTime) return <>
                        <Col md={12} className="mt-20 border-radius-5" >

                          <div className="interview-box mt-20">
                            {label.interview_type === 1
                              ? "This recorded interview is still in preparation and has not yet been submitted."
                              : "This live interview is currently pending and has not yet been scheduled."}
                          </div>
                        </Col>
                      </>;

                      return label.interview_type === 1 ? (
                        <Col md={12} className="mt-20 border-radius-5" key={index} onClick={() => handleheaderModel("RecordedInterview", label.interview_id)}>
                          <div className="interview-box">
                            <div>{Interviews}</div>
                            <div className="interview-box-detail ml-10">
                              <div className="x-space-between">
                                <div>
                                  <label>Interview Type:</label><span>Recorded</span>
                                </div>
                                <div >
                                  <Statusbtn
                                    className={`${label?.interview_stage ? label.interview_stage.replace(/\s+/g, '-').toLowerCase() : 'default-status'} status`}
                                    text={label?.interview_stage || 'Default Status'}
                                  /> </div>
                              </div>
                              <div className="d-flex mt-10 flex-wrap">
                                {label.questions.map((question, qIndex) => (
                                  <div className="step mb-1" key={qIndex}>
                                    {icons[question?.question_type_name]} {/* Displaying icon dynamically */}
                                    <span>{question.count}</span><span className="ml">{question.question_type_name}</span>
                                  </div>
                                ))}

                              </div>
                              <div className="mt-10 x-space-between">
                                <div>
                                  <label>Submitted On:</label>

                                  <span>{moment.utc(label?.end_time && label?.end_time)
                                    .tz(timeZone)
                                    .format("MM-DD-YYYY h:mm A")}</span>

                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <Col md={12} className="mt-20 border-radius-5" onClick={() => handleheaderModel("Liveterview", label.interview_id)} key={index}>
                          <div className="interview-box">
                            <div>{Interviews}</div>
                            <div className="interview-box-detail ml-10">
                              <div className="x-space-between">
                                <div>
                                  <label>Interview Type:</label><span>Live</span>
                                </div>
                                <div >
                                  <Statusbtn
                                    className={`${label?.interview_stage ? label.interview_stage.replace(/\s+/g, '-').toLowerCase() : 'default-status'} status`}
                                    text={label?.interview_stage || 'Default Status'}
                                  /> </div>

                              </div>
                              <div className="d-flex mt-10">
                                <div className="step d-flex flex-wrap" style={{ flexWrap: "wrap" }}>
                                  <label>Participants:</label>
                                  {label?.participants && label?.participants.map((participant, index) => (
                                    <span key={index} className="ml">{participant},</span>
                                  ))}
                                  {/* <span >{label?.interview_candidate_name}</span> */}
                                </div>

                              </div>
                              <div className="mt-10 x-space-between">
                                <div>
                                  <label>Submitted On:</label>

                                  <span>{moment.utc(label?.end_time && label?.end_time)
                                    .tz(timeZone)
                                    .format("MM-DD-YYYY h:mm A")}</span>

                                </div>
                              </div>

                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </>
            )}
          </>
        )
        }
      </Container >
      {showRecordedInterview && <RecordedInterview id={interviewId} handleClose={() => handleCloseModel("RecordedInterview")} />}
      {showLiveterview && <Liveterview handleClose={() => handleCloseModel("Liveterview")} id={interviewId} />}
    </Offcanvas >
  );
};

export default Interview;
