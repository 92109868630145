import React, { useState, useRef } from "react";

import HeaderListing from "../components/common/HeaderListing";
import { JobActive } from "../helper/Icons/Icon";
import AddCompany from '../components/company/AddCompany';

import DashboardTable from "../components/common/DashboardTable";
import { Row, Col } from "react-bootstrap";
import InvitationsCard from "./InvitationsCard";
import RecordedInterviewsCard from "./RecordedInterviewsCard";
import LiveInterviewsCard from "./LiveInterviewsCard";
import JobAnalyticsCard from "./JobAnalyticsCard";
import { useNavigate } from "react-router-dom";

function Dashboard() {
    const headetext = "Dashboard";
    const scrollRef = useRef(null);
    const textbtn = "Customize Company Branding";
    const [showCompany, setShowCompany] = useState(false);
    const [JobAnalyticsDate, setJobAnalyticsDate] = useState()
    const [LiveInterviewsDate, setLiveInterviewsDate] = useState()
    const [RecordedInterviewsDate, setRecordedInterviewsDate] = useState()
    const [InvitationsDate, setInvitationsDate] = useState()
    const [searchFiled, setSearchField] = useState({})

    const [recordCall, setRecord] = useState(false);
    const [interviewTypeId, setinterviewTypeId] = useState({
        type: null,
        name: '',
        startDate: '',
        endDate: '',
    });

    const navigate = useNavigate();






    const handleCompanyClose = () => {
        setShowCompany(false);

    };

    const scrollToPosition = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo({
                top: 800,
                behavior: 'smooth',
            });
        }
    };






    const HandelCard = (props) => {

        setSearchField({})
        switch (props) {
            case "ActiveJobs":
                navigate('/jobs', { state: { active_jobs: true } });
                break;
            case "Candidates":
                navigate(`/candidates`);
                break;
            case "recordedInterviews":
                setinterviewTypeId({
                    type: 1,
                    name: '',
                    startDate: JobAnalyticsDate.startDate,
                    endDate: JobAnalyticsDate.endDate,
                });
                setRecord(!recordCall);
                scrollToPosition();
                break;
            case "liveInterviews":
                setRecord(!recordCall);
                setinterviewTypeId({
                    type: 2,
                    name: '',
                    startDate: JobAnalyticsDate.startDate,
                    endDate: JobAnalyticsDate.endDate,
                });
                scrollToPosition();
                break;
            case "Hired":
                // navigate('/candidates', { state: { hired_candidate: true } });
                setRecord(!recordCall);
                setinterviewTypeId({
                    type: 2,
                    name: 'Hired',
                    startDate: JobAnalyticsDate.startDate,
                    endDate: JobAnalyticsDate.endDate,
                });
                scrollToPosition();
                break;
            default:
                break;
        }
    };

    const HandelCardChart = (name, label) => {

        setSearchField({})
        if (name === "Interviews") {


            setinterviewTypeId({
                type: 0,
                name: label,
                startDate: InvitationsDate.startDate,
                endDate: InvitationsDate.endDate,
            });
        }
        else if (name === "recordedInterviews") {


            setinterviewTypeId({
                type: 1,
                name: label,
                startDate: RecordedInterviewsDate.startDate,
                endDate: RecordedInterviewsDate.endDate,
            });
        }
        else if (name === "liveInterviews") {


            setinterviewTypeId({
                type: 2,
                name: label,
                startDate: LiveInterviewsDate.startDate,
                endDate: LiveInterviewsDate.endDate,
            });
        }


        setRecord(!recordCall);
        scrollToPosition();
    };
    return (
        <>
            <Row className="row-pad-0">

                <Col md={12} className="y-scroll" ref={scrollRef}>
                    <HeaderListing
                        headertext={headetext}
                        btnicon={JobActive}


                        variant="outline-primary"

                    />
                    <JobAnalyticsCard cardModel={HandelCard} setJobAnalyticsDate={setJobAnalyticsDate} />

                    <Row className="mt-10 d-flex">
                        <InvitationsCard HandelCardChart={HandelCardChart} setJobAnalyticsDate={setInvitationsDate} />


                        <RecordedInterviewsCard HandelCardChart={HandelCardChart} setJobAnalyticsDate={setRecordedInterviewsDate} />
                        <LiveInterviewsCard HandelCardChart={HandelCardChart} setJobAnalyticsDate={setLiveInterviewsDate} />

                    </Row>

                    <DashboardTable update={recordCall} interviewTypeId={interviewTypeId} searchFiledEmptyy={searchFiled} />

                </Col>
                {showCompany && (

                    <AddCompany handleClose={handleCompanyClose} />

                )}
            </Row>
        </>
    );
}

export default Dashboard;
