import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJobs } from '../../store/actions/jobActions';
import Select from 'react-select';

const CopyJob = ({ Changehandle, copyJob }) => {
    const dispatch = useDispatch();
    const { allGetJobs, loading, error } = useSelector((state) => state.job);
    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        dispatch(getJobs());
    }, [dispatch]);

    const handleChange = (selectedOption) => {
        if (selectedOption) {
            Changehandle(selectedOption.value);
        } else {
            Changehandle(null);
        }
    };

    const options = allGetJobs.map((job) => ({
        value: job.id,
        label: job.job_title,
    }));


    const selectedValue = options.find((option) => option.value === copyJob) || null;

    return (
        <>

            <Select
                isDisabled={isDisabled}
                isLoading={loading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                options={options}
                onChange={handleChange}
                placeholder="Select job to copy"
                value={selectedValue}
            />
        </>
    );
};

export default CopyJob;


// import React, { useState, useEffect } from 'react';
// import { Dropdown, Form, Spinner } from 'react-bootstrap';
// import { useDispatch, useSelector } from 'react-redux';
// import { getJobs } from '../../store/actions/jobActions';
// import Select from 'react-select'
// const CopyJob = ({ Changehandle, copyJob }) => {

//     const { allGetJobs, loading, error } = useSelector((state) => state.job);
//     const [isClearable, setIsClearable] = useState(true);
//     const [isSearchable, setIsSearchable] = useState(true);
//     const [isDisabled, setIsDisabled] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);
//     const [query, setQuery] = useState(copyJob ?? '');

//     const [showDropdown, setShowDropdown] = useState(false);
//     const dispatch = useDispatch();
//     useEffect(() => {

//         dispatch(getJobs())

//     }, [dispatch]);
//     const handleChange = (selectedOption) => {

//         if (selectedOption) {

//             Changehandle(selectedOption.value);
//         }
//         else {
//             Changehandle();
//         }
//     };

//     const options = allGetJobs.map(job => ({
//         value: job.id,
//         label: job.job_title
//     }));
//     const handleSelect = (option) => {

//         onchange(option)
//         setQuery(option);
//         setShowDropdown(false);
//     };
//     return (
//         <>
//             {/* <Select
//                 isDisabled={isDisabled}
//                 isLoading={isLoading}
//                 isClearable={isClearable}
//                 isSearchable={isSearchable}
//                 options={options}
//                 onChange={handleChange}
//                 placeholder="Select job to copy"
//                 defaultValue={options && options.filter((data) => data.value === copyJob)}
//             /> */}
//             <div style={{ position: 'relative' }}>
//                 <Form.Control
//                     type="text"
//                     placeholder="Enter or create a question"
//                     value={options && options.filter((data) => data.value === copyJob)}
//                     onChange={(e) => handleChange(e.target.value)}
//                     onFocus={() => setShowDropdown(true)}
//                 // onBlur={() => setShowDropdown(false)}
//                 />

//                 {showDropdown && (options.length > 0 || loading) && (
//                     <Dropdown.Menu show style={{ width: '100%', position: 'absolute', zIndex: 1 }}>
//                         {loading && (
//                             <Dropdown.Item disabled>
//                                 <Spinner animation="border" size="sm" /> Loading...
//                             </Dropdown.Item>
//                         )}
//                         {options.map((option, index) => (
//                             <Dropdown.Item key={index} onClick={() => handleSelect(option.id)}>
//                                 {option.label}
//                             </Dropdown.Item>
//                         ))}

//                     </Dropdown.Menu>
//                 )}

//             </div>
//         </>
//     );
// };

// export default CopyJob;
