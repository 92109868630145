import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import StarRating from 'react-rating-stars-component';

import { getliveInterviewEvaluation, addLiveInterviewEvaluation, EvaluationFinalRemarks } from "../store/actions/interviewEvaluatorAction";
import Swal from 'sweetalert2';
import Message from "../components/common/Message";
import moment from 'moment';
import { CustomButton, CustomRating, Label, Textarea } from "../components/form";
import Spinner from "../components/common/Spinner";
import HeaderCompanName from "../components/company/HeaderCompanName";

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 2000
    });
}

const EvaluateCandidateLiveInterview = () => {
    const [showMessage, setMessage] = useState(false);
    const [evaluatorRemarks, setEvaluatorRemarks] = useState('');
    const [evaluatorRating, setEvaluatorRating] = useState(0);
    const [errors, setErrors] = useState({});
    const { liveInterviewEvaluationData, loading, error } = useSelector((state) => state.evaluator);
    const dispatch = useDispatch();
    const { token } = useParams();
    const Evaluation = liveInterviewEvaluationData?.data;

    useEffect(() => {
        dispatch(getliveInterviewEvaluation(token));
    }, [dispatch, token]);
    const handleChange = (e) => {
        const { name, value } = e.target;


        if (name === 'evaluator_remarks') {
            setEvaluatorRemarks(value);
            setErrors((prevErrors) => ({
                ...prevErrors,
                evaluatorRemarks: value.trim() ? '' : 'Final remarks are required',
            }));
        }
    };


    const handleRatingChange = (newRating) => {
        setEvaluatorRating(newRating);
        setErrors((prevErrors) => ({
            ...prevErrors,
            evaluatorRating: newRating > 0 ? '' : 'Please provide a rating',
        }));
    };
    const validateForm = () => {
        const newErrors = {};

        if (!evaluatorRemarks) {
            newErrors.evaluatorRemarks = "Evaluator remarks are required";
        }

        if (evaluatorRating === 0) {
            newErrors.evaluatorRating = "Please provide a rating";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleSubmit = () => {
        if (validateForm()) {

            const payload = {
                final_remarks: evaluatorRemarks,
                final_rating: evaluatorRating,
                avg_rating: evaluatorRating,
                evaluation_date: new Date(),
            };

            dispatch(EvaluationFinalRemarks({ token, payload })).then((res) => {
                if (res.payload.error) {
                    sweetAlert(
                        "error",
                        "Error!",
                        res.payload.error
                    );
                } else {
                    sweetAlert(
                        "success",
                        "Success!",
                        res.message
                    );
                    setMessage(true)
                }
            })
                .catch(() => {
                    sweetAlert(
                        "error",
                        "Error!",
                        "Something went wrong!"
                    );
                });

        }
    };
    const formatDateTime = (dateTime) => {
        return moment(dateTime).format('MM-DD-YYYY h:mm A');
    };

    const formatDuration = (durationInMinutes) => {
        const duration = moment.duration(durationInMinutes, 'minutes');
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        let formattedDuration = '';
        if (hours > 0) {
            formattedDuration += `${hours} hour${hours > 1 ? 's' : ''} `;
        }
        if (minutes > 0) {
            formattedDuration += `${minutes} min${minutes > 1 ? 's' : ''} `;
        }
        if (seconds > 0) {
            formattedDuration += `${seconds} sec${seconds > 1 ? 's' : ''}`;
        }

        return formattedDuration.trim();
    };


    return (
        <>
            {!showMessage ? <Col className="pad-0">
                {loading && <Spinner />}
                {error ? <div className="link-error-wrap">
                    <div className="link-error"><h1>{error?.error}</h1></div>
                </div>
                    : <>
                        <HeaderCompanName
                            logo={<img src={`${Evaluation?.company_logo}`} style={{ width: "100%", height: "100%" }} alt="company logo" />}
                            bgclr={Evaluation?.company_header_footer_color}
                            name={Evaluation?.company_name}
                        />



                        <Col className="pad-20 sm-pad-0 y-scroll" md={12} sm={12} style={{ backgroundColor: "#F5F5F5" }}>


                            <Row className="row-pad-0 pad-10">
                                <h6 className='mt-1 mb-10 mt-10 pad-0 min-sub-heading'>
                                    <label>Evaluate Candidate(s)</label>
                                </h6>
                            </Row>


                            <Row className="row-pad-0 recordedinterview-wrap evaluateCandidate-wrap pad-10">
                                <Col md={12} sm={12} className="pad-10 mb-20 bg-white border-radius-5">
                                    <Row className='row-pad-0 evaluate-live-wrap'>
                                        <h6 className="min-sub-heading mt-10 mb-20"><span>Interview Recording</span></h6>
                                        <Col md={12}>
                                            <div className='Response-div'>
                                                <b className="md-mb-10">Participants: </b>
                                                {Evaluation?.participants && Evaluation?.participants.map((participant, index) => (
                                                    <span key={index}>{participant},</span>
                                                ))}

                                            </div>
                                        </Col>

                                        <Col md={12} className="mt-10">
                                            <div className='Response-div'>
                                                <div >
                                                    <b>Date & Time: </b>
                                                    <span>{formatDateTime(Evaluation?.created_date)}{ }</span>
                                                </div>
                                                <div className="sm-mt-10">
                                                    <b>Duration: </b>
                                                    <span>{formatDuration(Evaluation?.duration)}</span>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col md={6} sm={12} className="mt-20">
                                            <div className='mt-20 Response-wrap pad-10' style={{ height: "390px" }}>
                                                {Evaluation?.media_location ? (
                                                    <video width="100%" height="100%" style={{ objectFit: "cover" }} controls>
                                                        <source src={Evaluation?.media_location} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                ) : (
                                                    <p>Video not available.</p>
                                                )}
                                            </div>
                                        </Col>

                                        <Col md={6} sm={12} className="pad-0">
                                            {Evaluation?.show_feedback && (<Row className='feedback-wrap mt-10 mb-10 row-pad-0 pad-0'>
                                                <Col md={6} sm={12}>
                                                    <Label text="Internal Comments:" className="label-input form-label" />
                                                    <Textarea
                                                        style={{ maxHeight: '100px', height: "100px" }}
                                                        as="textarea"
                                                        readOnly
                                                        className="feedback"
                                                        placeholder={Evaluation?.internal_comment}
                                                    />
                                                </Col>

                                                <Col md={6} sm={12}>
                                                    <Label text="Internal Rating:" className="label-input form-label" />

                                                    <div className='feedback pad-10 d-flex justify-content-center align-items-center' style={{ maxHeight: '100px', height: "100px" }}>

                                                        {Evaluation?.internal_rating && <CustomRating value={Evaluation?.internal_rating} edit={false} />}


                                                    </div>
                                                </Col>
                                            </Row>
                                            )}
                                            <Row className='feedback-wrap mb-20 row-pad-0 pad-0 mt-30'>
                                                <Col md={6} sm={12} className="sm-mb-10">
                                                    <Label text="Enter Comments:" className="label-input form-label" />
                                                    <Textarea
                                                        as="textarea"
                                                        rows={3}
                                                        style={{ maxHeight: '100px', height: "100px" }}
                                                        name="evaluator_remarks"
                                                        className={`feedback ${errors.evaluatorRemarks ? 'is-invalid' : ''}`}
                                                        placeholder="Provide your comments"
                                                        value={evaluatorRemarks}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.evaluatorRemarks && <p className="text-danger">{errors.evaluatorRemarks}</p>}
                                                </Col>

                                                <Col md={6} sm={12} className="sm-mb-10">
                                                    <Label text="Rate Response:" className="label-input form-label" />
                                                    <div className='feedback pad-10 d-flex justify-content-center align-items-center' style={{ maxHeight: '100px', height: "100px" }}>
                                                        <StarRating
                                                            count={5}
                                                            size={20}
                                                            name="evaluator_rating"
                                                            activeColor="#FFD700"
                                                            value={evaluatorRating}
                                                            onChange={handleRatingChange}
                                                        />
                                                    </div>
                                                    {errors.evaluatorRating && <p className="text-danger">{errors.evaluatorRating}</p>}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>


                            <Row className="row-pad-0 bg-white border-radius-5 custom-width-note pad-10">
                                <Col md={12} sm={6}>
                                    <h5 className="min-sub-heading"><label>{Evaluation?.evaluator_name} Note</label></h5>
                                    {Evaluation?.message && <p className="ml-10">{Evaluation?.message}</p>}
                                </Col>
                            </Row>


                            <Col md={12} className="text-end mt-20 mb-20 sm-mr-10">
                                {/* <CustomButton text="Cancel" className="custom-btn-outline mr-10" /> */}
                                <CustomButton text="Submit Evaluation" className="custom-btn-primary sm-mr-10" onClick={handleSubmit} />
                            </Col>
                        </Col>
                    </>
                }
            </Col > : <Message />
            }

        </>
    );
}

export default EvaluateCandidateLiveInterview;
