import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Row, Col, Offcanvas, Container, Form } from 'react-bootstrap';
import { crossicon, sms } from '../../helper/Icons/Icon';
import { CustomButton, InputField, Label, Textarea } from '../form';
import { update, getById } from '../../store/actions/smsTemplateActions';
import { useDispatch, useSelector } from 'react-redux';
import SmsTemplateTypes from '../common/SmsTemplateTypes';
import Swal from 'sweetalert2';
import Spinner from "../common/Spinner";

const EditSMSTemplate = ({ handleClose, smsTemplateId }) => {
    const dispatch = useDispatch();
    const { smsTemplateDetail, loading } = useSelector((state) => state.smsTemplate);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [charCount, setCharCount] = useState(0);

    useEffect(() => {
        if (smsTemplateId) {
            dispatch(getById(smsTemplateId));
        }
    }, [dispatch, smsTemplateId]);

    useLayoutEffect(() => {
        if (smsTemplateDetail) {
            const cleanedMessage = smsTemplateDetail.message || '';
            setFormData({
                sms_template_type_id: smsTemplateDetail.sms_template_type_id,
                templateName: smsTemplateDetail.template_name,
                message: cleanedMessage
            });
            setCharCount(cleanedMessage.replace(/\s+/g, '').length);
        }
    }, [smsTemplateDetail]);

    const handleInsertVariable = (e) => {
        const selectedVariable = e.target.value;
        if (selectedVariable === "[Insert_Variable]") return;

        const newMessage = formData.message + selectedVariable;
        const newCharCount = newMessage.replace(/\s+/g, '').length;

        if (newCharCount > 160) {
            setErrors((prev) => ({
                ...prev,
                message: 'Message should not exceed 160 characters',
            }));
            return;
        }

        setFormData({
            ...formData,
            message: newMessage,
        });
        setCharCount(newCharCount);
        e.target.value = "[Insert_Variable]";
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'message') {
            const cleanedValue = value.replace(/\s+/g, '');
            if (cleanedValue.length > 160) {
                setErrors((prev) => ({
                    ...prev,
                    message: 'Message should not exceed 160 characters',
                }));
                return;
            } else {
                setErrors((prev) => ({
                    ...prev,
                    message: '',
                }));
            }
            setCharCount(cleanedValue.length);
        }

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const sweetAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 3000,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = {};
        if (!formData.templateName) newErrors.templateName = 'Template name is required';
        if (!formData.sms_template_type_id) newErrors.sms_template_type_id = 'Template type is required';
        if (!formData.message) newErrors.message = 'Message is required';
        if (charCount > 160) newErrors.message = 'Message should not exceed 160 characters';

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            const formDataFields = {
                sms_template_type_id: formData.sms_template_type_id,
                sms_template_name: formData.templateName,
                message: formData.message,
                is_active: 1,
            };

            dispatch(update({ smsTemplateId, formDataFields }))
                .then((res) => {
                    if (res.payload.error) {
                        sweetAlert("error", "Error!", `${res.payload.error}`);
                    } else {
                        handleClose();
                        sweetAlert("success", "Success!", `${res.payload.message}`);
                    }
                })
                .catch(() => {
                    sweetAlert("error", "Error!", "Something went wrong!");
                });
        }
    };

    return (
        <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "50%" }}>
            <Offcanvas.Header className="model-header bg-white actionlist-header">
                <Offcanvas.Title>
                    <span className="model-header-svg">{sms}Edit SMS Template</span>
                </Offcanvas.Title>
                <div className="text-end">
                    <CustomButton onClick={handleClose} className="text-end close-btn" text={crossicon} />
                </div>
            </Offcanvas.Header>
            {loading && <Spinner />}
            <Container fluid className="pad-10">
                <Form>
                    <Row className="mt-20 row-pad-0 bg-white border-radius-5 mt-10 pad-10">
                        <Col md={6}>
                            <Label className="label-input" text="Template Name" />
                            <InputField
                                type="text"
                                className="input-field"
                                id="templateName"
                                name="templateName"
                                placeholder="Add template name"
                                onChange={handleChange}
                                value={formData.templateName || ''}
                            />
                            {errors.templateName && <span className="text-danger">{errors.templateName}</span>}
                        </Col>
                        <Col md={6}>
                            <SmsTemplateTypes
                                templateTypeId={formData.sms_template_type_id}
                                Changehandle={handleChange}
                            />
                            {errors.sms_template_type_id && <span className="text-danger">{errors.sms_template_type_id}</span>}
                        </Col>
                        <Col md={12} className='mt-10 sms-template-texterea'>
                            <Label className="label-text" text="Message" />
                            <Textarea
                                as="textarea"
                                rows="10"
                                className="input-textarea"
                                placeholder="Enter your message"
                                id="message"
                                name="message"
                                onChange={handleChange}
                                value={formData.message || ''}
                            />
                            <div className='selectoptionsms'>
                                <Form.Select name='Insert-variabl' id='Insert-variabl' onChange={handleInsertVariable}>
                                    <option value="[Insert_Variable]">Insert Variable</option>
                                    <option value="[Company_Name]">Company Name</option>
                                    <option value="[Candidate_Name]">Candidate Name</option>
                                    <option value="[Interview_Link]">Interview Link</option>
                                    <option value="[No_of_days]">No.of days</option>
                                    {/* <option value="[Unique_Link]">Unique Link</option> */}
                                    <option value="[Interview_Type]">Interview Type</option>
                                    <option value="[Interview_Title]">Interview Title</option>
                                    <option value="[Interview_Start_Time]">Interview Start Time</option>
                                    <option value="[Interview_End_Time]">Interview End Time</option>
                                    {/* <option value="[Live_Interview_Link]">Live Interview Link</option> */}
                                    <option value="[Interview_Description]">Interview Description</option>
                                    <option value="[Job_Name]">Job Name</option>
                                    <option value="[Sender_Name]">Sender Name</option>
                                    <option value="[Evaluator_Name]">Evaluator Name</option>
                                    <option value="[interview_Evaluation_Link]">interview Evaluation Link</option>
                                </Form.Select>
                            </div>
                            <div className='value-count-texterea'>{charCount}/160</div>
                            {errors.message && <span className="text-danger">{errors.message}</span>}
                        </Col>
                        <Col md={12} className='text-end mt-20 mb-10 pad-0'>
                            <CustomButton className="custom-btn-outline mr-10" text="Cancel" onClick={handleClose} />
                            <CustomButton
                                className="custom-btn-primary"
                                onClick={handleSubmit}
                                text="Save"
                            // disabled={charCount > 160} // Disable save if limit is exceeded
                            />
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Offcanvas>
    );
};

export default EditSMSTemplate;
