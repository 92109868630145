import React, { useEffect, useState, useMemo } from 'react';
import { Col, Row, Nav, Form } from 'react-bootstrap';

import { ringcopy, videobox, mcqQuestion, textquestion, uploadfile, thingtime, retake, responsestime, word } from "../../helper/Icons/Icon";
import { Checkbox, CustomButton } from "../form";

import FileUploadView from '../common/FileUploadView';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from "../common/Spinner";
import Swal from 'sweetalert2';
import StarRating from 'react-rating-stars-component';
import { useDispatch, useSelector, } from "react-redux";
import { UpdateInterviewByToken, interviewtemplatetokent, updateInterveiewTimeine } from '../../store/actions/InterviewproceedAction';
const icons = { 'Video Question': videobox, 'Audio': ringcopy, 'MCQ Question': mcqQuestion, 'Written': textquestion, 'File Upload': uploadfile }
const config = require('../../config/config.json');

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 2000
    });
}

const CandidateResponse = () => {
    const { token } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [rating, setRating] = useState(0);
    const [comments, setComments] = useState('');

    const { interviewtemplatetDetail, loading, error } = useSelector((state) => state.Interviewproceed);
    const candidateRespones = useMemo(() => {
        return interviewtemplatetDetail?.interview_template_questions ?? [];
    }, [interviewtemplatetDetail]);

    const [selectedAnser, setselectedAnser] = useState(null);
    const [selectQuestion, setselectQuestion] = useState(null);
    const [answerId, setAnswerId] = useState(0);


    useEffect(() => {
        if (token) {
            dispatch(interviewtemplatetokent(token));
        }
    }, [dispatch, token]);

    useEffect(() => {
        if (answerId !== null && candidateRespones.length > 0) {
            const currentQuestion = candidateRespones[answerId];
            if (currentQuestion?.answers?.length > 0) {
                setselectedAnser(currentQuestion.answers[0]);
            } else {
                setselectedAnser(null);
            }
            setselectQuestion(currentQuestion);
        }
    }, [answerId, candidateRespones]);


    const handleRatingChange = (newRating) => {
        setRating(newRating);
    };

    const handleCommentsChange = (e) => {
        setComments(e.target.value);
    };

    const InterviewProceed = () => {
        let payload = {
            "interview_type_id": 1,
            "interview_name": interviewtemplatetDetail?.interview_name,
            "stage_id": 10, //interviw submitted
            "interview_title": interviewtemplatetDetail?.interview_name,
            "is_active": false,
            "end_timestamp": new Date(),
            "description": interviewtemplatetDetail?.interview_template_description,
            "interview_rating": rating,
            "comments": comments,

        }

        dispatch(UpdateInterviewByToken({ token, payload })).then((resp) => {
            if (resp.payload.error) {

                sweetAlert(
                    "error",
                    "Error!",

                    `${resp.payload.error}!`
                );
            }
            else {
                sweetAlert(
                    "success",
                    "Success!",
                    resp.payload.message
                );

                navigate(`/exit-message/${token}`);
            }


        }).catch(() => {
            sweetAlert(
                "error",
                "Error!",
                "Something went wrong!"
            );
        });

        const log = {
            key: `exited_interview`,
            log: `Exited interview`,
            date_time: new Date()
        };
        dispatch(updateInterveiewTimeine({ token, log }));
    };


    const selectAnswer = (id) => {
        setAnswerId(id);
    }

    return (
        <>
            {loading &&
                <Spinner />
            }
            <Row className="row-pad-0  pad-10" >
                <Col md={8} className='ipad-mobile-view-60p recordedinterview-wrap-left-side'>
                    <h6 className='pad-0 mt-1 sub-heading'> Preview Interview Questions</h6>
                </Col>
                <Col md={4} className='sm-hide  ipad-mobile-view-40p recordedinterview-wrap-left-right '>
                    <h6 className='pad-0  sub-heading'> Rate Your Experience</h6>

                </Col>

            </Row>
            <Row className="row-pad-0  interviewer-responses recordedinterview-wrap sm-recordedinterview-responses ">
                <Col md={8} className='mb-20 ipad-mobile-view-60p  recordedinterview-wrap-left-side sm-pad-0 bg-white border-radius-5'>
                    <Row className="row-pad-0 bg-white border-radius-5 pad-10" >
                        <div>
                            <h4>You are almost there.</h4>
                            <p>Kindly take the time to thoroughly review your responses and make sure you are satisfied with them.</p>
                        </div>
                        <h6 className=' mt-1 min-heading'> Candidate’s Responses</h6>
                        <Row className='row-pad-0 '>

                            <div className='responses-wrap  mt-10'>
                                <Nav variant="pills" className="responses-wrap-nav">
                                    {candidateRespones && candidateRespones.map((question, index) => (
                                        <Nav.Item key={index + 1}>
                                            <Nav.Link className={`box cursor ${index === answerId ? 'active' : ''}`} onClick={() => selectAnswer(index)}>
                                                <span>{icons[question.question_type_name]}</span>
                                                <span className='question-nav-link '>Question {index + 1}-{question.question_type_name}</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>


                                {
                                    selectedAnser && <div className='Response-left-side '>
                                        <Row className='row-pad-0'>
                                            <div className='Response-div sm-question-response-text question row-pad-0 '>
                                                <p>Question: {selectQuestion.question_text}</p>
                                            </div>
                                            <div className='Response-div sm-thingtime-wrap thingtime-wrap d-flex mt-20 row-pad-0 pad-10 '>
                                                <div className='thingtime'>
                                                    <small>{thingtime}Think Time:</small>
                                                    <span>
                                                        <span className={selectedAnser.answer_think_time > selectQuestion.think_time ? "resgreater" : ""}>
                                                            {selectedAnser.answer_think_time}
                                                        </span>
                                                        /<span>{selectQuestion.think_time}</span> Mins
                                                    </span>
                                                </div>

                                                {(selectedAnser.answer_media === 'Video Question' || selectedAnser.answer_media === 'Audio') &&
                                                    <>
                                                        <div className='responestime'>
                                                            <small>{responsestime}Response Time:</small>
                                                            <span>
                                                                <span className={selectedAnser.answer_response_time > selectQuestion.response_time ? "resgreater" : " "}>
                                                                    {selectedAnser.answer_response_time}
                                                                </span>
                                                                /<span>{selectQuestion.response_time}</span> Mins
                                                            </span>
                                                        </div>


                                                        <div className='retakes'>
                                                            <small>{retake}Retakes:</small><span>{selectedAnser?.answer_retake ?? 0}/{selectQuestion.retakes}</span>

                                                        </div>
                                                    </>
                                                }
                                            </div>

                                            {selectedAnser.answer_media === 'Video Question' && (
                                                <div className='mt-20 Response-wrap  response-wrap-answer-div pad-10'>
                                                    <video
                                                        width="100%" height="100%"
                                                        controls
                                                        src={`${selectedAnser?.interview_answer}`}
                                                    />
                                                </div>
                                            )}

                                            {selectedAnser.answer_media === 'Audio' && (
                                                <div className='mt-20 Response-wrap  response-wrap-answer-div audio-center pad-10'>
                                                    <audio
                                                        controls
                                                        src={`${selectedAnser?.interview_answer}`}
                                                    />
                                                </div>
                                            )}

                                            {selectedAnser.answer_media === 'Multiple Choice' && (
                                                <div className='Response-wrap  response-wrap-answer-div option-overflow-wrap-answer pad-10 mt-20'>
                                                    {selectQuestion.question_options.map((label, index) => (
                                                        <div className='Response-MCQResponse' key={index}>
                                                            <Checkbox
                                                                label={label.value}
                                                                type="radio"
                                                                id={`option${index + 1}`}
                                                                name="options"
                                                                checked={label.value === selectedAnser.interview_answer}
                                                            />
                                                        </div>
                                                    ))}

                                                </div>
                                            )}
                                            {selectedAnser.answer_media === 'Written' && (
                                                <div className='Response-wrap response-wrap-answer-div mt-20 pad-10'>

                                                    <p className='text-p' dangerouslySetInnerHTML={{ __html: selectedAnser.interview_answer }} />
                                                </div>
                                            )}
                                            {selectedAnser.answer_media === 'File Upload' && (
                                                <div className='Response-wrap response-wrap-answer-div pad-10 mt-20'>
                                                    <FileUploadView
                                                        nameext={selectedAnser.interview_answer.split('.').pop()}
                                                        name={`${selectedAnser.interview_answer}`}
                                                    />
                                                </div>
                                            )}



                                        </Row>

                                    </div>
                                }
                            </div>

                        </Row>
                    </Row >
                </Col >
                <Col md={12} className='sm-show pad-0 hide mt-10 sm-pad-0 mb-10'>
                    <h6 className='pad-0  sub-heading'> Rate Your Experience</h6>

                </Col>
                <Col className="ipad-mobile-view-40p mb-20 sm-pad-0 bg-white border-radius-5 recordedinterview-wrap-left-right" md={4}>

                    <Row className="row-pad-0 bg-white border-radius-5 pad-10">
                        <Col md={12}>
                            <h4 ClassName="min-heading">How was the interview process?</h4>
                            <p className="mt-15 ">
                                We value your feedback to enhance our interview process. Your input is important to us.
                            </p>
                            <Form.Label className='min-sub-heading mb-10'>Rating:</Form.Label>
                            <StarRating
                                value={rating}
                                count={5}
                                size={20}
                                onChange={handleRatingChange}
                                activeColor="#FFD700"
                            />
                        </Col>
                        <Form.Group controlId="exampleForm.ControlTextarea1" className="pad-20">
                            <Form.Label className='min-sub-heading  mb-10'>Comments</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={6}
                                placeholder="Enter your experience and suggestions below"
                                value={comments}
                                onChange={handleCommentsChange}
                            />
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={12} className="text-end mt-20 mb-10 sm-pad-0">
                    <CustomButton className="custom-btn-primary sm-proceed-btn" text="Save & Exit Interview" onClick={InterviewProceed} />
                </Col>

            </Row >


        </>
    );
}

export default CandidateResponse;
