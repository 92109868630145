
import { CustomButton } from '../form';
import { attachment, sms, email, users, interviewbilling, JobActive } from '../../helper/Icons/Icon';
import { Row, Col } from 'react-bootstrap';


import Spinner from '../common/Spinner';
import { Link, useNavigate } from 'react-router-dom';
import * as moment from 'moment';

const Plan = ({ accountInfo, loading, accountStatusverify, errorMessage, addPackage }) => {
    const navigate = useNavigate();

    const formatCustomDate = (date) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const momentDate = moment(date);
        const month = months[momentDate.month()];
        const day = momentDate.date();
        const year = momentDate.year();
        return `${month} ${day}`;
    };

    const handleUpgradeClick = () => {
        navigate("/billing/billing-packages", accountInfo);
    };

    const istrial = accountStatusverify?.is_trial && accountStatusverify?.is_trial;
    return (
        <>
            <Col md={12} className='bg-white radius-7 pad-20'>
                {loading && <Spinner />}

                {errorMessage ?
                    <Col md={12} className="d-flex justify-content-between align-items-center flex-column pad-0 mb-10">
                        <h4 className="sub-heading">
                            <div className="text-danger">{errorMessage} </div>
                        </h4>
                        <CustomButton text="Upgrade Plan " style={{ width: "25%" }} onClick={handleUpgradeClick} className="custom-btn-primary mt-20" />
                    </Col> : <>
                        <Col md={12} className="d-flex justify-content-between pad-0 mb-10">
                            <h4 className="sub-heading">
                                {accountInfo?.account_name} -  {accountInfo?.packageName}
                            </h4>
                            <CustomButton text="Upgrade Plan" onClick={handleUpgradeClick} className="custom-btn-primary" />
                        </Col>

                        <div className="billing-wrap-p billing-plan-card">


                            <p>
                                Your <small className="sub-heading">{accountInfo?.packageName}</small> of the growth plan ends in {accountStatusverify?.package_days_remaining} days. You currently have {accountInfo?.users && accountInfo?.users === 1 ? <>{accountInfo?.users} <Link to="/users">user</Link></> : <>{accountInfo?.users} <Link to="/users">users</Link></>} on your account, who have received a total of {accountInfo?.interviews && accountInfo?.interviews} responses.
                            </p>



                            <p style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center"
                            }}>
                                <label className='min-sub-heading'><span>Period:</span></label>
                                <label className='min-sub-heading mr-10 ml-10'><span>

                                    {formatCustomDate(accountInfo?.createdDate)} - {formatCustomDate(accountInfo?.package_expiry_date)}
                                </span></label>
                            </p>

                            {/* Billing Information Display */}
                            {[
                                { title: 'Interviews', icon: interviewbilling, current: accountInfo?.interviews, limit: accountInfo?.allowedInterviews == null ? "Unlimited interviews" : accountInfo?.allowedInterviews },
                                { title: 'Jobs', icon: JobActive, current: accountInfo?.jobs, limit: accountInfo?.allowedJobs },
                                { title: 'Users', icon: users, current: accountInfo?.users, limit: accountInfo?.allowedUsers },
                                { title: 'Email Credits', icon: email, current: accountInfo?.email_messages, limit: accountInfo?.allowedEmails },
                                { title: 'SMS Credits', icon: sms, current: accountInfo?.sms_messages, limit: accountInfo?.allowedSms },
                            ].map((item, index) => (
                                <div key={index} className='d-flex billing-Content'>
                                    <div className='title'>
                                        <h5 className='min-sub-heading'>{item.icon}<span className='title-name'>{item.title}:</span> <span>{item.current}</span></h5>
                                    </div>
                                    <div className='billing-box'>
                                        <span>
                                            Allowed {item.title}: {item.limit}
                                            {!istrial && (item.title === "Email Credits" || item.title === "SMS Credits") && (
                                                ((item.title === "Email Credits") ? <a href="#" className="mr-10 ml-10" onClick={() => addPackage("email")}>
                                                    Buy more
                                                </a> : <a href="#" className="mr-10 ml-10" onClick={() => addPackage("sms")}>
                                                    Buy more
                                                </a>)
                                            )}

                                        </span>
                                    </div>
                                </div>
                            ))}

                            {/* <div
                                className='add-package-btn'><CustomButton text="Add More Credits" className="custom-btn-primary" onClick={addPackage} />
                            </div> */}
                        </div></>}
            </Col >
        </>
    );
};

export default Plan;
