import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../common/Spinner';
import { interviewtemplatetokent } from "../../store/actions/InterviewproceedAction";
import RecordVideo from './RecordVideo';
import OptionsQuestion from './OptionsQuestion';
import ResponseEditor from "./ResponseEditor";
import Fileupload from "./FileUpload";
import InterviewMedia from "./InterviewMedia";
import { CustomButton } from '../form';
import AudioRecorder from './AudioRecorder';
import CriclrDiv from '../common/CriclrDiv';
import { sky, Video, retakeinterview, uploadfile, InterviewMediaicon, mic, answerDuration, textquestion, ChoiceQuestion } from "../../helper/Icons/Icon";
const icons = { 1: Video, 2: mic, 3: ChoiceQuestion, 4: textquestion, 5: uploadfile }
const text = { 1: "Video Question:", 2: "Audio Question:", 3: "MCQ Question:", 4: "Text Question:", 5: "File Upload Question:" }

const InterviewQuestion = () => {
    const { token } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showerror, setErrors] = useState(null)
    const [activeStep, setActiveStep] = useState(0);
    const start_timestamp = new Date();

    const { interviewtemplatetDetail, loading, error } = useSelector((state) => state.Interviewproceed);
    let activeQuestion = interviewtemplatetDetail?.interview_template_questions?.[activeStep] ?? null;
    let answer = activeQuestion?.answers?.[0] ?? null;
    const [reload, setReload] = useState(false)

    useEffect(() => {
        dispatch(interviewtemplatetokent(token)).then((res) => {
            if (res.payload.error) {
                setErrors(res.payload.error)
            }

        });

    }, [dispatch, token, reload, activeStep]);

    const reLoad = (reload) => {
        setReload((prev) => !prev);
    }

    const handleNextQuestion = () => {
        let current = activeStep + 1;
        setActiveStep(current);
    };

    const handlePreviousQuestion = () => {
        if (activeStep > 0) {
            let current = activeStep - 1;
            setActiveStep(current);
        }
    };


    const InterviewProceed = () => {
        navigate(`/candidate-response/${token}`);
    };

    return (

        <>
            {loading ? <Spinner /> : null}
            {
                interviewtemplatetDetail && (
                    <>
                        <Row className="row-pad-0">
                            <h4 className="sub-heading mt-10 ">Interview Questions</h4>

                            <Row className="row-pad-0 bg-white border-radius-5 pad-20  sm-pad-0 questions-wrap">
                                <Col md={12} className='wrap-steps-numbrs-wid' >
                                    <ul className="steps-numbrs-wid">
                                        {interviewtemplatetDetail?.interview_template_questions && interviewtemplatetDetail?.interview_template_questions.map((question, index) => (
                                            <li key={index} className={index === activeStep ? 'active' : ''}>
                                                <CriclrDiv icon={index + 1} clrName={`number-cricle v-blue-light`} />
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                                {
                                    activeQuestion &&
                                    <>

                                        <Col md={12} className="mt-10 d-flex   flex-wrap justify-content-between flex-wrap-md">


                                            <div className="system-card prevent-select">
                                                <div className="syetem-icon-question">{icons[activeQuestion.question_type_id]}</div>
                                                <div className="system-msg">
                                                    <small className="system-text prevent-select">{text[activeQuestion.question_type_id]} {activeQuestion.question_text} {activeQuestion?.is_optional ? "(optional)" : ""}</small>
                                                </div>
                                            </div>
                                            <div className=" system-card-fixed">
                                                <div className="syetem-icon-question">
                                                    {sky}
                                                </div>
                                                <div className="system-msg">
                                                    <small className="system-text">Allowed Think Time:</small>
                                                    <small className="system-text-span">{activeQuestion.think_time}:00 Mins</small>
                                                </div>
                                            </div>

                                            {
                                                (activeQuestion.question_type_id === 1 || activeQuestion.question_type_id === 2) &&
                                                <>
                                                    < div className="system-card-fixed ">
                                                        <div className="syetem-icon-question">
                                                            {answerDuration}
                                                        </div>
                                                        <div className="system-msg">
                                                            <small className="system-text">Allowed Answer Duration:</small>
                                                            <small className="system-text-span">{activeQuestion.response_time}:00 Mins</small>
                                                        </div>
                                                    </div>
                                                    <div className="system-card-fixed ">
                                                        <div className="syetem-icon-question">
                                                            {retakeinterview}
                                                        </div>
                                                        <div className="system-msg">
                                                            <small className="system-text">Allowed Retakes:</small>
                                                            <small className="system-text-span">{answer?.answer_retake ?? 0}/{activeQuestion.retakes}</small>
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                        </Col>

                                        <Row className="row-pad-0">
                                            <Col md={6} sm={12} className='pad-0'>
                                                <div className="bg-ligte-gray radius-7 mt-10 mb-20 questions-media-wrap  ">
                                                    <div className="media-box pad-10  mt-20">
                                                        <h4 className='InterviewMed-svg sub-heading'><span>{InterviewMediaicon}</span>Interview Media</h4>
                                                        <InterviewMedia media={activeQuestion.question_media} />
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col md={6} sm={12} className='pad-0'>
                                                <div className="bg-ligte-gray radius-7 mt-20 mb-20 answer-questions-wrap sm-recordedquestion-question  ">
                                                    {activeQuestion.question_type_id === 1 && <RecordVideo key={activeStep} question={activeQuestion} answer={answer} activeStep={activeStep} reLoad={reLoad} />}
                                                    {activeQuestion.question_type_id === 2 && <AudioRecorder key={activeStep} question={activeQuestion} answer={answer} activeStep={activeStep} reLoad={reLoad} />}
                                                    {activeQuestion.question_type_id === 3 && <OptionsQuestion key={activeStep} question={activeQuestion} answer={answer} activeStep={activeStep} reLoad={reLoad} />}
                                                    {activeQuestion.question_type_id === 4 && <ResponseEditor key={activeStep} question={activeQuestion} answer={answer} activeStep={activeStep} reLoad={reLoad} />}
                                                    {activeQuestion.question_type_id === 5 && <Fileupload key={activeStep} question={activeQuestion} answer={answer} activeStep={activeStep} reLoad={reLoad} />}
                                                    {/* Add conditions for other question types here */}
                                                </div>
                                            </Col>


                                        </Row>

                                        <Col md={12} className=" mb-10 text-end  md-btn-wrap mt-10 mr-20">
                                            {activeStep > 0 && <CustomButton className="custom-btn-primary sm-proceed-btn mr-10" text="Previous Question" onClick={handlePreviousQuestion} />}

                                            {activeStep === interviewtemplatetDetail?.interview_template_questions?.length - 1 ? (
                                                <CustomButton
                                                    className="custom-btn-primary sm-proceed-btn mr-20"
                                                    text="Preview Answers"
                                                    onClick={InterviewProceed}
                                                    disabled={!answer?.interview_answer && !activeQuestion?.is_optional}
                                                />
                                            ) : (
                                                <CustomButton
                                                    className="custom-btn-primary  sm-proceed-btn mr-20"
                                                    text="Next Question"
                                                    onClick={() => handleNextQuestion()}
                                                    disabled={!answer?.interview_answer && !activeQuestion?.is_optional}
                                                />
                                            )}
                                        </Col>
                                    </>
                                }
                            </Row>
                        </Row>
                    </>

                )
            }
        </>
    );
};

export default InterviewQuestion;
