import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const config = require('../../config/config.json');
const API_BASE_URL = config.API_BASE_URL;


const BASE_URL = `${API_BASE_URL}/users`;
const API_BASE_URL_PUBLIC = config.API_BASE_URL_PUBLIC
const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

export const create = createAsyncThunk(
    'user/create',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.post(`${BASE_URL}`, payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)


export const update = createAsyncThunk(
    'user/update',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.put(`${BASE_URL}/${payload.profileId}`, payload.formDataFields, config)
            return data;
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)


export const getAll = createAsyncThunk(
    'user/getAll',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            params: payload
        }

        try {
            const { data } = await axios.get(`${BASE_URL}`, config)
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const getById = createAsyncThunk(
    'user/getById',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${BASE_URL}/${id}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)


export const deleteUser = createAsyncThunk(
    'user/deleteUser',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.delete(`${BASE_URL}/${id}`, config);
            return data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)


export const getDesignatedUsers = createAsyncThunk(
    'user/designated-users',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL}/designated-users`, config);
            return data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const logedinUser = createAsyncThunk(
    'user/logedinUser',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL}/logedin-user`, config);
            return data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const getUsersRoles = createAsyncThunk(
    'user/users-roles',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL}/user-roles`, config);
            return data.data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const getRoles = createAsyncThunk(
    'user/getRoles ',
    async (thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL}/get-user-roles`, config);
            return data.data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const getTimeZone = createAsyncThunk(
    'user/TimeZone',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL}/timezones`, config);
            return data.data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const getTimeZonePublic = createAsyncThunk(
    'user/getTimeZonePublic ',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',

            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL_PUBLIC}/timezones`, config);
            return data.data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
// UsersProfile 

export const UsersProfile = createAsyncThunk(
    'user/UsersProfile',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL}/profile-detail/${id}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const UsersProfileLogin = createAsyncThunk(
    'user/UsersProfileLogin',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL}/profile-detail/${id}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const deleteUsers = createAsyncThunk(
    'job/deleteUsers',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.post(`${API_BASE_URL}/users-multiple-delete`, payload, config)
            return data;
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)

export const createPreference = createAsyncThunk(
    'user/createPreference',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.post(`${API_BASE_URL}/user-preference`, payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)



export const sendSms = createAsyncThunk(
    'user/sendSms',
    async (number, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.put(`${API_BASE_URL}/send-sms/${number}`, '', config);
            return data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const verifycode = createAsyncThunk(
    'user/verifycode',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            params: payload
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL}/verify-code`, config);
            return data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
