import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/authSlice'
import companyReducer from './slices/companySlice'
import jobReducer from './slices/jobSlice'
import candidateReducer from './slices/candidateSlice'
import industryReducer from './slices/industrySlice'
import jobTypeReducer from './slices/jobTypeSlice';
import payTypeReducer from './slices/payTypeSlice'
import workspaceTypeReducer from './slices/workspaceTypeSlice'
import interviewReducer from './slices/interviewSlice'
import workAuthorizationReducer from './slices/workAuthorizationSlice'
import countryReducer from './slices/countrySlice'
import stateReducer from './slices/stateSlice'
import meetingRequestReducer from './slices/meetingRequestSlice'
import interviewTemplateReducer from './slices/interviewTemplateSlice'
import smsTemplateReducer from './slices/smsTemplateSlice'
import emailTemplateReducer from './slices/emailTemplateSlice'
import candidateInvitationReducer from './slices/candidateInvitationSlice'
import userReducer from './slices/userSlice'
import smsTemplateTypeReducer from './slices/smsTemplateTypeSlice'
import InterviewNatureTypesReducer from './slices/InterviewNatureTypesSlice'
import emailTemplateTypeReducer from './slices/emailTemplateTypeSlice'
import documentReducer from './slices/documentSlice'
import activityLogsReducer from './slices/activityLogsSlice'
import meetingSchedularReducer from './slices/meetingSchedularSlice'
import InterviewproceedReducer from './slices/InterviewproceedSlice'
import questionReducer from './slices/questionSlice'
import questionTypeReducer from './slices/questionTypeSlice'
import VideoRecordReducer from './slices/VideoRecordingSlice'
import interviewAppReducer from './slices/interviewAppSlice'
import tableFieldsReducer from './slices/tableFieldsSlice'
import userPrefrenceReducer from './slices/userPrefrenceSlice'
import moduleStageReducer from './slices/moduleStageSlice'
import recorderInterviewReducer from './slices/recorderInterviewSlices'
import accountBillingReducer from './slices/accountBillingSlice'
import DashboardReducer from './slices/DashboardSlice'
import interviewEvaluatorReducer from './slices/interviewEvaluatorSlice'
import activityTypeReducer from './slices/activityTypeSlice'

export const store = configureStore({
    reducer: {
        recordedVideo: VideoRecordReducer,
        auth: authReducer,
        company: companyReducer,
        job: jobReducer,
        candidate: candidateReducer,
        industry: industryReducer,
        jobType: jobTypeReducer,
        payType: payTypeReducer,
        workspaceType: workspaceTypeReducer,
        interview: interviewReducer,
        workAuthorization: workAuthorizationReducer,
        country: countryReducer,
        state: stateReducer,
        meetingRequest: meetingRequestReducer,
        interviewTemplate: interviewTemplateReducer,
        smsTemplate: smsTemplateReducer,
        emailTemplate: emailTemplateReducer,
        candidateInvitations: candidateInvitationReducer,
        user: userReducer,
        InterviewNatureTypes: InterviewNatureTypesReducer,
        smsTemplateType: smsTemplateTypeReducer,
        emailTemplateType: emailTemplateTypeReducer,
        document: documentReducer,
        activityLogs: activityLogsReducer,
        meetingSchedular: meetingSchedularReducer,
        Interviewproceed: InterviewproceedReducer,
        question: questionReducer,
        questionType: questionTypeReducer,
        interviewApp: interviewAppReducer,
        tableField: tableFieldsReducer,
        userPrefrence: userPrefrenceReducer,
        moduleStage: moduleStageReducer,
        recorderInterview: recorderInterviewReducer,
        accountBilling: accountBillingReducer,
        dashboard: DashboardReducer,
        evaluator: interviewEvaluatorReducer,
        activityType: activityTypeReducer,

    },
})