import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { getDesignatedUsers } from '../../store/actions/userActions';
import { Label } from '../form';

const DesignatedUsers = ({ designatedUserId, onChange }) => {
    const dispatch = useDispatch();
    // const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const designatedUsers = useSelector(state => state.user.designatedUsers);
    const currentUser = designatedUsers?.current_user_id;

    useEffect(() => {
        dispatch(getDesignatedUsers());
    }, [dispatch]);

    const handleChange = (selectedOption) => {
        if (selectedOption) {
            onChange('followup_user', selectedOption.value);
        } else {
            onChange('followup_user', currentUser);
        }
    };

    if (!designatedUsers?.users || !Array.isArray(designatedUsers?.users)) {
        return (
            <>
                <Label text="Select Designated User" htmlFor="followup_user" />
                <Form.Select name='followup_user'>
                    <option value="">Something went wrong. Check your internet connection.</option>
                </Form.Select>
            </>
        );
    }

    const options = designatedUsers?.users.map(option => ({
        value: option.id,
        label: option.username
    }));

    const selectedOption = designatedUserId
        ? options.find(option => option.value === designatedUserId)
        : options.find(option => option.value === currentUser);



    return (
        <>
            <Label
                text="Designated Follow-up User"
                htmlFor="followup_user"
                className="label-input"
                tooltip="Meeting scheduler of this user will be shared with candidates to schedule a follow-up appointment"
                icon={<i style={{ marginLeft: "5px" }} className="fa fa-info-circle mr-5"></i>}
                required="required"
            />
            <Select
                isDisabled={isDisabled}
                isLoading={isLoading}

                isSearchable={isSearchable}
                options={options}
                onChange={handleChange}
                value={selectedOption} // Set the selected option
                placeholder="Select a follow-up user"
            />
        </>
    );
};

export default DesignatedUsers;
