import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { getAll } from '../../store/slices/jobTypeSlice';
import { Label } from '../form';

const JobTypes = ({ typeId, onChange }) => {
    const dispatch = useDispatch();
    const options = useSelector(state => state.jobType.jobTypes);

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    const handleChange = (e) => {
        onChange(e);
    };

    return (
        <>
            <Label text="Job Type" className="label-input" HtmlFor="job_type_id" required="required" />
            <Form.Select name='job_type_id' id='job_type_id' onChange={handleChange}>
                <option value="">Select the job type</option>
                {options &&
                    options.map(option => (
                        <option key={option.id} value={option.id} selected={(typeId && typeId == option.id) ? "selected" : ""}>{option.name}</option>
                    ))}
            </Form.Select >
        </>
    );
};

export default JobTypes;