import { createSlice } from '@reduxjs/toolkit'
import { } from '../actions/authActions'
import { create, get, getPlan, stripePayment, getAmountBreakdown, updatePackagepurchased, update, accountUsageInfo, getPackages, packagePurchased, packageFinal, getPackagesAll, getTransactionsAll, downloadInvoice, accountStatus } from '../actions/accountBillingAction'

const initialState = {
    loading: false,
    accountBilling: null,
    accountInfo: null,
    packagesGet: null,
    purchasedPackage: null,
    allPackage: null,
    monthlyPlan: null,
    updatePackge: null,
    accountStatusverify: null,
    amoutBreakDown: [],
    allPackageTransactions: [],
    error: null,

}

const accountBillingSlice = createSlice({
    name: 'accountBilling',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading = false
                // state.accountBilling = action.payload

            })
            .addCase(create.rejected, (state, action) => {
                state.loading = false
                state.error = action

            }).addCase(get.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(get.fulfilled, (state, action) => {
                state.loading = false
                state.accountBilling = action.payload

            })
            .addCase(get.rejected, (state, action) => {
                state.loading = false
                state.error = action

            }).addCase(update.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(update.fulfilled, (state, action) => {
                state.loading = false
                state.accountBilling = action.payload

            })
            .addCase(update.rejected, (state, action) => {
                state.loading = false
                state.error = action
            }).addCase(accountUsageInfo.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(accountUsageInfo.fulfilled, (state, action) => {
                state.loading = false
                state.accountInfo = action.payload

            })
            .addCase(accountUsageInfo.rejected, (state, action) => {
                state.loading = false
                state.error = action
            }).addCase(getPackages.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(getPackages.fulfilled, (state, action) => {
                state.loading = false
                state.packagesGet = action.payload

            })
            .addCase(getPackages.rejected, (state, action) => {
                state.loading = false
                state.error = action
            }).addCase(packagePurchased.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(packagePurchased.fulfilled, (state, action) => {
                state.loading = false
                state.purchasedPackage = action.payload

            })
            .addCase(packagePurchased.rejected, (state, action) => {
                state.loading = false
                state.error = action
            }).addCase(packageFinal.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(packageFinal.fulfilled, (state, action) => {
                state.loading = false
                state.purchasedPackage = action.payload

            })
            .addCase(packageFinal.rejected, (state, action) => {
                state.loading = false
                state.error = action

            }).addCase(getTransactionsAll.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(getTransactionsAll.fulfilled, (state, action) => {
                state.loading = false
                state.allPackageTransactions = action.payload

            })
            .addCase(getTransactionsAll.rejected, (state, action) => {
                state.loading = false
                state.error = action
            })




            .addCase(getPackagesAll.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(getPackagesAll.fulfilled, (state, action) => {
                state.loading = false
                state.allPackage = action.payload

            })
            .addCase(getPackagesAll.rejected, (state, action) => {
                state.loading = false
                state.error = action
            }).addCase(downloadInvoice.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(downloadInvoice.fulfilled, (state, action) => {
                state.loading = false


            })
            .addCase(downloadInvoice.rejected, (state, action) => {
                state.loading = false
                state.error = action
            }).addCase(getPlan.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(getPlan.fulfilled, (state, action) => {
                state.loading = false
                state.monthlyPlan = action.payload


            })
            .addCase(getPlan.rejected, (state, action) => {
                state.loading = false
                state.error = action
            }).addCase(stripePayment.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(stripePayment.fulfilled, (state, action) => {
                state.loading = false
                // state.monthlyPlan=action.payload


            })
            .addCase(stripePayment.rejected, (state, action) => {
                state.loading = false
                state.error = action
            }).addCase(updatePackagepurchased.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(updatePackagepurchased.fulfilled, (state, action) => {
                state.loading = false
                state.updatePackge = action.payload


            })
            .addCase(updatePackagepurchased.rejected, (state, action) => {
                state.error = action
                state.loading = false

            }).addCase(accountStatus.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(accountStatus.fulfilled, (state, action) => {
                state.loading = false
                state.accountStatusverify = action.payload


            })
            .addCase(accountStatus.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload


            }).addCase(getAmountBreakdown.pending, (state, action) => {
                state.loading = true
                state.error = null


            })
            .addCase(getAmountBreakdown.fulfilled, (state, action) => {
                state.loading = false
                state.amoutBreakDown = action.payload


            })
            .addCase(getAmountBreakdown.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })


    },
})

export default accountBillingSlice.reducer;

