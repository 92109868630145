import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Spinner } from 'react-bootstrap';
import { getInterviewsByCandidate } from '../../store/actions/candidateActions';
import { Label } from '../form';

const InterviewsByCandidate = ({ Changehandle, UniqueID }) => {
    const dispatch = useDispatch();
    const { InterviewsByCandidate, loading, error } = useSelector((state) => state.candidate);
    let InterviewsByCandidatedata = InterviewsByCandidate?.data;

    useEffect(() => {
        if (UniqueID) {
            dispatch(getInterviewsByCandidate(UniqueID));
        }
    }, [dispatch, UniqueID]);



    if (loading) {
        return <Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>;
    }

    if (!InterviewsByCandidatedata || !Array.isArray(InterviewsByCandidatedata)) {
        return (
            <>
                <Label text="Select Job" className="label-input" HtmlFor="select_job" />
                <Form.Select name="select_job" disabled>
                    <option value="">Something went wrong.</option>
                </Form.Select>
            </>
        );
    }

    return (
        <>
            <Label text="Select Job" HtmlFor="select_job" required="required" />
            <Form.Select name="select_job" id="select_job" onChange={Changehandle}>
                <option value="">Select a job from the dropdown</option>
                {InterviewsByCandidatedata.map((option) => (
                    <option key={option.interview_id} value={`${option.interview_id}|${option.interview_type_id}`}
                    >
                        {option.job_name}{option.interview_title ? ` (${option.interview_title})` : ''}

                    </option>
                ))}
            </Form.Select>
        </>
    );
};

export default InterviewsByCandidate;
