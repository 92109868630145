import React from "react";
import { Routes, Route } from "react-router-dom";

import '../css/style.css';
import '../css/Tabel.css';
import '../css/detailPage.css';
import '../css/templatetab.css';
import '../css/interview.css';
import '../css/custom.css';

import '../css/New.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../css/mediaQuery820px.css';
import '../css/mediaQuery768px.css';
import ForgetPassword from "../components/accountSetting/Forgetpassword.jsx";
import Resetpassword from "../components/accountSetting/Resetpassword.jsx";
import Requestdemo from "../components/accountSetting/RequestDemo";
import Login from "../pages/Login";
import Register from "../components/accountSetting/Register";
import Interview from "../components/candidate/Interviews";
import Layout from "../layout/Layout";
import Company from "../pages/Company";
import MeetingRequest from "../pages/MeetingRequest";
import Dashboard from '../pages/Dashboard';
import Candidates from "../pages/Candidates";
import Job from "../pages/Job";
import EvaluateCandidate from "../EvaluateCandidate/EvaluateCandidate.jsx";
import Logout from "../pages/Logout";
import TemplateLayout from "../layout/TemplateLayout";
import SmsTemplates from "../pages/SmsTemplates";
import InterviewTemplates from "../pages/InterviewTemplates";
import EmailTemplates from "../pages/EmailTemplates";
import SettingLayout from "../layout/SettingLayout";
import UserSetting from "../pages/UserSetting";
import IntegrationsSetting from "../pages/IntegrationsSetting";
import BillingLayout from "../layout/BillingLayout.jsx";
import Planbilling from "../components/accountSetting/Planbilling.jsx";
import PlanOverview from "../components/accountSetting/PlanOverview.jsx";
import BillingList from "../components/accountSetting/BillingList.jsx";
import CompanyToken from "../components/company/CompanyToken.jsx";
import LayoutLayoutRecorded from "../layout/LayoutRecorded.jsx";
import LayoutLiveInterviews from "../layout/LayoutLiveInterviews.jsx";
import JobDetail from "../components/interview/JobDetail.jsx";
import AddCandidate from "../components/interview/AddCandidate.jsx";
import SystemCheck from "../components/interview/SystemCheck.jsx";
import WelcomeInterview from "../components/interview/WelcomeInterview.jsx";
import InterviewQuestion from "../components/interview/InterviewQuestion.jsx";
import CandidateResponse from "../components/interview/CandidateResponse.jsx";
import MeetingRequestInterview from "../components/interview/MeetingRequestInterview.jsx";
import PracticeQuestion from "../components/interview/PracticeQuestion.jsx"; import ExitMessageInterview from "../components/interview/ExitMessageInterview.jsx";
import LiveJobDetail from "../components/interview/LiveJobDetail.jsx";
import LiveInterviews from "../components/interview/LiveInterview.jsx";
import SystemCheckLive from "../components/interview/SystemCheckLive.jsx";
import PreviewCompany from "../components/company/PreviewCompany.jsx";
import PreviewCompanyToken from "../components/company/PreviewCompanyToken.jsx";
import ViewCompanyModel from "../components/company/ViewCompanyModel.jsx";
import NotFoundPage from "../components/common/NotFoundPage.jsx";
import EvaluateCandidateLiveInterview from "../EvaluateCandidate/EvaluateCandidateLiveInterview.jsx";
const Indexs = () => {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/requestdemo" element={<Requestdemo />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/register/:token?" element={<Register />} />
            <Route path="/users/resetpassword/:token?" element={<Resetpassword />} />
            <Route path="/users/forgetpassword" element={<ForgetPassword />} />

            <Route path="/company" element={<ViewCompanyModel />} />
            <Route path="/company/:token" element={<PreviewCompanyToken />} />
            <Route path="/preview-company" element={<PreviewCompany />} />



            <Route path="/EvaluateCandidate/:token?" element={<EvaluateCandidate />} />
            <Route path="/LiveEvaluateCandidate/:token?" element={<EvaluateCandidateLiveInterview />} />
            <Route element={<Layout />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/companies" element={<Company />} />
                <Route path="/candidates" element={<Candidates />} />
                <Route path="/jobs" element={<Job />} />
                <Route path="/interviews" element={<Interview />} />
                <Route path="/meeting-requests" element={<MeetingRequest />} />



                <Route element={<TemplateLayout />}>
                    <Route path="interview-template" element={<InterviewTemplates />} />
                    <Route path="sms-template" element={<SmsTemplates />} />
                    <Route path="email-template" element={<EmailTemplates />} />
                </Route>

                <Route element={<SettingLayout />}>
                    <Route path="users" element={<UserSetting />} />
                    <Route path="integration" element={<IntegrationsSetting />} />
                    <Route path="billing" element={<BillingLayout />}>
                        <Route path="plan-overview" element={<PlanOverview />} />
                        <Route path="billing-history" element={<BillingList />} />
                        <Route path="billing-packages" element={< Planbilling />} />
                    </Route>

                </Route>
            </Route>

            <Route path="/invitation/:token" element={<CompanyToken />} />

            <Route element={<LayoutLayoutRecorded />}>
                <Route path="job-detail/:token" element={<JobDetail />} />
                <Route path="add-candidate/:token" element={<AddCandidate />} />
                <Route path="system-check/:token" element={<SystemCheck />} />
                <Route path="welcome-interview/:token" element={<WelcomeInterview />} />
                <Route path="practice-question/:token" element={<PracticeQuestion />} />
                <Route path="interview-question/:token" element={<InterviewQuestion />} />
                <Route path="exit-message/:token" element={<ExitMessageInterview />} />
                <Route path="candidate-response/:token" element={<CandidateResponse />} />
                <Route path="meeting-request/:token" element={<MeetingRequestInterview />} />


            </Route>
            <Route element={<LayoutLiveInterviews />}>
                <Route path="/live-job-detail/:token" element={<LiveJobDetail />} />
                <Route path="/live-job-detail/:token/:name" element={<LiveJobDetail />} />
                <Route path="system_check_Live/:token/:name" element={<SystemCheckLive />} />
                <Route path="system_check_Live/:token" element={<SystemCheckLive />} />
                <Route path="system_check_Live/:token/:name" element={<SystemCheckLive />} />
                <Route path="live_interview/:token" element={<LiveInterviews />} />
                <Route path="live_interview/:token/:name" element={<LiveInterviews />} />

            </Route>
            {/* NotFoundPage routes */}

            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};

export default Indexs;
