import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { CustomButton } from '../form';
import { Col } from 'react-bootstrap';
import { Back } from 'react-bootstrap-icons';
const localizer = momentLocalizer(moment);


const eventStyleGetter = (event, start, end, isSelected) => {
  let style = {
    backgroundColor: event.title === 'OFF day' ? '#DC0076' : 'red',
    borderRadius: "8px",
    fontSize: "13px",
    lineHeight: "18px",
    opacity: 0.8,
    color: '#fff',
    border: '0px',
    display: 'block',
  };
  return {
    style: style
  };
};

const ViewCalendarEvents = ({ onclick }) => {

  const myEventsList = [
    {
      title: "[Candidate's Name] Interview for the Position of [Job Name]",
      start: new Date('2024-04-01'), // March 1st, 2024
      end: new Date('2024-04-02'), // March 2nd, 2024
    },
    {
      title: 'Working Day',
      start: new Date('2024-04-05'), // March 5th, 2024
      end: new Date('2024-04-07'), // March 7th, 2024
    },
    {
      title: 'OFF Day',
      start: new Date('2024-04-05'), // March 5th, 2024
      end: new Date('2024-04-07'), // March 7th, 2024
    },
    // Add more events as needed
    {
      title: 'Meeting with the Team',
      start: new Date(), // Automatically calculated
      end: new Date(), // Automatically calculated
    },
    {
      title: 'Training Session',
      start: new Date(), // Automatically calculated
      end: new Date(), // Automatically calculated
    },
    // Add more events as needed
  ];


  return (
    <>
      <div>
        <Calendar
          localizer={localizer}
          events={myEventsList}
          startAccessor="start"
          endAccessor="end"
          eventPropGetter={eventStyleGetter}
          style={{ height: 500 }}
        />

      </div>
      <Col md={12} className="text-end mt-20">
        <CustomButton onClick={onclick} className="btn-full" text="Back" />

      </Col>

    </>
  );
};

export default ViewCalendarEvents;
