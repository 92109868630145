import React from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import RequiredStar from '../common/RequiredStar';

const Label = (props) => {
  return (
    <Form.Label {...props}>
      {props.text}{props.required && <RequiredStar />}
      {props.icon && (
        <OverlayTrigger
          placement="top" // Adjust placement if needed
          overlay={
            <Tooltip id="tooltip-quick-action">
              {props.tooltip}
            </Tooltip>
          }
        >
          <span>{props.icon}</span>
        </OverlayTrigger>
      )}
    </Form.Label>
  );
};

export default Label;
