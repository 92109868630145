import { Col, Container, Row } from "react-bootstrap";
import SystemCheckbox from "./SystemCheckbox";
import { useEffect, useState } from "react";
import { wifi, mic, time, lightimg, StartInterviwimg, camera, readcopy } from "../../helper/Icons/Icon";


import CriclrDiv from "../common/CriclrDiv";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import video from '../../assets/video/'
const SystemCheck = () => {
    const { token } = useParams();
    const location = useLocation();

    const navigate = useNavigate();
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [cameraPermission, setCameraPermission] = useState(null);
    const [microphonePermission, setMicrophonePermission] = useState(null);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
        checkCameraPermission();
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);
    const checkCameraPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            stream.getTracks().forEach(track => track.stop());
            setCameraPermission(true);
            setMicrophonePermission(true);
        } catch (error) {
            console.error('Error accessing camera:', error);
            setCameraPermission(false);
            setMicrophonePermission(false);
        }
    };
    const PermissionsHandle = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            // getUserMedia is not supported

            window.alert('getUserMedia is not supported in this browser.');
            return;
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

            stream.getTracks().forEach(track => track.stop());
            setCameraPermission(true);
            setMicrophonePermission(true);
        } catch (error) {
            console.error('Error accessing camera/microphone:', error);

            if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
                // Requested device not found
                window.alert('Requested device not found.');
            } else if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {

                window.alert('Permission denied to access camera/microphone.');
            } else {

                window.alert('Error accessing camera/microphone.');
            }
        }
    };



    const InterviewProceed = () => {
        // navigate(`/welcome-interview`, { state: { token: location.state?.token } });
        navigate(`/welcome-interview/${token}`);
    };
    const PracticeQuestion = () => {
        // navigate(`/practice-question`, { state: { token: location.state?.token, candidateId: location.state.candidateId } })
        navigate(`/practice-question/${token}`);

    }
    return (
        <>
            <Col md={12} className="pad-0 system-check-wrap ">
                <Container fluid className="pad-20 sm-pad-10" >
                    <Col md={12} className="bg-white border-radius-5 pad-10 ">
                        <h4 className="sub-heading">System Check</h4>

                        <Row className="mt-15 pad-10 " >

                            <Col md={4} className="mb-1"><SystemCheckbox icon={wifi} onclick={PermissionsHandle} clrName={isOnline ? 'system-card-success' : 'system-card-erro'} text="System Check" /></Col>
                            <Col md={4} className="mb-1"><SystemCheckbox icon={camera} onclick={PermissionsHandle} clrName={cameraPermission ? 'system-card-success' : 'system-card-erro'} text="Camera Check" /></Col>
                            <Col md={4} className="mb-1"><SystemCheckbox icon={mic} onclick={PermissionsHandle} clrName={microphonePermission ? 'system-card-success' : 'system-card-erro'} text="Microphone Check" /></Col>
                        </Row>
                    </Col>

                    <Col md={12} className="bg-white border-radius-5 pad-20 mt-20">
                        <Row className="pad-20 sm-pad-0">
                            <Col md={5} sm={12} className="mb-10 mt-1">
                                <video width="90%" height="100%" controls>
                                    <source src="" type="video/mp4" />
                                    Your browser does not support the video tag.

                                </video>
                            </Col>
                            <Col md={7} sm={12} className="pad-0">
                                <h4>Tips to Record a Great Video </h4>

                                <div className="d-flex mt-20">
                                    <CriclrDiv icon={readcopy} clrName="v-blue-light tips-svg tips-Circle-wrap" />
                                    <div className="ml-15 line-height-24">
                                        <h5>Read Instructions Carefully</h5>
                                        <p>You will be more successful in the interview and you will demonstrate your ability to work carefully and thoroughly by following the directions for the interview very carefully.</p>
                                    </div>
                                </div>

                                {/* second tipe */}
                                <div className="d-flex">
                                    <CriclrDiv icon={camera} clrName="v-blue-light tips-svg tips-Circle-wrap" />
                                    <div className="ml-15 line-height-24">
                                        <h5>Learn How to Record a Great Video </h5>
                                        <p>When you are answering a question, look directly at the camera rather than at your computer screen. Imagine that the personal who will be viewing this video is right there. Pretend you are talking to that person.</p>
                                    </div>
                                </div>
                                {/* */}
                                <div className="d-flex">
                                    <CriclrDiv icon={time} clrName="v-blue-light tips-svg tips-Circle-wrap" />
                                    <div className="ml-15 line-height-24">
                                        <h5>Keep Tabs on Deadline</h5>
                                        <p>Usually these interviews have a time-limit. So, put a clock where you can easily see it, and use the time wisely.</p>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} className="mb-20" >
                        <Row className="row-pad-0">
                            <Col md={5} className="bg-white border-radius-5 pad-10 mt-20   cursor StartInterview-main" onClick={PracticeQuestion}>
                                <div className="StartInterview-wrap  cursor" >

                                    <img src={lightimg} alt="Light Image" />
                                </div>
                                <h4>  Try Practice Question</h4>

                            </Col>
                            <Col md={2}>

                            </Col>
                            <Col md={5} className="bg-white border-radius-5 pad-10 mt-20 StartInterview-main  cursor" onClick={InterviewProceed} >
                                <div className="StartInterview-wrap  cursor"> <img src={StartInterviwimg} alt="Start Interview Now Image" />
                                </div>
                                <h4>  Start Interview Now</h4>

                            </Col>
                        </Row>
                    </Col>
                </Container>
            </Col>
        </>
    );
}

export default SystemCheck;
