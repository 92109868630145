import { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Card, Image, Carousel } from "react-bootstrap";
import { JobActive, adduser, website, linkden, facebook, Xtiwtter, eye } from "../../helper/Icons/Icon";
import { CustomButton } from "../form/index";
import CriclrDiv from "../common/CriclrDiv";
import AnalyticityStats from "../common/AnalyticityStats";
import Paginate from "../common/Paginate";
import Analytics from "../common/Analytics";
import banner from '../../assets/images/banner.png';
import { getCompanyJobsLive } from "../../store/actions/InterviewproceedAction";
import Prehotos from "./Prehotos";
import HeaderCompanName from "./HeaderCompanName";
import Spinner from "../common/Spinner";
import { companyPreviewtoken } from "../../store/actions/InterviewproceedAction";
import JobsLikeToken from "./JobsLikeToken";
const config = require('../../config/config.json');

const PreviewCompanyToken = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(3);
  const clrName = "bg-ligte-gray";
  const { companyPreviewDetail, loading, error } = useSelector((state) => state.Interviewproceed);
  const { CompanyJobs } = useSelector((state) => state.Interviewproceed);
  const jobs = CompanyJobs?.data || [];
  const pages = CompanyJobs.pagination?.current_page;
  const totalPages = CompanyJobs.pagination?.total_pages;
  const totalRecords = CompanyJobs.pagination?.total_jobs;
  useEffect(() => {

    const payload = {
      limit: perPage,
      page: currentPage
    };
    if (token) {
      dispatch(companyPreviewtoken({ token, payload }));
      dispatch(getCompanyJobsLive({ companytoken: token, payload }));
    }



  }, [dispatch, currentPage, perPage]);


  const data = {
    Companylabel: "Company Name",
    Companyvalue: companyPreviewDetail?.company_name || "-",
    Industrylabel: "Industry",
    Industryvalue: companyPreviewDetail?.industry?.industry_name || "-",
    Citylabel: "City, State",
    Cityvalue: companyPreviewDetail?.city || "-",
    ZipCodelabel: "Zip Code",
    ZipCodevalue: companyPreviewDetail?.zip_code || "-",
    Websitelabel: "Website",
    Websitevalue: companyPreviewDetail?.website_url || "-",
    Countrylabel: "Country",
    Countryvalue: companyPreviewDetail?.country_name || "-",
    CompanySizelabel: "Company Size",
    CompanySizevalue: companyPreviewDetail?.company_size || "-",
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPage = (perPage) => {
    setPerPage(perPage);
  };
  const shouldlogo = companyPreviewDetail?.company_logo;
  const shouldbanner = companyPreviewDetail?.company_banner;
  const buttonColor = companyPreviewDetail?.button_colour_code;
  const buttonTextColor = companyPreviewDetail?.button_text_colour_code;


  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <>
      {companyPreviewDetail && (<>

        <HeaderCompanName logo={<img src={`${companyPreviewDetail.company_logo}`} style={{ width: "100%", height: "100%", }} alt="company logo" />} bgclr={companyPreviewDetail?.header_footer_colour_code} name={companyPreviewDetail.company_name} />



        <Col className="pad-20 bg-ligte-gray wrap-company y-scroll">
          {companyPreviewDetail?.landing_page_settings?.show_banner && ((shouldlogo || shouldbanner) && (


            <Col md={12} className="p-relative pad-0 ">
              <Row className="row-pad-0 virtelligenceTeam-card pb-10 ">
                <Col md={12} className="p-relative pad-0 ">
                  <div className="virtelligenceTeam-img">
                    <img alt="" src={`${shouldbanner ?? banner}`} />
                  </div>

                  {companyPreviewDetail?.landing_page_settings?.show_logo && <div className="profile-image-view-company p-absolute ">
                    <img src={`${shouldlogo}`} alt="" />
                  </div>
                  }
                  {companyPreviewDetail?.landing_page_settings?.show_name && <div className="company-name">
                    <b>{companyPreviewDetail.company_name}</b>
                  </div>
                  }
                </Col>
              </Row>
            </Col>
          ))}
          <Row className="row-pad-0 mt-20">
            <Col md={7} className="">
              <Row className="pad-20 mt-20 bg-white border-radius-5">
                <Row className='row-pad-0   mb-10 '>
                  <Col md={12} className='pad-0'><h2 className="sub-heading">{companyPreviewDetail?.landing_page_settings?.show_name && `${data?.Companyvalue}`} Company Overview</h2> </Col>
                  <span className='single-border d-block'></span>
                </Row>

                <Row className='row-pad-0  mt-1 CompanyOverview-body'>
                  <Col md={6} className='' >
                    {companyPreviewDetail?.landing_page_settings?.show_name && <div className="content">
                      <label className="label-view ">{data?.Companylabel}:</label><small className="view-text">{data?.Companyvalue}</small>
                    </div>
                    }
                    <div className="content">
                      <label className="label-view " >{data?.Industrylabel}:</label> <small className="view-text">{data?.Industryvalue}</small>
                    </div>
                    <div className="content">
                      <label className="label-view " >{data?.Citylabel}:</label> <small className="view-text" >{data?.Cityvalue}</small>

                    </div>
                    <div className="content">
                      <label className="label-view ">{data?.ZipCodelabel}:</label> <small className="view-text" >{data?.ZipCodevalue}</small>

                    </div>
                  </Col>

                  <Col md={6} className=''>
                    <div className="content">
                      <label className="label-view ">{data?.Websitelabel}:</label> <small className="view-text" >{data?.Websitevalue}</small>
                    </div>
                    <div className="content">
                      <label className="label-view ">{data?.Countrylabel}:</label><small className="view-text" >{data?.Countryvalue}</small>
                    </div>
                    <div className="content">
                      <label className="label-view ">{data?.CompanySizelabel}:</label> <small className="view-text" >{data?.CompanySizevalue == 0 ? "" : data?.CompanySizevalue}</small>
                    </div>
                  </Col>
                </Row>

              </Row>
              {companyPreviewDetail?.landing_page_settings?.show_description && (companyPreviewDetail?.company_description &&
                <Row className="pad-20 mt-20 bg-white border-radius-5">
                  <Row className='row-pad-0 mb-1 '><Col md={12} className='pad-0'><h2 className="sub-heading">About {companyPreviewDetail?.company_name}</h2> </Col>
                    <span className='single-border d-block'></span>
                  </Row>
                  <Col md={12} className="mt-1 mb-10">
                    <p dangerouslySetInnerHTML={{ __html: companyPreviewDetail?.company_description }} />
                  </Col>
                </Row>
              )}

              {companyPreviewDetail?.landing_page_settings?.show_images && (
                <Row className="pad-20 mt-20 border-radius-5 bg-white">
                  <Prehotos company={companyPreviewDetail} token={token} PageChange={handlePageChange} PerPage={handlePerPage} perPageSate={perPage} currentPageSate={currentPage} />
                </Row>
              )}
            </Col>
            <Col md={5} className="">
              {companyPreviewDetail?.landing_page_settings?.show_cards && (
                <Row className="pad-20 mt-20 bg-white border-radius-5 ml-10  border-radius-5 sml-0 smr-0">
                  <Row className='row-pad-0  mb-10 pb-10'>
                    <Col md={12} className='pad-0'>
                      <h2 className="sub-heading"> Highlights</h2>
                    </Col>
                    <span className='single-border d-block'></span>
                  </Row>
                  <Row className="d-flex justify-content-center flex-wrap row-pad-0 pb-10">
                    <div className="d-flex highlights-box border-radius-5 border-highlights-box pb-10 mr-15">
                      <Analytics
                        className={clrName}
                        icon={JobActive}
                        link=""
                        text="Job Active"
                        activeNumber={companyPreviewDetail.active_jobs || 0}
                      />
                    </div>
                    <div className="d-flex highlights-box border-radius-5 border-highlights-box pb-10 mr-15">
                      <Analytics
                        className={clrName}
                        icon={eye}
                        link=""
                        activeNumber={companyPreviewDetail.invites || 0}
                        text="Invites"
                      />
                    </div>
                    <div className="d-flex highlights-box border-radius-5 border-highlights-box pb-10 mr-15">
                      <Analytics
                        className={clrName}
                        icon={adduser}
                        link=""
                        activeNumber={companyPreviewDetail.interviews || 0}
                        text="Interviews"
                      />
                    </div>
                  </Row>
                </Row>
              )}
              {companyPreviewDetail?.landing_page_settings?.show_socials && (
                <><Row className="pad-20 mt-20 bg-white border-radius-5 ml-10  border-radius-5 sml-0 smr-0 ">
                  <Row className="row-pad-0  mb-10 ">
                    <Col md={12} className="pad-0">
                      <h2 className="sub-heading">Connect with {companyPreviewDetail.landing_page_settings?.show_name && companyPreviewDetail?.company_name}</h2>
                    </Col>
                    <span className="single-border d-block"></span>
                    <Col
                      md={12}
                      className="d-flex justify-content-around pad-0  mt-10"
                    >
                      {companyPreviewDetail?.website_url && (
                        <Analytics className="bgwesites" icon={website} link={companyPreviewDetail?.website_url} />
                      )}
                      {companyPreviewDetail?.linkedin_url && (
                        <Analytics className="bglinkden ml-20" icon={linkden} link={companyPreviewDetail?.linkedin_url} />
                      )}
                      {companyPreviewDetail?.facebook_url && (
                        <Analytics className="bgfacebook ml-20" icon={facebook} link={companyPreviewDetail?.facebook_url} />
                      )}
                      {companyPreviewDetail?.twitter_url && (
                        <Analytics className="bgxtiwtter ml-20" icon={Xtiwtter} link={companyPreviewDetail?.twitter_url} />
                      )}
                    </Col>
                  </Row>
                </Row>
                </>
              )}

              <Row className="pad-20 mt-20 bg-white ml-10   border-radius-5 sml-0 smr-0">
                <Col md={12} className=" mb-10">
                  <Row className='row-pad-0  mb-10'>
                    <Col md={12} className='pad-0'>
                      <h2 className="sub-heading">Jobs You May Like</h2>
                    </Col>
                    <span className='single-border d-block'></span>
                  </Row>

                  {jobs.length > 0 ? (
                    jobs?.map(job => (
                      <Card className="joblikes-card mt-2" key={job.id}>
                        <Row>
                          <Col md={12}>
                            <div className="d-flex justify-content-between sm-flex-wrap">
                              <div className="d-flex">
                                <CriclrDiv icon={JobActive} clrName="bg-white Circlediv-wrap" />
                                <div className="active-status-company">
                                  <span className='number-circle'>{job?.jobTitle || job?.job_title}</span>
                                  <span className='text-circle mt-1'>${job?.pay_salary}/{job?.pay_type}</span>
                                </div>
                              </div>
                              <p className="sm-ml-48" style={{ fontSize: '14px ' }}>{job?.country_name},{job?.state || job?.state}</p>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    ))
                  ) : (
                    <p>No record found.</p>
                  )}

                  {jobs.length > 0 && (
                    <Col md={12} className="d-flex justify-content-center mt-15">
                      <Paginate
                        pages={pages}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        perPage={perPage}
                        totalRecords={totalRecords}
                        handlePageChange={handlePageChange}
                        handlePerPage={handlePerPage}
                        pagenation="only"
                      />
                    </Col>
                  )}
                </Col>
              </Row>

            </Col>
          </Row>


          {/* <Row md={12} className="pad-0 mt-20 ">
            <Col className="text-end ">
              <CustomButton
                size="lg"
                style={{
                  background: buttonColor,
                  color: buttonTextColor,
                  borderColor: buttonColor
                }}

                text="Proceed"
              />
            </Col>
          </Row> */}
        </Col >

      </>
      )}

    </>);
};

export default PreviewCompanyToken;
