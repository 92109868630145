import React, { useState, useRef } from 'react';
import { Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import FileUploadView from '../common/FileUploadView';
import { word, docx, pdf, txt } from '../../helper/Icons/Icon';

const config = require('../../config/config.json');

const InterviewMedia = ({ media }) => {
    const [index, setIndex] = useState(0);
    const audioRefs = useRef([]);
    const videoRefs = useRef([]);

    const handleSelect = (selectedIndex) => {

        if (audioRefs.current[index]) {
            audioRefs.current[index].pause();
        }
        if (videoRefs.current[index]) {
            videoRefs.current[index].pause();
        }


        setIndex(selectedIndex);
    };

    const renderCarouselItem = (item, type, idx) => {
        const extension = type;

        switch (extension) {
            case 'mp4':
            case 'mov':
            case 'avi':
                return (
                    <Carousel.Item key={idx}>
                        <div className='video-Interview-Media-wrap'>
                            <video
                                ref={(el) => (videoRefs.current[idx] = el)}
                                className="d-block w-100"
                                src={`${item}`}
                                controls
                                alt={`Slide ${idx}`}
                                onPlay={() => {

                                    videoRefs.current.forEach((video, index) => {
                                        if (video && index !== idx) {
                                            video.pause();
                                        }
                                    });
                                }}
                            />
                            <Carousel.Caption>
                                {/* <div className='media-index'>
                                    <h3>{idx + 1}</h3>
                                </div> */}
                            </Carousel.Caption>
                        </div>
                    </Carousel.Item>
                );

            case 'mp3':
            case 'wav':
            case 'aac':
                return (
                    <Carousel.Item key={idx}>
                        <div className='audio-media-interview-wrap' >
                            <audio
                                ref={(el) => (audioRefs.current[idx] = el)} // Store audio element in ref
                                className="d-block w-100"
                                src={`${item}`}
                                controls
                                alt={`Slide ${idx}`}
                                onPlay={() => {
                                    // Stop other audios when this one plays
                                    audioRefs.current.forEach((audio, index) => {
                                        if (audio && index !== idx) {
                                            audio.pause();
                                        }
                                    });
                                }}
                            />
                        </div>
                        <Carousel.Caption>
                            {/* <h3> {idx + 1}</h3> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                );

            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'webp':
                return (
                    <Carousel.Item key={idx}>
                        <div className='media-interview-image' >
                            <img
                                className="d-block w-100"
                                src={`${item}`}
                                alt={`Slide ${idx}`}
                            />
                        </div>

                        <Carousel.Caption>
                            {/* <h3> {idx + 1}</h3> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                );

            case 'docx':
            case 'doc':
                return (
                    <Carousel.Item key={idx}>
                        <div className='audio-media-interview-file' >

                            <FileUploadView name={`${item}`} icon={docx} nameext="docx" />
                        </div>
                        <Carousel.Caption>
                            {/* <h3> {idx + 1}</h3> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                );

            case 'pdf':
                return (
                    <Carousel.Item key={idx}>
                        <FileUploadView name={`${item}`} icon={pdf} nameext="pdf" />
                        <Carousel.Caption>
                            {/* <h3> {idx + 1}</h3> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                );

            case 'txt':
                return (
                    <Carousel.Item key={idx}>
                        <FileUploadView name={`${item}`} icon={txt} nameext="txt" />
                        <Carousel.Caption>
                            {/* <h3> {idx + 1}</h3> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                );

            default:
                return null; // Ignore unknown file types
        }
    };

    if (!media || !Array.isArray(media) || media.length === 0) {
        return (
            <Row className="row-pad-0 pad-10 border-radius-5 mt-15">
                <h6 className='interview-no-media'>No media available</h6>
            </Row>
        );
    }

    return (
        <Row className={`row-pad-0 pad-10 interview-media-wrap responsive-modal-view-mobile mt-10 ${media.length === 1 ? "no-carousel-arrows" : "show-carousel-arrows"}`}>
            <Carousel activeIndex={index} touch={true}
                interval={null} onSelect={handleSelect} data-bs-theme="dark">
                {media.map((item, idx) => renderCarouselItem(item?.path, item?.type, idx))}
            </Carousel>
        </Row>
    );
};

export default InterviewMedia;
