import { Container, Offcanvas, Row, Col, Dropdown } from "react-bootstrap";
import { Video, crossicon, fileupploed, newtemplate, UploadMediaImge, attachment, ringcopy, mic } from "../../helper/Icons/Icon";

import ImportInterview from "./ImportInterview";
import UploadMedia from "./UploadMedia";
import { Label, InputField, Textarea, CustomButton } from "../form/index";
import { useCallback, useState, useRef, useEffect } from "react";
import Addlink from "./Addlink";

import EmailSmsConfiguration from "./EmailSmsConfiguration";
import PreInterviewForm from "./PreInterviewForm";
import Questions from "./Questions";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { create } from '../../store/actions/interviewTemplateActions'
import { update, getById } from '../../store/actions/interviewTemplateActions'
import Spinner from "../common/Spinner";
const AddInterviewTemplate = ({ handleClose }) => {
    const dispatch = useDispatch();
    const [showUploadMedia, setUploadMedia] = useState(false);
    const [showUploadMediaExit, setUploadMediaExit] = useState(false);
    const [errors, setErrors] = useState({});
    const [data, setData] = useState({

        'video_interview_invitation_email': 0,
        'video_interview_invitation_sms': 0,
        'interview_success_email_to_candidate': 0,
        'live_interview_invitation_email': 0,
        'live_interview_invitation_sms': 0,
        'external_evaluator_invitation_email': 0,
        'external_evaluator_invitation_sms': 0,
        'evaluation_success_email_to_ext_evaluator': 0,

    });
    const [showImportInterview, setImportInterview] = useState(false);
    const [showAddlink, setAddlink] = useState(false);
    const { loading, error } = useSelector((state) => state.interviewTemplate);

    const sweetAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 2500
        });
    };


    const handleChange = (e) => {
        const { name, value } = e.target;

        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.trim() ? '' : 'Field is required'
        }));
    };

    const preInterviewForm = (fields) => {
        setData(prevData => ({
            ...prevData,
            pre_interview_form: fields
        }));
    };

    const temlateQuestins = (questions) => {

        setData(prevData => ({
            ...prevData,
            questions: questions
        }));

        if (questions) {
            setErrors(prevErrors => ({
                ...prevErrors,
                questions: '',
            }))
        }
    };

    const handleModel = (props) => {
        switch (props) {
            case "ImportInterview":
                setImportInterview(!showImportInterview);
                break;
            case "UploadMedia":

                setUploadMedia(!showUploadMedia);

                break;
            case "UploadMediaExit":

                setUploadMediaExit(!showUploadMediaExit);

                break;
            case "Addlink":
                setAddlink(!showAddlink);
                break;

            default:
                break;
        }
    };

    const saveWelMsgMedia = (media) => {
        setData(prevData => ({
            ...prevData,
            intro_media: media
        }));

        setUploadMedia(false);
    };

    const saveExitMsgMedia = (media) => {

        setData(prevData => ({
            ...prevData,
            exit_media: media
        }));
        setUploadMediaExit(false);
    }


    const FileMediaDisplay = ({ fileMedia }) => {
        if (!Array.isArray(fileMedia)) return null;

        return (
            <div className="save-question-box mt-1 template-question-box">
                <label>Media:</label>
                {fileMedia.map((file, index) => {
                    const fileName = file.name;
                    const extension = file.type;

                    let icon;
                    if (['mp4', 'mov', 'avi'].includes(extension)) {
                        icon = Video;
                    } else if (['mp3', 'wav', 'aac'].includes(extension)) {
                        icon = ringcopy;
                    } else if (['jpeg', 'png', 'gif', 'jpg', 'webp'].includes(extension)) {
                        icon = UploadMediaImge;
                    }
                    else if (['docx', 'pdf', 'doc'].includes(extension)) {
                        icon = attachment;
                    }
                    else {
                        icon = fileupploed;
                    }

                    return (
                        <div key={index} className="file-item">
                            {icon && <span className="file-icon">{icon}</span>}
                            <span className="file-name">{fileName}</span>
                        </div>
                    );
                })}
            </div>
        );
    };


    const validateForm = () => {
        const errors = {};

        if (!data.interview_template_name) {
            errors.interview_template_name = 'Template name is required';
        }
        if (!data.interview_name) {
            errors.interview_name = 'Interview name is required';
        }

        // if (!data.interview_template_description) {
        //     errors.interview_template_description = 'Description template is required';
        // }
        if (!data.questions || data.questions.length === 0) {
            errors.questions = 'At least one question must be added';
        }

        return errors;
    };

    const handleSubmit = (e) => {

        e.preventDefault();
        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            dispatch(create(data)).then((res) => {
                if (res.payload.error) {
                    sweetAlert(
                        "error",
                        "Error!",
                        `${res.payload.error}!`
                    );
                } else {
                    handleClose();

                    sweetAlert(
                        "success",
                        "Success!",
                        `${res.payload.message}!`
                    );
                }
            }).catch(error => {
                sweetAlert(
                    "error",
                    "Error!",
                    "Something went wrong!"
                );
            });
        }
        else {
            setErrors(errors);
        }
    };

    const InterviewJourney = (props) => {
        if (props) {
            const Id = props.interview_template_id;
            setImportInterview(false)
            dispatch(getById(Id)).then((res) => {
                if (res.payload) {
                    setData({
                        interview_template_name: res.payload.InterviewTemplateName || '',
                        interview_name: res.payload.InterviewName || '',
                        interview_template_description: res.payload.InterviewTemplateDescription || '',
                        intro_media: res.payload.intro_media || [],
                        exit_media: res.payload.exit_media || [],
                        intro_message: res.payload.intro_message || '',
                        exit_message: res.payload.exit_message || '',
                        video_interview_invitation_email: res.payload.video_interview_invitation_email || 0,
                        video_interview_invitation_sms: res.payload.video_interview_invitation_sms || 0,
                        live_interview_invitation_email: res.payload.live_interview_invitation_email || 0,
                        live_interview_invitation_sms: res.payload.live_interview_invitation_sms || 0,
                        external_evaluator_invitation_email: res.payload.external_evaluator_invitation_email || 0,
                        external_evaluator_invitation_sms: res.payload.external_evaluator_invitation_sms || 0,
                        interview_success_email_to_candidate: res.payload.interview_success_email_to_candidate || 0,
                        evaluation_success_email_to_ext_evaluator: res.payload.evaluation_success_email_to_ext_evaluator || 0,
                        questions: res.payload.questions || [],
                        pre_interview_form: res.payload.pre_interview_form || []
                    });
                }
            });

        }
    }
    return (
        <>
            <Offcanvas
                show={true}
                onHide={handleClose}
                placement="end"
                className="bg-ligte-gray custom-offcanvas"
                style={{ width: "82%" }}
            >
                {loading && <Spinner />}
                <Container fluid className="row-pad-0 pad-0 bg-ligte-gray pad-20">
                    <Offcanvas.Header className="model-header bg-white actionlist-header ">
                        <Offcanvas.Title>

                            <span className="model-header-svg ">{newtemplate}New Interview Template</span>
                        </Offcanvas.Title>
                        <div className="text-end">
                            <CustomButton
                                onClick={handleClose}
                                className="text-end close-btn "
                                text={crossicon}
                            />
                        </div>
                    </Offcanvas.Header>
                    <Col md={12} className=" pad-20 new-template-wrap">
                        <Row className="row-pad-0 bg-white  radius-7 pad-20 ">
                            <Col md={12} className="pad-0">
                                <Row className="row-pad-0 mb-10">
                                    <Col md={6}>
                                        <Label
                                            text="Template Name"
                                            className="label-input"
                                            htmlFor="templateName"
                                            required="required"
                                        />
                                        <InputField
                                            type="text"
                                            name="interview_template_name"
                                            id="templateName"
                                            className="input-field"
                                            placeholder="Enter template name here"
                                            onChange={handleChange}
                                            value={data.interview_template_name}
                                        />
                                        {errors.interview_template_name && <span className="text-danger">{errors.interview_template_name}</span>}
                                    </Col>
                                    <Col md={6}>
                                        <Label
                                            text="Interview Name"
                                            className="label-input"
                                            htmlFor="interviewName"
                                            required="required"
                                        />
                                        <InputField
                                            type="text"
                                            className="input-field"
                                            name="interview_name"
                                            id="interviewName"
                                            placeholder="Enter interview name here"
                                            value={data.interview_name}
                                            onChange={handleChange}
                                        />
                                        {errors.interview_name && <span className="text-danger">{errors.interview_name}</span>}
                                    </Col>
                                    <Col md={12} className="mt-10">
                                        <Label
                                            text="Template Description"
                                            className="label-input"
                                            htmlFor="templateDescription"
                                        // required="required"
                                        />
                                        <Textarea
                                            as="textarea"
                                            name="interview_template_description"
                                            className="input-textarea"
                                            rows={4}
                                            placeholder="Enter template description here"
                                            value={data.interview_template_description}
                                            onChange={handleChange}
                                        />
                                        {/* {errors.interview_template_description && <span className="text-danger">{errors.interview_template_description}</span>}
                                     */}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>


                        <Row className="row-pad-0 bg-white  mt-10 radius-7 pad-20">
                            <EmailSmsConfiguration onChange={handleChange} emailInterview={data} />
                        </Row>

                        <Row className="row-pad-0 bg-white  mt-20 radius-7 pad-20 ">
                            <PreInterviewForm preInterviewForm={preInterviewForm} data={data?.pre_interview_form} />
                        </Row>

                        <Row className="row-pad-0 bg-white  mt-20 radius-7 pad-20 ">
                            <Col md={12} className="">
                                <Row className="row-pad-0 ">
                                    <Col md={8} className="pad-0">
                                        <h2 className="sub-heading">Interview Journey</h2>
                                    </Col>
                                    <Col md={4} className="text-end">
                                        <CustomButton
                                            text="Import Interview Journey"
                                            className="custom-btn-outline"
                                            onClick={() => handleModel("ImportInterview")}
                                        />
                                    </Col>
                                </Row>

                                <Row className="InterviewJourney-content mt-10 mb-10 pad-10  row-pad-0">
                                    <Col md={12} className="pad-0">
                                        <h3 className="min-heading">Welcome Message</h3>

                                        <Row className="row-pad-0">
                                            <Col md={10} className="pad-0">
                                                <InputField
                                                    type="text"
                                                    onChange={handleChange}
                                                    id="intro_message"
                                                    name="intro_message"
                                                    value={data.intro_message || ''}
                                                    className="h-34 input-field"
                                                    placeholder="Enter welcome message"
                                                />
                                                <div>{data.intro_media && <FileMediaDisplay fileMedia={data.intro_media} />}</div>

                                            </Col>
                                            <Col md={2} className="text-end">
                                                <CustomButton
                                                    text="+ Add Media"
                                                    onClick={() => handleModel("UploadMedia")}
                                                    className="custom-btn-outline h-34"
                                                />

                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                                {/* end Welcome Message */}

                                <Questions temlateQuestins={temlateQuestins} requiredValue={data.questions} />
                                {errors.questions && <span className="text-danger">{errors.questions}</span>}

                            </Col>
                        </Row>
                        <Row className="row-pad-0 bg-white  mt-20 radius-7 pad-20">
                            <Col md={12}>
                                <div className="InterviewJourney-content bg-white mt-20 mb-10 pad-10  row-pad-0">

                                    <h3 className="min-heading">Exit Message</h3>

                                    <Row className="row-pad-0">
                                        <Col md={10} className="pad-0">
                                            <InputField
                                                onChange={handleChange}
                                                type="text"
                                                id="exit_message"
                                                name="exit_message"
                                                className="h-34 input-field"
                                                placeholder="Enter exit message"
                                                value={data.exit_message || ''}
                                            />
                                            <div>
                                                {data.exit_media && <FileMediaDisplay fileMedia={data.exit_media} />}
                                            </div>

                                        </Col>
                                        <Col md={2} className="text-end">
                                            <CustomButton
                                                text="+ Add Media"
                                                onClick={() => handleModel("UploadMediaExit")}
                                                className="custom-btn-outline"
                                            />
                                        </Col>
                                    </Row>

                                </div>
                            </Col>
                        </Row>
                        <Col md={12} className="text-end mt-20  mb-10">
                            <CustomButton
                                text="Save"
                                className="custom-btn-primary"
                                onClick={handleSubmit}
                            />
                        </Col>
                    </Col>
                </Container>
                {showImportInterview && (
                    <ImportInterview handleClose={() => handleModel("ImportInterview")} submit={InterviewJourney} />
                )}
                {showUploadMedia && (
                    <UploadMedia fileMedia={data.intro_media} SavehandleModel={saveWelMsgMedia} handleClose={() => handleModel("UploadMedia")} />
                )}
                {showUploadMediaExit && (

                    <UploadMedia fileMedia={data.exit_media} SavehandleModel={saveExitMsgMedia} handleClose={() => handleModel("UploadMediaExit")} />
                )}
                {showAddlink && <Addlink handleClose={() => handleModel("Addlink")} />}

            </Offcanvas>
        </>
    );
};
export default AddInterviewTemplate;
