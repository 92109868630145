import React from 'react';
import { invitedStats, PassedStats, SubmittedStats, FailedStats, HiredStats } from '../../helper/Icons/Icon';

const AnalyticityStats = ({ stats }) => {
    const statusIcons = {
        "Invited": invitedStats,
        "Interview Passed": PassedStats,
        "Interview Submitted": SubmittedStats,
        "Expired": FailedStats,
        "Rejected": FailedStats,
    };

    return (
        <div className="status-container">
            {stats.map((stat, index) => (
                stat.status !== "Interview Underway" && (
                    <div className="status-badge" key={index}>
                        <span>{statusIcons[stat.status] || HiredStats}</span>
                        <span>{stat.count}</span>
                        <span>{` ${stat.status}`}</span>
                    </div>
                )
            ))}
        </div>
    );
};

export default AnalyticityStats;
