import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Label, SelectField } from "../form/index";
import { useDispatch, useSelector } from 'react-redux';
import { getAll as geAllEamilTypes } from '../../store/slices/emailTemplateTypeSlice';
import { getAll as getAllSmsTyps } from '../../store/slices/smsTemplateTypeSlice';
import { TypeH2 } from 'react-bootstrap-icons';

const EmailSmsConfiguration = ({ onChange, emailInterview }) => {
    const dispatch = useDispatch()

    const emailTemplateTypes = useSelector(state => state.emailTemplateType.emailTemplateTypes);
    let videoInterviewEmail = emailTemplateTypes[0]?.emails_templates;
    let liveInterviewEmail = emailTemplateTypes[1]?.emails_templates;
    let externalEvaluatorEmail = emailTemplateTypes[2]?.emails_templates;
    let evaluationSuccessEmail = emailTemplateTypes[3]?.emails_templates;
    let evaluationSuccessEmailEvaluator = emailTemplateTypes[4]?.emails_templates;

    const smsTemplateTypes = useSelector(state => state.smsTemplateType.smsTemplateTypes);
    let videoInterviewSms = smsTemplateTypes[0]?.sms_templates;
    let liveInterviewSms = smsTemplateTypes[1]?.sms_templates;
    let externalEvaluatorSms = smsTemplateTypes[2]?.sms_templates;

    useEffect(() => {
        dispatch(geAllEamilTypes());
        dispatch(getAllSmsTyps());
    }, [dispatch]);

    const handleChange = (e) => {
        onChange(e)
    };


    return (

        <Col md={12} className="pad-0">
            <h2 className="sub-heading ml-10">
                Email & SMS Configuration
            </h2>

            <Row className="row-pad-0  mb-10">
                <Col md={12} className="configuration">
                    <Row>
                        <Col md={3} className="mb-10">
                            <Label

                                text={videoInterviewEmail && emailTemplateTypes[0]?.email_template_type_name}

                                className="label-input"
                                htmlFor="video_interview_invitation_email"
                            />
                            <Form.Select name='video_interview_invitation_email' className='input-select' id='video_interview_invitation_email' onChange={handleChange}>


                                {videoInterviewEmail &&
                                    videoInterviewEmail.map(option => (
                                        <option key={option.emailTemplateId}
                                            value={option.emailTemplateId}
                                            selected={(
                                                (emailInterview?.video_interview_invitation_email
                                                    ? option.emailTemplateId === emailInterview?.video_interview_invitation_email
                                                    : option.isDefault)
                                                    ? "selected"
                                                    : ""
                                            )}
                                        >{option.emailTemplateName}</option>
                                    ))}
                            </Form.Select >
                        </Col>


                        <Col md={3} className="mb-10">
                            <Label
                                // text="Recorded Interview Invitation SMS"
                                text={smsTemplateTypes && smsTemplateTypes[0]?.sms_template_type_name}
                                className="label-input"
                                htmlFor="video_interview_invitation_sms"
                            />
                            <Form.Select name='video_interview_invitation_sms' className='input-select' id='video_interview_invitation_sms' onChange={handleChange}>


                                {videoInterviewSms &&
                                    videoInterviewSms.map(option => (
                                        <option key={option.smsTemplateId} value={option.smsTemplateId}
                                            selected={(
                                                (emailInterview?.video_interview_invitation_sms
                                                    ? option.smsTemplateId === emailInterview?.video_interview_invitation_sms
                                                    : option.isDefault)
                                                    ? "selected"
                                                    : ""
                                            )}


                                        >{option.templateName}</option>
                                    ))
                                }
                            </Form.Select >
                        </Col>





                        <Col md={3} className="mb-10">
                            <Label
                                text={videoInterviewEmail && emailTemplateTypes[1]?.email_template_type_name}

                                // text="Live Interview Invitation Email"
                                className="label-input"
                                htmlFor="live_interview_invitation_email"
                            />

                            <Form.Select name='live_interview_invitation_email' className='input-select' id='live_interview_invitation_email' onChange={handleChange}>


                                {liveInterviewEmail &&
                                    liveInterviewEmail.map(option => (
                                        <option key={option.emailTemplateId} value={option.emailTemplateId}
                                            selected={(
                                                (emailInterview?.live_interview_invitation_email
                                                    ? option.emailTemplateId === emailInterview?.live_interview_invitation_email
                                                    : option.isDefault)
                                                    ? "selected"
                                                    : ""
                                            )}


                                        >{option.emailTemplateName}</option>
                                    ))
                                }
                            </Form.Select >
                        </Col>

                        <Col md={3} className="mb-10">
                            <Label
                                // text="Live Interview Invitation SMS"
                                text={smsTemplateTypes && smsTemplateTypes[1]?.sms_template_type_name}
                                className="label-input"
                                htmlFor="live_interview_invitation_sms"
                            />
                            <Form.Select name='live_interview_invitation_sms' className='input-select' id='live_interview_invitation_sms' onChange={handleChange}>


                                {liveInterviewSms &&
                                    liveInterviewSms.map(option => (
                                        <option key={option.smsTemplateId} value={option.smsTemplateId}


                                            selected={(
                                                (emailInterview?.live_interview_invitation_sms
                                                    ? option.smsTemplateId === emailInterview?.live_interview_invitation_sms
                                                    : option.isDefault)
                                                    ? "selected"
                                                    : ""
                                            )}
                                        >{option.templateName}</option>
                                    ))
                                }
                            </Form.Select >
                        </Col>


                        <Col md={3} className="mb-10">
                            <Label
                                text={videoInterviewEmail && emailTemplateTypes[2]?.email_template_type_name}

                                // text="External Evaluator Invitation Email"
                                className="label-input"
                                htmlFor="external_evaluator_invitation_email"
                            />

                            <Form.Select name='external_evaluator_invitation_email' className='input-select' id='external_evaluator_invitation_email' onChange={handleChange}>


                                {externalEvaluatorEmail &&
                                    externalEvaluatorEmail.map(option => (
                                        <option key={option.emailTemplateId} value={option.emailTemplateId}
                                            selected={(
                                                (emailInterview?.external_evaluator_invitation_email
                                                    ? option.emailTemplateId === emailInterview?.external_evaluator_invitation_email
                                                    : option.isDefault)
                                                    ? "selected"
                                                    : ""
                                            )}


                                        >{option.emailTemplateName}</option>
                                    ))
                                }
                            </Form.Select >
                        </Col>

                        <Col md={3} className="mb-10">
                            <Label
                                text={smsTemplateTypes && smsTemplateTypes[2]?.sms_template_type_name}

                                // text="External Evaluator Invitation SMS"
                                className="label-input"
                                htmlFor="external_evaluator_invitation_sms"
                            />
                            <Form.Select name='external_evaluator_invitation_sms' className='input-select' id='external_evaluator_invitation_sms' onChange={handleChange}>


                                {externalEvaluatorSms &&
                                    externalEvaluatorSms.map(option => (
                                        <option key={option.smsTemplateId} value={option.smsTemplateId}
                                            selected={(
                                                (emailInterview?.external_evaluator_invitation_sms
                                                    ? option.smsTemplateId === emailInterview?.external_evaluator_invitation_sms
                                                    : option.isDefault)
                                                    ? "selected"
                                                    : ""
                                            )}

                                        >{option.templateName}</option>
                                    ))
                                }
                            </Form.Select >
                        </Col>

                        <Col md={3} className="mb-10">
                            <Label
                                text={videoInterviewEmail && emailTemplateTypes[3]?.email_template_type_name}

                                // text="Interview Success Email to Candidate"
                                className="label-input"
                                htmlFor="interview_success_email_to_candidate"
                            />
                            <Form.Select name='interview_success_email_to_candidate' className='input-select' id='interview_success_email_to_candidate' onChange={handleChange}>



                                {evaluationSuccessEmail &&
                                    evaluationSuccessEmail.map(option => (
                                        <option key={option.emailTemplateId} value={option.emailTemplateId}
                                            selected={(
                                                (emailInterview?.interview_success_email_to_candidate
                                                    ? option.emailTemplateId === emailInterview?.interview_success_email_to_candidate
                                                    : option.isDefault)
                                                    ? "selected"
                                                    : ""
                                            )}


                                        >{option.emailTemplateName}</option>
                                    ))
                                }
                            </Form.Select >
                        </Col>

                        <Col md={3} className="mb-10">
                            <Label
                                text={videoInterviewEmail && emailTemplateTypes[4]?.email_template_type_name}

                                // text="Evaluation Success Email to External Evaluator"
                                className="label-input"
                                htmlFor="evaluation_success_email_to_ext_evaluator"
                            />
                            <Form.Select name='evaluation_success_email_to_ext_evaluator' className='input-select' id='evaluation_success_email_to_ext_evaluator' onChange={handleChange}>


                                {evaluationSuccessEmailEvaluator &&
                                    evaluationSuccessEmailEvaluator.map(option => (
                                        <option key={option.emailTemplateId} value={option.emailTemplateId}

                                            selected={(
                                                (emailInterview?.evaluation_success_email_to_ext_evaluator
                                                    ? option.emailTemplateId === emailInterview?.evaluation_success_email_to_ext_evaluator
                                                    : option.isDefault)
                                                    ? "selected"
                                                    : ""
                                            )}


                                        >{option.emailTemplateName}</option>
                                    ))
                                }
                            </Form.Select >
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
};

export default EmailSmsConfiguration;
